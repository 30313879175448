const pt = {
  demo: {
    title: 'Inglês',
    introduction: '',
  },

  resetpass: {
    mesage: 'Esqueceu a senha?',
    info: 'Digite o endereço de e-mail associado à sua conta e enviaremos um link para redefinir sua senha.',
    reset: 'Redefinir',
    back: 'Voltar',
    sent: 'Solicitação enviada com sucesso',
    confirmation: 'Enviamos um e-mail de confirmação para',
    check: 'Verifique seu e-mail',
    check1:
      'Enviamos um código de confirmação de 6 dígitos por e-mail. Por favor, insira o código abaixo para verificar seu e-mail.',
    verify: 'Verificar',
    resend: 'Reenviar código',
    donthave: 'Não tem o código?',
    return: 'Voltar para a Página Inicial',
    success: 'Solicitação enviada com sucesso!',
    success2: 'Enviamos uma mensagem de confirmação de 6 dígitos para o seu e-mail.',
    success3: 'Por favor, insira o código abaixo para verificar seu e-mail.',
    newpass: 'Atualizar Senha',
    confirmpass: 'Confirmar Nova Senha',
    pass: 'Senha',
    checkpegatina: 'Ver o autocolante físico',
    checkpegatina2: 'Para garantir a segurança dos seus dados sensíveis, está impresso no autocolante físico um PIN de confirmação de 6 dígitos. Introduza este PIN na caixa abaixo para garantir o acesso a informações sensíveis. Se não tiver um PIN, inicie sessão e não precisará de o introduzir.',
    errorpin: 'Pino errado',
    checkotp: 'Verifique a sua aplicação de dois fatores',
    checkotp2: 'Verifique a sua aplicação Google Authenticator para obter o código para verificar a sua conta.',
  },
  newparameter: {
    back: 'Voltar',
    next: 'Próximo',
    scope: 'Âmbito do Parâmetro',
    type: 'Tipo de Parâmetro',
    config: 'Configuração',
    posology: 'Vender como prescrito na Posologia',
    swap: 'Substituição da Prescrição',
    notdispense: 'Não Dispensar',
    priority: 'Priorizar Destaques Farmáticos',
    add: 'Concluir',
    priorityconfirm: 'A partir de agora, daremos prioridade aos seus Medicamentos em Destaque.',
    posologyconfirm: 'A partir de agora, só será vendido o prescrito na Posologia.',
    selectsust: 'Prescrição a ser Substituída',
    selectsust2: 'Prescrição a ser Dispensada.',
    notdispence2: 'Selecione a prescrição que não deseja dispensar.',
    number: 'Número',
    scopetable: 'Afeta a',
    name: 'Nome',
    typeparameter: 'Tipo de Parâmetro',
    code: 'Códigos Nacionais',
    patient: 'Paciente',
    center: 'Centro',
    pharmacy: 'Farmácia',
    swaptype: 'Substituição da Prescrição',
    prioritytype: 'Priorizar Destaques',
    posologytype: 'Apenas o prescrito na Posologia',
    notdispencetype: 'Não Dispensar',
    no_financied: 'Apenas Financiados',
    no_narcotic: 'Não Vender Entorpecentes',
    maxstock: 'Estoque Máximo',
    max_box_dps: 'Unidades Máximas',
    no_regex: "Não Dispensar por 'palavra'",
    order_lab: 'Priorizar Laboratório',
    check_stock: 'Vender com Estoque',
    force_posology: 'Vender pelos CN da Posologia',
    laboratory: 'Lista de Laboratórios',
    replace_max_stock: 'Substituição por Estoque Máximo',
    cst_change: 'Última recarga no Hopper',
  },
  table: {
    column: 'Colunas',
    find: 'Encontre sua coluna.',
    columntitle: 'Título da Coluna',
    hyde: 'Desmarcar todos',
    mark: 'Selecionar todos',
    filters: 'Filtros',
    density: 'Densidade',
    density1: 'Compacto',
    density2: 'Normal',
    density3: 'Grande',
    export: 'Exportar',
    operators: 'Operadores',
    contains: 'contém',
    equals: 'é igual a',
    start: 'começa com',
    end: 'termina com',
    empty: 'está vazio',
    noempty: 'não está vazio',
    value: 'Valor',
    filtervalue: 'Filtrar valor',
    download: 'Baixar como CSV',
    print: 'Imprimir',
    download2: 'Baixar como Excel',
    columnMenuLabel: 'Menu',
    columnMenuShowColumns: 'Mostrar colunas',
    columnMenuManageColumns: 'Gerenciar colunas',
    columnMenuFilter: 'Filtrar',
    columnMenuHideColumn: 'Ocultar coluna',
    columnMenuUnsort: 'Desfazer ordenação',
    columnMenuSortAsc: 'Ordenar ASC',
    columnMenuSortDesc: 'Ordenar DESC',
  },
  menu: {
    general: 'MENU',
    order: 'Gestão de Pedidos',
    'historic Orders': 'Histórico',
    catalog: 'Medicamentos',
    patient: 'Pacientes',
    posology: 'Posologia',
    newpro: 'Produção',
    Laboratorys: 'Laboratórios',
    Config: 'Configuração',
    management: 'ADMINISTRAÇÃO',
    user: 'Usuários',
    profile: 'Meu perfil',
    list: 'Administração de Usuários',
    myinstallation: 'Minha Instalação',
    subscription: 'Minha farmácia',
    invoice: 'Fatura',
    centers: 'Centros',
    centersmanage: 'Gestão de Centros',
    virtual: 'IA Mery',
    available: 'Pedidos de compra',
    parameters: 'Parâmetros',
    listrx: 'Receitas por Paciente',
    reports: 'Receita Eletrônica',
    traceability: 'Trazabilidade',
    alerts: 'Alertas',
    history: 'Histórico',
    medpatient: 'Medicação por Paciente',
    merge: 'Cruzamento de Posologia',
    take: 'Entrega de Medicamentos',
    import: 'Importação',
    inicio: 'Início',
    upload: 'Importar Arquivo',
    parametersimport: 'Parâmetros de Importação',
    task: 'Tarefas Programadas',
    electronic: 'Cartões Sanitários',
    availableia: 'Disponíveis',
    match: 'Cruzamento de Posologia / Receita',
    sellparameters: 'Parâmetros de Venda',
    form0: 'Relatórios',
    form1: 'Relatório de Produção',
    form2: 'Medicação por Paciente',
    form3: 'Trazabilidade',
    chat: 'Chat',
    calendar: 'Calendário',
    support: 'Suporte Técnico',
    assist: 'Assistência',
    softinstall: 'Software instalado',
  },
  dropdown: {
    Home: 'Início',
    Profile: 'Perfil',
    Settings: 'Configurações',
    logout: 'Sair',
  },
  alert: {
    success: 'Acesso concedido',
    newuser: 'Novo Usuário',
    verified: 'Verificado',
    yes: 'Sim',
    no: 'Não',
    edit: 'Editar',
    deleteuser: 'Excluir Usuário',
    confirmation: 'Tem certeza de que deseja excluir este(s) Usuário(s)?',
  },
  myuser: {
    general: 'Geral',
    pass: 'Alterar Senha',
    allow: 'Permitidos',
    sice: 'Tamanho máximo de',
    save: 'Salvar Alterações',
    about: 'Comentário',
    old: 'Senha Atual',
    new: 'Nova Senha',
    mustbe: 'A senha deve ter no mínimo 6 caracteres.',
    confirm: 'Confirmar Nova Senha',
    banned: 'Desativado',
    applyban: 'Aplicar desativação da conta.',
    savechanges: 'Salvar Alterações',
    upload: 'Enviar Arquivo',
    photo: 'Enviar Foto',
    update: 'Atualizar Foto',
  },
  register: {
    mesage: 'Rápido, simples e intuitivo.',
    intro: 'Crie sua conta e comece a aproveitar nossas soluções.',
    intro2: 'É muito simples!',
    name: 'Nome e Sobrenome',
    lastname: 'Sobrenome',
    namepharma: 'Nome da Instalação',
    create: 'Registrar',
    already: 'Já tem uma conta? ',
    firstnamerec: 'O nome é obrigatório',
    lastnamerec: 'O sobrenome é obrigatório',
    emailrec: 'O e-mail é obrigatório',
    passrec: 'A senha é obrigatória',
  },
  centers: {
    list: 'Lista de Centros',
    title: 'Ficha do centro',
    nif: 'NIF',
    date: 'Data de registro',
    production: 'Modo Produção',
    machinepro: 'Dispositivo Selecionado',
    print1: 'Imprimir os NÃO envasáveis.',
    print2: 'Imprimir por momentos.',
    print3: 'Imprimir o Se necessário.',
    code: 'Por código de medicamento',
    code2: 'Por família de embalagens',
    code3: 'Por famílias biológicas',
    change: 'Deseja mudar de Centro?',
    changeinstallation: 'Deseja mudar de Instalação?',
    sure: 'Tem certeza de que deseja mudar de centro?',
    acept: 'Aceitar',
    addnew: 'Nova Pauta Padrão',
    namenewpred: 'Nome da Pauta',
    syncdate: 'Sincronizar data de fim da receita eletrónica',
    syncseller: 'Atualizar a dosagem de acordo com a última venda de medicamento',
    antibioticpackablealone:'Cada antibiótico requer um recipiente separado.',
  },
  listmedicine: {
    medication: 'Medicação',
    search: 'Buscar',
    filter: 'Filtrar Medicamento',
    all: 'Todos',
    tipemedication: 'Medicamentos',
    familya: 'Família de Embalagem',
    familyb: 'Família Biológica',
    inactive: 'Inativo',
    active: 'Ativo',
    ficha: 'Ficha do Medicamento',
    brand: 'Marca',
    generic: 'Genérico',
    needrecipe: 'Receita Necessária',
    code: 'Código',
    name: 'Nome',
    codefamily: 'Código Família Biológica',
    namefamily: 'Nome Família Biológica',
    units: 'Unidades por Caixa',
    atc: 'Código ATC',
    packable: 'Envasável',
    psicotropic: 'Psicotrópico',
    narcotic: 'Narcótico',
    warning: 'Advertências',
    replaceable: 'Substituível',
    clinic: 'Embalagem Clínica',
    use: 'Uso Hospitalar',
    comer: 'Comercializado',
    tld: 'TLD',
    para: 'Parafarmácia',
    control: 'Controle Especial Médico',
    drive: 'Efeitos na Condução',
    orphan: 'Órfão',
    recipe: 'Receita',
    bio: 'Biossimilar',
    save: 'Salvar',
    cancel: 'Cancelar',
  },
  listpatient: {
    title: 'Lista de Pacientes',
    search: 'Buscar',
    active: 'Ativo',
    ficha: 'Ficha do Paciente',
    name: 'Nome',
    dni: 'NIF/NIE',
    born: 'Data de Nascimento',
    ss: 'Número de Segurança Social',
    phone: 'Telefone',
    sex: 'Gênero',
    female: 'Feminino',
    male: 'Masculino',
    bed: 'Cama',
    region: 'Província',
    room: 'Quarto',
    address: 'Endereço',
    country: 'País',
    city: 'Cidade',
    cp: 'CEP',
    mail: 'E-mail',
    save: 'Salvar',
    cancel: 'Cancelar',
    sync: 'Sincronização',
    journal: 'Histórico de Medicação',
    electronic: 'Receita Eletrônica',
    group: 'Grupo',
    doctorname: 'Nome do médico',
    excludeia: 'Excluir alterações à ia',
  },
  production: {
    status: 'Estado',
    title: 'Nova Produção',
    code: 'Código',
    datecreate: 'Data de Criação',
    dateproduction: 'Data de Produção',
    lastprox: 'Próxima/Última Dispensação',
    initialdose: 'Início/Última Dispensação',
    dateend: 'Data de Término',
    cancel: 'Cancelar',
    pendent: 'Pendente',
    working: 'Em Progresso',
    produced: 'Produzida',
    deleted: 'Excluída',
    repackaging: 'Produção de Reenvasamento',
    pouch: 'Nº de Doses',
    dose: 'Unidades por Dose',
    printlabel: 'Impressão de Etiquetas',
    labelsearch: 'Buscar por Nome',
    cretit_none: 'Créditos insuficientes',
    cretit_none1: 'Atualmente, você não tem créditos suficientes para realizar esta produção.',
    cretit_none2: 'Por favor, recarregue sua conta para continuar.',
    cretit_none3: 'Créditos atuais',
    cretit_none4: 'Créditos necessários',
    cretit_none5: 'Créditos',
    num_production: "Número de Produção",
    date_generate: "Geração de Produção",
    dates_production: "Datas da Produção",
    date_send: "Envio para o Dispositivo"
  },
  dashboard: {
    title: 'Bem-vindo de volta 👋',
    titlecomment: 'Com nossa IA avançada, oferecemos o serviço mais seguro e confiável do mercado.',
    discover: 'Descubra mais',
    patients: 'Pacientes',
    medication: 'Medicação Ativa',
    production: 'Produções',
    caducity: 'Medicação próxima ao vencimento',
    data: 'Dados da Última Produção: ',
    total: 'Total Dispensado:',
    packable: 'Envasáveis',
    unpackable: 'Não Envasável',
    dispensed: 'Dispensado',
    activpatient: 'Pacientes Ativos',
    activposology: 'Tratamentos Ativos',
    totalcount: 'U. Dispensadas Mês',
    type: 'Características',
    if_needed: 'Se necessário',
    iaquality: 'Qualidade da IA',
    merge: 'Corretos',
    rxx: 'Sem Receita',
    posology: 'Sem Posologia',
    reviwer: 'Análise',
    dep: 'Depósito',
    ranking: 'TOP Mudanças',
  },
  posology: {
    force_print: 'Imprimir somente',
    no_sync: 'Protegido',
    title: 'Posologia',
    search: 'Buscar',
    active: 'Ativo',
    inactive: 'Medicação Inativa',
    save: 'Salvar',
    cancel: 'Cancelar',
    packable: 'Embalável',
    unpackable: 'Não embalável',
    start: 'Data de Início',
    end: 'Data Final',
    dosis: 'Dose',
    period: 'Dias Período',
    cronic: 'Crônico',
    days: 'Dias',
    hour: 'Hora Período',
    starthour: 'Hora Início',
    months: 'Meses',
    january: 'Janeiro',
    february: 'Fevereiro',
    march: 'Março',
    april: 'Abril',
    may: 'Maio',
    june: 'Junho',
    july: 'Julho',
    august: 'Agosto',
    september: 'Setembro',
    october: 'Outubro',
    november: 'Novembro',
    december: 'Dezembro',
    week: 'Semanal',
    ciclica: 'Cíclica Dias',
    ciclicb: 'Cíclica Horas',
    especilavar: 'Variável',
    month: 'Mensal',
    aparte: 'Embalado Separadamente',
    optional: 'Se Necessário',
    irreplaceable: 'Irreparável',
    moment: 'Momento',
    monday: 'Segunda-feira',
    tuesday: 'Terça-feira',
    wednesday: 'Quarta-feira',
    thursday: 'Quinta-feira',
    friday: 'Sexta-feira',
    saturday: 'Sábado',
    sunday: 'Domingo',
    add: 'Adicionar Tomada',
    name: 'Nome da Tomada',
    selecttitle: 'Seleção do Medicamento',
    select: 'Selecionar',
    addtoma: 'Adicionar',
    cannot: 'Você não pode salvar uma posologia vazia.',
    confirmation: 'Confirmação',
    confirmationtext: 'Tem certeza de que deseja excluir este tratamento?',
    confirmationdelete: 'Excluir',
    update: 'Alterações salvas com sucesso.',
    error: 'Erro ao excluir a posologia.',
    erroradd: 'Erro ao adicionar a posologia.',
    addsuccess: 'Adicionado com sucesso.',
    deletesuccess: 'Excluído com sucesso.',
    errordate: 'A data de início não pode ser anterior à data de hoje.',
    exist: 'Tomada já existente.',
    periodless: 'O período não pode ser inferior a dias.',
    daysless: 'Dias não pode ser inferior a 1.',
    hourless: 'Horas não pode ser inferior a 1.',
    hourerror: 'O período não pode ser superior a 24 horas.',
    dayerror: 'O dia não pode ser superior a 31 ou inferior a 0.',
    delete: 'Excluir Posologia',
    note: 'Notas',
    interaction: 'Interações Farmacológicas',
    deletedose: 'Deseja excluir o tratamento selecionado?',
    confirmationotp: 'Gerar QR',
  },
  login: {
    mesage: 'Olá, bem-vindo',
    introduction:
      'Lorem Ipsum é simplesmente um texto fictício da indústria tipográfica e de impressão. Lorem Ipsum é o texto fictício padrão do setor desde os anos 1500, quando um impressor desconhecido pegou uma galera de tipos e a embaralhou para fazer um livro de amostra. Ele sobreviveu não apenas cinco séculos, mas também o salto para a composição eletrônica, permanecendo essencialmente inalterado. Foi popularizado na década de 1960 com o lançamento de folhas de Letraset contendo passagens de Lorem Ipsum e, mais recentemente, com software de editoração eletrônica como Aldus PageMaker, incluindo versões de Lorem Ipsum.',
    signin: 'Entrar no IDOOSE',
    details: 'Insira seus dados abaixo.',
    inicio: 'Iniciar Sessão',
    agree: 'Ao me registrar, concordo com os ',
    terms: 'Termos de Serviço',
    privacy: 'Política de Privacidade',
    and: ' e ',
    account: 'Novo Usuário? ',
    started: 'Criar uma conta',
    email: 'E-mail',
    password: 'Senha',
    rememberme: 'Lembrar-me',
    forgotpassword: 'Esqueceu sua Senha?',
    requiremail: 'O e-mail é obrigatório',
    requirepass: 'A senha é obrigatória',
    requirement: 'Ao me registrar, aceito os ',
    requirement2: ' Termos e Condições de Uso',
    requirement3: ' e a ',
    requirement4: 'Política de Privacidade',
  },
  virtual: {
    list: 'Lista de Prescrições',
    new: 'Nova Prescrição',
    active: 'Ativa',
    expired: 'Expirada',
    expiredate: 'Data de Expiração',
    renewdate: 'Data de Renovação',
    raw: 'Banda / Chip / TSI / Nº Prescrição',
    order: 'Código de Barras (apenas CAT)',
    review: 'Revisão',
    assignment: 'Atribuição de Códigos',
    sell: 'Venda',
    create: 'Criar Ordem',
    title: 'Tarefas Agendadas',
    number: 'Nº Ordem',
    status: 'Estado',
    date: 'Data de Início',
    patients: 'Nº de Pacientes',
    titleparameters: 'Parâmetros de Venda',
    confirm: 'Deseja excluir a prescrição selecionada?',
    deletesinc: 'Deseja excluir a sincronização selecionada?',
    productionform: 'Relatório de Produção',
    prepareinform: 'Preparar Relatório',
    export: 'Exportar para Excel',
  },

  trazability: {
    ema: 'Agência Europeia de Medicamentos (EMA)',
    text: 'A Agência Europeia de Medicamentos (EMA) é uma agência descentralizada da União Europeia (UE) responsável pela avaliação científica, monitoramento e supervisão da segurança de medicamentos na UE. A EMA é uma organização em rede cujas atividades envolvem milhares de especialistas de toda a Europa. Esses especialistas realizam o trabalho dos comitês científicos da EMA.',
    discover: 'Descubra a EMA',
    detections: 'Alertas Detectadas',
    eligible: 'Alertas Elegíveis para Correção',
    lastupdate: 'Última Atualização',
    totals: 'Total de Medicação Verificada',
  },
  reportpatientproductionlist: {
    lng_title: 'RELATÓRIO DE PACIENTES PRODUZIDOS',
    lng_titlelistcenter: 'LISTA DE PACIENTES',
    lng_phone: 'Telefone',
    lng_mail: 'E-mail',
    lng_num_production: 'Número de Produção',
    lng_center: 'Centro',
    lng_date_int: 'Data de Início',
    lng_date_end: 'Data Final',
    lng_code: 'Código',
    lng_name: 'Nome',
    lng_send: 'Envio',
    lng_reception: 'Recepção',
    lng_date: 'Data',
    lng_no_medication: 'Sem Mediação',
    lng_reviwer: 'Revisado por:',
    lng_produced: 'Produzido por:',
  },
  reportmedicineproductionlist: {
    lng_title: 'RELATÓRIO DE MEDICAÇÃO AGRUPADA',
    lng_title2: 'RELATÓRIO DE RECARGA',
    lng_title3: 'RELATÓRIO DE MEDICAÇÃO POR PACIENTE',
    lng_phone: 'Telefone',
    lng_mail: 'E-mail',
    lng_num_production: 'Nº de Produção',
    lng_center: 'Centro',
    lng_date_int: 'Data de Início',
    lng_date_end: 'Data Final',
    lng_code: 'Código',
    lng_name: 'Nome',
    lng_qty: 'Unidades',
    lng_yes: 'Sim',
    lng_no: 'Não',
    lng_packable: 'Emblistável',
    lng_date: 'Data',
    lng_dev: 'Tolva',
    lng_lot: 'Lote',
    lng_exp: 'Validade',
    lng_qty2: 'Restante',
  },
  reportposologyproductionlist: {
    lng_title_not_packing: 'MEDICAÇÃO NÃO EMBLISTÁVEL',
    lng_title_packing: 'MEDICAÇÃO EMBLISTÁVEL',
    lng_mo2: 'Segunda-feira',
    lng_tu2: 'Terça-feira',
    lng_we2: 'Quarta-feira',
    lng_th2: 'Quinta-feira',
    lng_fr2: 'Sexta-feira',
    lng_sa2: 'Sábado',
    lng_su2: 'Domingo',
    lng_title: 'PLANO FARMACOLÓGICO',
    lng_phone: 'Telefone',
    lng_mail: 'E-mail',
    lng_date: 'Data',
    lng_num_patient: 'Nº Paciente',
    lng_name_patient: 'Nome',
    lng_dni: 'CPF',
    lng_ss: 'Número do Seguro Social',
    lng_code_medicine: 'Código',
    lng_name_medicine: 'Nome do Medicamento',
    lng_posology: 'Posologia',
    lng_date_int: 'Data de Início',
    lng_date_end: 'Data Final',
    lng_cronic: 'Crônico',
    lng_mo: 'Seg',
    lng_tu: 'Ter',
    lng_we: 'Qua',
    lng_th: 'Qui',
    lng_fr: 'Sex',
    lng_sa: 'Sáb',
    lng_su: 'Dom',
    lng_day: 'Dias',
    lng_month: 'Meses',
    lng_ja: 'Jan',
    lng_fe: 'Fev',
    lng_ma: 'Mar',
    lng_ap: 'Abr',
    lng_my: 'Mai',
    lng_jn: 'Jun',
    lng_jl: 'Jul',
    lng_au: 'Ago',
    lng_se: 'Set',
    lng_oc: 'Out',
    lng_no: 'Nov',
    lng_de: 'Dez',
    lng_month_all: 'Todos os Meses',
    lng_hour: 'Cada',
    lng_hour_x: 'Horas a partir das',
  },
  reportchangeslist: {
    lng_title_not_packing: 'MEDICAÇÃO NÃO EMBLISTÁVEL',
    lng_title_packing: 'MEDICAÇÃO EMBLISTÁVEL',
    lng_mo2: 'Segunda-feira',
    lng_tu2: 'Terça-feira',
    lng_we2: 'Quarta-feira',
    lng_th2: 'Quinta-feira',
    lng_fr2: 'Sexta-feira',
    lng_sa2: 'Sábado',
    lng_su2: 'Domingo',
    lng_title: 'RELATÓRIO DE ALTERAÇÕES',
    lng_phone: 'Telefone',
    lng_mail: 'E-mail',
    lng_date: 'Data',
    lng_num_patient: 'Nº Paciente',
    lng_name_patient: 'Nome',
    lng_dni: 'CPF',
    lng_ss: 'Número do Seguro Social',
    lng_code_medicine: 'Código',
    lng_name_medicine: 'Nome do Medicamento',
    lng_posology: 'Posologia',
    lng_date_int: 'Data de Início',
    lng_date_end: 'Data Final',
    lng_cronic: 'Crônico',
    lng_mo: 'Seg',
    lng_tu: 'Ter',
    lng_we: 'Qua',
    lng_th: 'Qui',
    lng_fr: 'Sex',
    lng_sa: 'Sáb',
    lng_su: 'Dom',
    lng_day: 'Dias',
    lng_month: 'Meses',
    lng_ja: 'Jan',
    lng_fe: 'Fev',
    lng_ma: 'Mar',
    lng_ap: 'Abr',
    lng_my: 'Mai',
    lng_jn: 'Jun',
    lng_jl: 'Jul',
    lng_au: 'Ago',
    lng_se: 'Set',
    lng_oc: 'Out',
    lng_no: 'Nov',
    lng_de: 'Dez',
    lng_month_all: 'Todos os Meses',
    lng_hour: 'Cada',
    lng_hour_x: 'Horas a partir das',
    lng_id_center: 'Nº Centro',
    lng_state: 'Status',
    lng_state_add: 'Adicionado',
    lng_state_edit: 'Modificado',
    lng_state_delete: 'Eliminado',
  },

  sync: {
    new: 'Adicionar Sincronização',
  },
  reportpatientjournallist: {
    lng_title: 'RELATÓRIO DE DÍVIDA DOS PACIENTES',
    lng_phone: 'Telefone',
    lng_mail: 'E-mail',
    lng_date: 'Data',
    lng_num_patient: 'Nº Paciente',
    lng_name_patient: 'Nome',
    lng_dni: 'DNI',
    lng_ss: 'Número SS',
    lng_code_medicine: 'Código',
    lng_name_medicine: 'Nome do Medicamento',
    lng_prod: 'Produzido',
    lng_seller: 'Vendido',
    lng_aport: 'Contribuído',
    lng_total: 'Total',
  },
  reportpatientconsent: {
    lng_anx_1: 'desejo participar no programa de sistemas de dosagem personalizada da farmácia',
    lng_anx_2:
      'Entendo que esse programa consiste no acondicionamento da medicação em um sistema de dosagem personalizada.',
    lng_anx_3:
      'Participo voluntariamente desse programa e posso deixar de participar quando desejar simplesmente revogando esse consentimento preenchendo a seção de revogação do mesmo.',
    lng_anx_4:
      'Participar desse projeto implica, se feito a partir de embalagens comerciais, deixar os medicamentos em suas embalagens originais na farmácia, onde é responsabilidade do farmacêutico sua correta custódia e conservação.',
    lng_anx_5: 'Comprometo-me a:',
    lng_anx_51: 'Comunicar ao farmacêutico qualquer variação na minha medicação.',
    lng_anx_52: 'Cumprir as condições de conservação e segurança do blister.',
    lng_anx_53:
      'Entregar os dispositivos vazios das semanas anteriores para verificação do cumprimento e possíveis erros de utilização.',
    lng_anx_6: 'O farmacêutico se compromete a:',
    lng_anx_61: 'Comunicar-me qualquer problema relacionado ao medicamento que possa detectar.',
    lng_anx_62:
      'Fornecer-me as informações necessárias para o uso correto dos dispositivos de dosagem personalizada e dos próprios medicamentos.',
    lng_anx_7:
      'Autorizo o farmacêutico a tratar meus dados pessoais necessários para participar do programa*.',
    lng_consent: 'CONSENTIMENTO INFORMADO',
    lng_date: 'Data',
    lng_dni: 'DNI',
    lng_don_d: 'D/Dª',
    lng_lopd_1:
      '*Os dados pessoais fornecidos para inclusão no programa de sistemas de dosagem personalizada serão incluídos em um arquivo chamado SPD, devidamente registrado na Agência Espanhola de Proteção de Dados, cujo responsável é a farmácia',
    lng_lopd_2:
      ' contam com todas as medidas de segurança necessárias de acordo com a Lei de Proteção de Dados, serão tratados exclusivamente com o objetivo de gerenciar os dispositivos de dosagem personalizada do paciente, os dados podem ser cedidos ou comunicados a profissionais de saúde, familiares e/ou cuidadores relacionados ao paciente para corroborar o tratamento e/ou melhorar a adesão ao mesmo, em nenhum caso serão utilizados para fins publicitários ou promocionais, estão sujeitos ao sigilo profissional do farmacêutico. As pessoas cujos dados estão registrados nesses arquivos têm o direito de acessá-los, corrigi-los ou cancelá-los, quando considerarem apropriado, dirigindo-se à mesma farmácia nos termos previstos legalmente.',
    lng_mail: 'E-mail',
    lng_name_d: 'Paciente/Representante Legal',
    lng_name_patient: 'Nome',
    lng_name_ph_d: 'Farmacêutico',
    lng_name_ph_sin_d: 'Assinatura do Farmacêutico',
    lng_name_sin_d: 'Assinatura do Paciente/Representante Legal',
    lng_num_patient: 'Nº Paciente',
    lng_phone: 'Telefone',
    lng_ss: 'Número SS',
    lng_title: 'CONSENTIMENTO INFORMADO DO PACIENTE',
  },
  Bob: {
    ordernumber: 'Nº da Ordem',
    status: 'Status',
    process: 'Progresso',
    Startorder: 'Início da Ordem',
    createdate: 'Data de Criação',
    selling: 'Processando',
    reviewing: 'Revisando',
    wait: 'Em Espera',
    sevem: 'SEVEM',
    patient: 'Paciente',
    drug: 'Medicamento',
    recipe: 'Receita Eletrônica',
    initialdate: 'Data de Início do Tratamento',
    expiredate: 'Data de Expiração',
    posology: 'Posologia',
    scan: 'Escanear o Código',
    dep: 'Depósito',
    without1: 'Sem Posologia',
    without2: 'Sem Receita',
    reviwer: 'Análise',
    correct: 'Correto',
    lot: 'Lote',
    neworder: 'Criar Ordem',
    deleteorder: 'Deseja excluir a ordem de trabalho selecionada?',
    numberorder: 'Nº da Ordem:',
    history: 'Histórico de Trazabilidade',
    scancodes: 'Atribuição de Códigos',
    forceall: 'Forçar tudo para CN',
    last: 'Última Atualização',
    deleteparameter: 'Deseja excluir o parâmetro selecionado?',
    sellformtitle: 'RELATÓRIO DE VENDAS',
  },
  official: {
    error: 'Documento não disponível.',
  },
  display: {
    prospectus: 'Prospecto',
    technical: 'Ficha Técnica',
    unpackable: 'Não Embalável',
    titletake: 'Data e hora da tomada',
    titleproduction: 'Data da embalagem',
    titleexprepacking: 'Expiração do dispositivo SPD',
  },
  notfound: {
    sorry: 'Tomada não encontrada.',
    sorry2: 'Desculpe, não conseguimos encontrar a página que você está procurando.',
    home: 'Página Inicial',
  },
  editproduction: {
    edit: 'Edição da Produção',
    confirm: 'Tem certeza de que deseja modificar a produção?',
    home: 'Página Inicial',
    predose: 'Doses Preditivas',
    addnewpre: 'Nova Dose Preditiva',
    nametome: 'Nome da Dose',
    hourtome: 'Hora da Dose',
    confirmationtext: 'Tem certeza de que deseja excluir esta dose preditiva?',
  },
  blisterpro: {
    process: 'PROGRESSO',
    sorry2: 'Desculpe, não conseguimos encontrar a página que você está procurando.',
    home: 'Página Inicial',
  },

  various: {
    exhausted: 'Exausto',
    datapatient: 'Dados do Paciente',
    center: 'Dados do Centro',
    expiredon: 'Data de Validade:',
    synclist: 'Itens Sincronizados',
    debt: 'Dívida',
    drug: 'Medicamento',
    produced: 'Produzido',
    sell: 'Vendido',
    provided: 'Fornecido',
    total: 'Total',
    date: 'Data',
    Details: 'Detalhes',
    event: 'Evento',
    units: 'Unidades',
    production: 'Produção',
    sell2: 'Vendido',
    add: 'Adicionar Contribuição',
    historic: 'Histórico',
    historic2: 'Histórico de Rastreabilidade',
    ns: 'Nº de Série',
    available: 'Disponível',
    next: 'Próximamente',
    recomended: 'Recomendado',
    notrecomended: 'Não recomendado',
    register: 'Registro de Entregas',
    registerdate: 'Data da Entrega',
    from: 'De',
    to: 'Para',
    datapicker: 'Datas do Relatório ',
    download: 'Baixar',
    tpills: 'Total de Comprimidos',
    tneed: 'Restante',
    rbox: 'Caixas',
    result: 'resultados',
    predetermlist: 'Lista de Doses Preditivas',
    deletepredet: 'Deseja excluir a dose preditiva selecionada?',
    no_verifi: 'Não Verificado',
    verifi: 'Verificado',
    installation: 'Minha Instalação',
    exportamts: 'Exportar ATMS',
    confirmdose: 'Deseja registrar a Dose?',
    whynot: 'Motivo de Cancelamento',
  },
  tooltip: {
    refill: 'Relatório de Recarga',
    label: 'Imprimir Etiquetas',
    edit: 'Editar Produção',
    grouped: 'Relatório de Medicamentos Agrupados',
    report1: 'Relatório Paciente-Medicamento',
    delivery: 'Nota de Entrega',
    archived: 'Arquivados',
    delete: 'Excluir Produção',
    generateproduction: 'Gerar Produção',
    repackaging: 'Produção de Reembalagem',
    start: 'Iniciar Produção',
    list: 'Lista de Pacientes',
    add: 'Novo Paciente',
    listposology: 'Posologia por Paciente',
    official: 'Relatório de Posologia Oficial',
    activate: 'Ativar/Desativar WhatsApp',
    consent: 'Consentimento Informado',
    alllabel: 'Todas',
    changes: 'Relatório de Mudanças',
    sevem: 'Finalizar Venda',
    new: 'Nova Tarefa',
    copy: 'Copiar',
    insert: 'Inserir',
    newparameter: 'Novo Parâmetro',
    center: 'Novo Centro',
  },
  import: {
    e_title: 'Atenção! O arquivo importado pode estar incorreto',
    e_dialog: 'Parece que importamos um arquivo da residência:',
    e_dialog1: 'em vez da residência:',
    e_dialog2: 'Por favor, revise e verifique a situação.',
    i_title: 'Importar Arquivo',
    i_type: 'Formato do Arquivo',
    i_drop: 'Selecionar Arquivo',
    i_dropb:
      'Você pode arrastar e soltar o arquivo aqui ou explorar em seu computador para selecioná-lo.',
    0: 'Resiplus (*.txt)',
    1: 'Resiplus Multi (*.txt)',
    2: 'Resiplus (*.xls)',
    3: 'Resiplus Multi (*.xls)',
    4: 'Sanitas (*.pdf)',
    5: 'DomusVi (*.txt)',
    6: 'DomusVi (*.pdf)',
    7: 'Pill Device (*.json)',
    8: 'Cretem Atms (*.xml)',
    9: 'Cretem Atms Multi (*.zip)',
    10: 'Jvm Oncube (*.txt)',
    11: 'Jvm Oncube Multi (*.zip)',
    12: 'Ecap (*.txt)',
  },
  module_: {
    title_: 'Módulo não disponível com a licença contratada',
    subtitle_: 'Entre em contato com o departamento comercial para solicitar uma demonstração!',
    return_: 'Voltar para o Aplicativo',
  },
  permit: {
    permit: 'Gerenciar Permissões',
  },
  permissionsgroup: {
    1: 'Catálogo de Medicamentos',
    2: 'Gestão de Pacientes',
    3: 'Posologia do Paciente',
    4: 'Módulo de Produção',
    5: 'Importações',
    6: 'IA Mery',
    7: 'Rastreabilidade',
    8: 'Administração de Usuários',
    9: 'Administração de Centros',
    10: 'Gerenciar dados da Minha Farmácia',
    11: 'Entrega de Medicamentos',
    12: 'Relatórios',
  },
  permissions: {
    1: 'Ativar Catálogo de Medicamentos',
    2: 'Modificar Medicamentos',
    3: 'Visualizar dados do Paciente',
    4: 'Modificar dados do Paciente',
    5: 'Visualizar Receita Eletrônica',
    6: 'Adicionar ou Remover Receita Eletrônica',
    7: 'Visualizar Sincronização',
    8: 'Adicionar ou Remover Sincronizações',
    9: 'Visualizar consumos do paciente',
    10: 'Modificar consumos do paciente',
    11: 'Ativar Posologia',
    12: 'Modificar Posologia',
    13: 'Ativar Módulo de Produção',
    14: 'Criar Produção',
    15: 'Enviar Produção / Seção de Blister Manual',
    16: 'Editar Produção',
    17: 'Excluir Produção',
    18: 'Ativar Módulo de Importação',
    19: 'Importar arquivos para o sistema',
    20: 'Habilitar IA Mery',
    21: 'Visualizar Tarefas Programadas',
    22: 'Adicionar Tarefas',
    23: 'Visualizar Cartões de Saúde',
    24: 'Inserir Cartões',
    25: 'Visualizar Disponíveis',
    26: 'Visualizar Cruzamento de Posologia / Receita',
    27: 'Ativar Parâmetros de Venda',
    28: 'Criar Parâmetro de Venda',
    29: 'Ativar seção de Pacientes',
    30: 'Habilitar módulo de Controle de doses',
    31: 'Entrega de medicamentos aos Pacientes',
    32: 'Ativar Rastreabilidade',
    33: 'Ativar Administração de Usuários',
    34: 'Modificar Usuários',
    35: 'Ativar Gestão de Centros',
    36: 'Modificar Centros',
    37: 'Ativar Minha Instalação',
    38: 'Modificar Minha Instalação',
    39: 'Ativar Relatórios',
    40: 'Relatório de Produção',
  },
  control_takes: {
    1: 'Não Necessário',
    2: 'Falta de Medicamento',
    3: 'Medicação Danificada',
    4: 'Paciente Recusa',
    5: 'Outros',
    cause_not_take: 'Motivo',
    6: 'Hora Errada',
    7: 'Alteração na Medicação',
    8: 'Adicionar',
    9: 'Retirar',
    10: 'Registrar Toma',
  },
  home: {
    home: 'Início',
    title: 'Simplifique sua Medicação',
    titletex:
      'Bem-vindo à vanguarda da gestão de medicamentos. Nosso Software de Sistemas Personalizados de Dosagem (SPD) redefine a forma como você se relaciona com sua saúde. Esqueça as complicações na preparação de seus medicamentos. Com nossa solução intuitiva e personalizada, a adesão ao tratamento se torna uma experiência simples e eficiente.',
    available: 'Disponível em',
    benefits: 'Benefícios do SPD',
    titlebenefit1: 'Aviso de Dosagem',
    benefit1:
      'Otimize sua rotina com nosso aplicativo que oferece lembretes precisos para cada dose. Esqueça as preocupações com esquecimentos e garanta seu bem-estar. Além disso, consulte as incompatibilidades e interações entre seus medicamentos para uma gestão completa e segura de sua saúde.',
    titlebenefit2: 'Serviço Personalizado',
    benefit2:
      'Experimente um novo padrão de conforto em seu cuidado de saúde. Com IDOOSE, você receberá sua medicação em doses meticulosamente preparadas por farmacêuticos altamente capacitados. Nos dedicamos a fornecer uma experiência sem complicações, trazendo qualidade e simplificando cada dose.',
    titlebenefit3: 'Preparamos sua Medicação',
    benefit3:
      'Adeus à complicação de preparar caixas. Com nosso sistema de doses unitárias correlatas, a tomada de sua medicação é tão fácil quanto abrir e pronto. Cada dose contém informações detalhadas, incluindo o prospecto, para oferecer conforto e clareza em cada etapa de seu tratamento.',
    appidoose: 'Com IDOOSE, você nunca esquecerá sua Medicação.',
    appidoosetext:
      'Baixe o aplicativo, registre-se e entraremos em contato com sua Farmácia mais próxima.',
    listposology: 'Posologia por Paciente',
    design: 'Projetado para Você',
    partner: 'Quer colaborar conosco?',
    partner2: 'TORNE-SE PARCEIRO',
    partnertext:
      'Destacamo-nos como líderes incontestáveis na aplicação de inteligência artificial no Sistema Personalizado de Dosagem (SPD). Ao escolher-nos, garantirá a eficiência de suas produções e eliminará completamente qualquer risco de erro no processo de produção. Confie em nossa experiência para levar suas operações ao mais alto nível de precisão e segurança.',
    prices: 'PREÇOS E PLANOS',
    licence: 'Licença',
    patient: 'Gestão de Pacientes',
    production: 'Módulo de Produção',
    import: 'Importação de Arquivo Robô',
    blister: 'Produção de Blister Manual',
    update: 'Atualizações',
    support: 'Suporte Técnico',
    patient30: 'Até 50 Pacientes',
    patient31: 'Pacientes Ilimitados',
    rx: 'Conexão com Receita Eletrônica',
    inform: 'Relatório de Posologia CCAA',
    warning: 'Alterações na Receita Eletrônica',
    next: 'Próximas Dispensações',
    mach: 'Cruzamento de Posologia com Receita Eletrônica',
    sell: 'Módulo de Vendas',
    question: 'Ainda tem perguntas?',
    question2:
      'Para oferecer a consultoria mais adequada, nos informe se você representa uma farmácia, hospital ou laboratório. Dessa forma, podemos fornecer informações personalizadas de acordo com as particularidades de seu setor.',
    contact: 'Contate-nos',
    reserved: 'Todos os direitos reservados, desenvolvido por',
    forgotpasswordintro:
      'Digite o endereço de e-mail associado à sua conta e enviaremos um link para redefinir sua senha.',
    request: 'Enviar Solicitação',
    returnto: 'Voltar para o login',
    choose: 'Necessita de um robô SPD?',
    choose1: 'Descubra os nossos robôs SPD',
    choose2:
      'Dispomos de uma vasta gama de robots SPD para responder às suas necessidades específicas. Propomos modelos concebidos em função do volume de produção, do nível de automatização e do formato pretendido, quer se trate de blister ou de saco. Além disso, dispomos de robôs equipados com a tecnologia Oculus, que integra a verificação e o controlo no próprio equipamento.',
    siglas: 'SPD',
    robottitle: 'Explore Nossa Gama de Robôs',
    robotdescription:
      'Se você está buscando otimizar a automação na preparação de SPD, nossos robôs são seu aliado ideal. Temos uma ampla gama de robôs SPD, desde os altamente automatizados até os que requerem menos automação, para nos adaptarmos às suas necessidades específicas. Graças à nossa tecnologia avançada e aos funis universais FSP que oferecemos, você poderá reduzir significativamente a dependência da calibração. Além disso, nossos robôs estão equipados com o sistema OCULUS integrado, que fornece evidências fotográficas detalhadas de cada produção, bem como análises abrangentes de todas as doses.',
    catalog: 'Baixar Catálogo',
    diff: 'Principais Vantagens de Nossos Robôs SPD',
    diff1: 'FSP Universais',
    diff1a:
      'Com os FSP Universais, você poderá usar a medicação que precisa no momento certo sem complicações e sem calibrações. Nossa tecnologia inovadora garante o uso de qualquer tipo de medicamento que você deseje, independentemente de sua forma ou tamanho. Você terá maior liberdade em sua produção.',
    diff2: 'Alta Rotação',
    diff2a:
      'Para garantir a disponibilidade constante das moléculas mais utilizadas, realizaremos uma calibração cuidadosa. Nossos especialistas realizarão um estudo abrangente para validar essa seleção, garantindo assim uma calibração precisa e uma automação eficiente. Esse enfoque nos permite alcançar uma automação ótima.',
    diff3: 'Rastreabilidade TOTAL',
    diff3a:
      'Com nossos robôs, gerenciar sua medicação é mais simples do que nunca. Basta escanear o código Sevem para registrar o lote e a validade em cada dose produzida. Esse processo rápido e eficiente oferece a capacidade de acompanhar detalhadamente sua medicação, garantindo um controle preciso e seguro da mesma.',
    robotnews: 'Sistema OCULUS',
    robotnews2:
      'Desde Robotik, desenvolvemos a tecnologia OCULUS, que é responsável por revisar e analisar toda a produção por meio de visão artificial. Com OCULUS, esqueça o tempo dedicado à revisão manual da produção; nossa tecnologia faz isso por você. Além disso, sempre deixa uma evidência fotográfica de tudo o que foi produzido com seu robô Robotik. Em resumo, OCULUS oferece total tranquilidade.',
    discover: 'Descubra as características de cada Robô SPD',
    discover2: 'SELECIONE',
    tablerobots: 'O robô SPD ideal para todas as necessidades',
    footer:
      'A melhor solução de dose única SPD do mercado. Somos especialistas em implementar inteligência artificial nos processos de gestão.',
    corfirmform: 'Fórmula recebida!',
    corfirmform2:
      'Nossa equipe revisará as informações fornecidas e entraremos em contato em breve. Obrigado pelo seu interesse!',
    tel: 'Telefone',
  },
  myprofile: {
    edit: 'Editar',
    user: 'Usuário',
    name: 'Nome Completo',
    mail: 'E-mail',
    number: 'Número de Telefone',
    country: 'País',
    state: 'Estado',
    city: 'Cidade',
    address: 'Endereço',
    zip: 'CEP',
    company: 'Empresa',
    role: 'Função',
    save: 'Salvar Alterações',
    delete: 'Excluir Usuário',
    verify: 'E-mail Verificado',
    verifyinfo:
      'Desativar esta opção enviará automaticamente um e-mail de verificação para o usuário.',
    banned: 'Desativado',
    apply: 'Desativar Conta',
    new: 'Criar Usuário',
    permit: 'Permitidos *.jpeg, *.jpg, *.png, *.gif',
    max: 'tamanho máx. de',
    management: 'Gerenciamento',
    search: 'Pesquisar...',
    list: 'Lista de Usuários',
    changecenter: 'Alterar Centro',
    resetpass: 'Redefinir Senha',
    confirmdelete: 'Deseja realmente excluir o usuário selecionado?',
    dense: 'Compacto',
    confirmreset: 'E-mail enviado com sucesso',
    quick: 'Editar Permissões',
    otp: 'Gerar OTP',
    otptitle: 'Verificação em duas etapas',
    otpinfo: 'Quer gerar um QR para verificação em dois passos?',
    otpabout: 'Digitalize o código QR com o autenticador Google para gerar um código temporário e mutável.',
  },
  pages: {
    pages: 'Páginas',
    about: 'Sobre Nós',
    contact: 'Contate IDOOSE',
    inicio: 'Início',
    info: 'Informações',
    community: 'Comunidade',
    post: 'Publicações',
    robot: 'Robô Misturador',
  },
  about: {
    who: 'Quem Somos',
    about: 'Sobre Nós',
    contact: 'Contate IDOOSE',
    inicio: 'Início',
    info: 'Informações',
    community: 'Comunidade',
    post: 'Publicações',
    whotitle: 'Quem',
    are: 'Somos?',
    mision: 'Nossa missão é melhorar a saúde e o bem-estar de nossos pacientes',
    mision2: 'por meio da preparação precisa de medicamentos em doses unitárias, garantindo',
    mision3: 'uma administração segura e eficaz de forma personalizada.',
    whatis: 'O que é IDOOSE?',
    text: 'Somos mais do que um programa de gerenciamento de medicação, buscamos impulsionar uma mudança positiva na saúde das pessoas. Com o programa de gerenciamento mais avançado e acessível, nos esforçamos para fornecer ferramentas que não apenas simplifiquem a administração de medicamentos, mas também promovam a conexão comunitária e o bem-estar geral. Graças à nossa IA, oferecemos o serviço mais seguro e confiável do mercado na preparação de medicação. Na IDOOSE, oferecemos um caminho para uma vida mais saudável, simples e segura.',
    legaltitle: "AVISO LEGAL",
  legalare: "TERMOS E CONDIÇÕES GERAIS DE USO",
  legaltitle1: "I. INFORMAÇÕES GERAIS",
  legaltitle2: "DADOS DE CONTATO",
  legaltitle3: "II. TERMOS E CONDIÇÕES DE USO",
  legaltitle4: "O Usuário",
  legaltitle5: "III. ACESSO E NAVEGAÇÃO NO SITE: EXCLUSÃO DE GARANTIAS E RESPONSABILIDADE",
  legaltitle6: "IV. POLÍTICA DE LINKS",
  legaltitle7: "V. PROPRIEDADE INTELECTUAL E INDUSTRIAL",
  legaltitle8: "VI. AÇÕES LEGAIS, LEGISLAÇÃO APLICÁVEL E JURISDIÇÃO",
  legaldata: "Nome da empresa",
  legaldata1: "NIF",
  legaldata2: "Endereço",
  legaldata3: "Telefone de contato",
  legaldata4: "E-mail de contato",
  legalsubtitile: "Em conformidade com a Lei 34/2002, informa-se os usuários sobre a titularidade do site www.idoose.com",
  legaltext: "Idoose Robotik SL é uma empresa localizada em Lorquí, na província de Múrcia. Sua sede fiscal está situada na Calle Castillo De Moratalla, 45. 30564, Lorquí (Múrcia), Espanha. Portanto, a empresa Idoose Robotik SL está registrada no Registro Mercantil de Múrcia. Atua na atividade classificada como Programação, Consultoria e Outras Atividades Relacionadas à Informática, segundo o CNAE. Especificamente, a classe de Idoose Robotik SL é Atividades de Programação de Computadores, com o código numérico 6201. Seu CIF é B56740137 e, por sua natureza jurídica, é uma Sociedade Limitada.",
  legaltext1: "Em cumprimento ao dever de informação previsto na Lei 34/2002 de Serviços da Sociedade da Informação e do Comércio Eletrônico (LSSI-CE), de 11 de julho, são fornecidos a seguir os dados gerais deste site:",
  legaltext2: "A titularidade deste site, www.idoose.com (doravante, Site), pertence a: IDOOSE ROBOTIK SL, com NIF: B56740137",
  legaltext3: "O objetivo destes Termos e Condições Gerais de Uso (doravante, Condições) é regular o acesso e a utilização do Site. Para os fins destas Condições, entende-se por Site: a aparência externa das interfaces de tela, tanto de forma estática como dinâmica, ou seja, a estrutura de navegação; e todos os elementos integrados nas interfaces de tela e na estrutura de navegação (doravante, Conteúdos) e todos os serviços ou recursos online que, eventualmente, sejam oferecidos aos Usuários (doravante, Serviços).",
  legaltext4: "A IDOOSE reserva-se o direito de modificar, a qualquer momento e sem aviso prévio, a apresentação e configuração do Site, bem como os Conteúdos e Serviços nele incorporados. O Usuário reconhece e aceita que, a qualquer momento, a IDOOSE poderá interromper, desativar e/ou cancelar qualquer um desses elementos ou o acesso aos mesmos.",
  legaltext5: "Além do custo de conexão pela rede de telecomunicações fornecida pelo provedor contratado pelo Usuário, alguns dos Conteúdos ou Serviços oferecidos pela IDOOSE ou por terceiros através do Site podem estar sujeitos à contratação prévia. Nesse caso, isso será claramente especificado e/ou serão disponibilizadas as Condições Gerais ou Específicas correspondentes.",
  legaltext6: "A utilização de determinados Conteúdos ou Serviços do Site poderá requerer inscrição ou registro prévio do Usuário.",
  legaltext7: "O acesso, navegação e uso do Site, assim como das áreas destinadas à interação entre os Usuários, ou entre Usuário e IDOOSE, como comentários e/ou blogs, confere a condição de Usuário. Ao navegar no Site, o Usuário aceita integralmente as Condições aqui estabelecidas, bem como suas modificações futuras, sem prejuízo da aplicação da legislação obrigatória aplicável. Em razão disso, recomenda-se que o Usuário as leia sempre que visitar o Site.",
  legaltext8: "O Site da IDOOSE oferece uma grande variedade de informações, serviços e dados. O Usuário assume responsabilidade pelo uso correto do Site. Essa responsabilidade se estende a:",
  legaltext9: "• O uso das informações, Conteúdos e/ou Serviços e dados oferecidos pela IDOOSE de forma que não viole o disposto nestas Condições, na lei, na moral ou na ordem pública, ou que prejudique direitos de terceiros ou o próprio funcionamento do Site.",
  legaltext10: "• A veracidade e legalidade das informações fornecidas pelo Usuário nos formulários oferecidos pela IDOOSE para acesso a determinados Conteúdos ou Serviços. Em qualquer caso, o Usuário deverá notificar imediatamente a IDOOSE sobre qualquer uso indevido das informações cadastradas, como roubo, perda ou acesso não autorizado a identificadores e/ou senhas, com o objetivo de cancelamento imediato.",
  legaltext11: "A IDOOSE reserva-se o direito de remover comentários e contribuições que violem a lei, a dignidade da pessoa, que sejam discriminatórios, xenófobos, racistas, pornográficos, de spam, que atentem contra a juventude ou infância, contra a ordem ou segurança pública ou que, a seu critério, não sejam adequados à publicação.",
  legaltext12: "Em qualquer caso, a IDOOSE não se responsabiliza pelas opiniões expressas por Usuários através de comentários ou outras ferramentas de participação.",
  legaltext13: "O mero acesso a este Site não implica o início de qualquer relação comercial entre IDOOSE e o Usuário.",
  legaltext14: "O Usuário declara ser maior de idade e possuir capacidade jurídica suficiente para se vincular a estas Condições. Portanto, este Site não é destinado a menores de idade. A IDOOSE se isenta de qualquer responsabilidade pelo descumprimento deste requisito.",
  legaltext15: "A IDOOSE não garante a continuidade, disponibilidade ou utilidade do Site, de seus Conteúdos ou Serviços. A IDOOSE fará todo o possível para o bom funcionamento do Site, mas não garante que o acesso será ininterrupto ou livre de erros.",
  legaltext16: "Também não se responsabiliza nem garante que o conteúdo ou software acessado por meio do Site esteja livre de erros ou cause danos ao sistema informático (hardware e software) do Usuário. Em nenhum caso a IDOOSE será responsável por perdas, danos ou prejuízos de qualquer natureza decorrentes do acesso, navegação ou uso do Site, incluindo danos causados por vírus.",
  legaltext17: "A IDOOSE também não se responsabiliza por danos que possam ser causados aos usuários por uso inadequado do Site. Em especial, não se responsabiliza por falhas, interrupções ou defeitos nas telecomunicações.",
  legaltext18: "O Usuário ou terceiro que criar um hiperlink de outro site para o Site da IDOOSE deve estar ciente de que:",
  legaltext19: "Não é permitida a reprodução — total ou parcial — de nenhum Conteúdo e/ou Serviço do Site sem autorização expressa da IDOOSE.",
  legaltext20: "Também não é permitida qualquer declaração falsa, imprecisa ou incorreta sobre o Site da IDOOSE ou seus Conteúdos e/ou Serviços.",
  legaltext21: "Exceto pelo hiperlink, o site em que o link for inserido não poderá conter qualquer elemento protegido por direitos de propriedade intelectual do ordenamento jurídico espanhol, salvo autorização expressa da IDOOSE.",
  legaltext22: "O estabelecimento do hiperlink não implica a existência de relação entre IDOOSE e o titular do site de onde for feito, nem o conhecimento ou aceitação, por parte da IDOOSE, dos conteúdos, serviços e/ou atividades ali oferecidos.",
  legaltext23: "A IDOOSE, por si ou como cessionária, é titular de todos os direitos de propriedade intelectual e industrial do Site, bem como dos elementos nele contidos (a título exemplificativo: imagens, som, áudio, vídeo, software, textos, marcas, logotipos, combinações de cores, estrutura, design, seleção de materiais utilizados, programas necessários ao seu funcionamento, etc.). Estes estão protegidos como propriedade intelectual pela legislação espanhola e comunitária, bem como por tratados internacionais subscritos pela Espanha.",
  legaltext24: "Todos os direitos reservados. De acordo com a Lei de Propriedade Intelectual, é expressamente proibida a reprodução, distribuição e comunicação pública — incluindo a modalidade de disponibilização — de qualquer conteúdo deste site, para fins comerciais, em qualquer meio ou suporte técnico, sem autorização da IDOOSE.",
  legaltext25: "O Usuário compromete-se a respeitar os direitos de propriedade intelectual e industrial da IDOOSE. Poderá visualizar os elementos do Site e até imprimi-los, copiá-los e armazená-los no seu disco rígido ou em qualquer outro suporte físico, exclusivamente para uso pessoal. O Usuário não poderá, no entanto, suprimir, alterar ou manipular qualquer dispositivo de proteção ou sistema de segurança instalado no Site.",
  legaltext26: "Caso o Usuário ou terceiro considere que qualquer Conteúdo do Site viole direitos de propriedade intelectual, deverá comunicar imediatamente à IDOOSE através dos dados de contato indicados na seção INFORMAÇÕES GERAIS deste Aviso Legal e Termos de Uso.",
  legaltext27: "A IDOOSE reserva-se o direito de exercer todas as ações civis ou penais que considerar necessárias em caso de uso indevido do Site e dos Conteúdos ou do descumprimento das presentes Condições.",
  legaltext28: "A relação entre o Usuário e a IDOOSE será regida pela legislação vigente aplicável no território espanhol. Em caso de controvérsia relacionada à interpretação e/ou aplicação destas Condições, as partes se submeterão à jurisdição dos tribunais competentes conforme a legislação aplicável."

  },
  contact: {
    where: 'Onde',
    find: 'estamos?',
    contact: 'Não hesite em entrar em contato conosco. Ficaremos felizes em atendê-lo.',
    name: 'Nome',
    email: 'E-mail',
    subjet: 'Assunto',
    message: 'Digite sua mensagem aqui.',
    submit: 'Enviar',
  },
  rulesimport: {
    list: 'Parâmetros de Importação',
    form: 'Relatório de Vendas',
    step1: 'Selecionar Arquivo',
    step2: 'Revisão de Conflitos',
    step3: 'Upload do arquivo',
    importcorrect: 'Correto',
    sugest: 'Recomendação',
    new: 'Novo',
    noconflicts: 'Arquivo validado com sucesso, nenhum conflito detectado.',
    sureimport: 'O banco de dados foi atualizado, clique em concluir para finalizar a importação.',
    importcomplete: 'A importação foi concluída com sucesso.',
  },
  calendar: {
    title: 'Calendário',
    event: 'Novo Evento',
    month: 'Mês',
    week: 'Semana',
    day: 'Dia',
    agenda: 'Agenda',
    today: 'Hoje',
    new: 'Adicionar Evento',
    new2: 'Título',
    description: 'Descrição',
    allday: 'Dia Inteiro',
    start: 'Data de Início',
    end: 'Data de Término',
  },
  tablerobots: {
    tolvas: 'Número de funis',
    univers: 'FSP Universais',
    width: 'Largura (cm)',
    height: 'Altura (cm)',
    depth: 'Profundidade (cm)',
  },
  yup: {
    yup_min: 'Muito curto!',
    yup_max: 'Muito tempo!',
    yup_required: 'Campo obrigatório!',
    yup_email: 'O e-mail deve ser um endereço de e-mail válido!',
    yup_phone: 'O número de telefone deve conter apenas dígitos!',
  },
  cookie_consent: {
    acept: 'Aceitar',
    reject: 'Declínio',
    message: 'Nosso site utiliza cookies para analisar o tráfego e melhorar nossos serviços.',
  },
  form_geometric: {
    1: 'Alongado',
    2: 'bolha',
    3: 'Anel',
    4: 'Arco',
    5: 'Bala',
    6: 'Biconvexo',
    7: 'Cápsula',
    8: 'Cartucho',
    9: 'Cilíndrico',
    10: 'Côncava',
    11: 'Quadrado',
    12: 'Elíptico',
    13: 'Esférico',
    14: 'Gás',
    15: 'Inalador',
    16: 'Lágrima',
    17: 'Líquido',
    18: 'maçã',
    19: 'Oblongo',
    20: 'Octogonal',
    21: 'Oval',
    22: 'Poeira',
    23: 'Retangular',
    24: 'Rodada',
    25: 'Lombo',
    26: 'Sobre',
    27: 'Triangular',
    28: 'Frasco',
  },
  form_size: {
    0: 'Indefinido',
    1: '2 mm (Micro)',
    2: '4 mm (Pequeno)',
    3: '8 mm (Médio)',
    4: '12 mm (Grande)',
    5: '18.0 mm (Muito Grande)',
    6: '23.3 mm (Extra Grande)',
  },
  form_desc: {
    form_geometric: 'Forma Geométrica',
    form_size: 'Tamanho',
    form_slot: 'Ranhurado',
  },
  termsconditions: {
    title: 'Termos e Condições de Uso da IDOOSE',
    subtitle: 'Bem-vindo à IDOOSE',
    parraf1:
      'Agradecemos por escolher a IDOOSE. Leia atentamente estes termos e condições antes de usar nosso software. Ao utilizar a IDOOSE, você aceita estes termos e condições. Se não concordar com algum destes termos, não utilize nosso software.',
    subtitle2: '1. Âmbito do Contrato e Aceitação',
    subtitle3: '1.1. Serviços cobertos no contrato',
    parraf2:
      'Este contrato se aplica a idoose.com e a qualquer outro software, site ou serviço fornecido pela IDOOSE ROBOTIK SL (coletivamente, os "serviços").',
    subtitle4: '1.2. Aceitação deste contrato',
    parraf3:
      'Ao utilizar os serviços da IDOOSE, você aceita estes termos e condições sem modificações. Se não concordar, não utilize os serviços.',
    subtitle5: '1.3. Modificação do contrato',
    parraf4:
      'A IDOOSE ROBOTIK SL reserva-se o direito de modificar estes termos e condições a qualquer momento. Notificaremos sobre qualquer alteração por e-mail ou através do nosso site. O seu uso continuado dos serviços após tais alterações constitui sua aceitação das mesmas.',
    subtitle6: '2. Uso dos Serviços e Restrições',
    subtitle7: '2.1. Uso do software',
    parraf5:
      'IDOOSE está licenciado, não vendido. Você deve cumprir todas as restrições técnicas do software e não pode: •Contornar as restrições técnicas do software •Descompilar, desmontar ou realizar engenharia reversa do software, exceto onde a lei permitir. •Fazer mais cópias do software do que as especificadas neste contrato. •Publicar o software para que outros o copiem. •Alugar, arrendar ou emprestar o software. •Transferir o software ou este contrato para um terceiro.',
    subtitle8: '2.2. Obrigações do usuário',
    parraf6:
      'Você se compromete a utilizar os serviços de maneira responsável e a não realizar nenhuma ação que possa prejudicar a IDOOSE ROBOTIK SL ou outros usuários. Isto inclui, mas não se limita a: •Não utilizar os serviços para atividades ilegais ou não autorizadas. •Não interferir com o funcionamento dos serviços ou acessar os serviços por métodos não autorizados. •Não coletar informações pessoais de outros usuários sem o seu consentimento. •Não carregar nem distribuir vírus ou qualquer outro tipo de software malicioso.',
    subtitle9: '3. Conta da IDOOSE',
    subtitle10: '3.1. Criação de conta',
    parraf7:
      'Para acessar certos serviços, você precisará de uma conta da IDOOSE. Você é responsável por manter a confidencialidade das informações da sua conta e senha.',
    subtitle11: '3.2. Uso da conta',
    parraf8:
      'Você é responsável por todas as atividades que ocorrem sob sua conta. Deve notificar imediatamente a IDOOSE ROBOTIK SL sobre qualquer uso não autorizado de sua conta ou qualquer outra violação de segurança.',
    subtitle12: '4. Conteúdo',
    subtitle13: '4.1. Propriedade do conteúdo',
    parraf9:
      'O conteúdo que você carrega ou transmite através dos serviços continua sendo de sua propriedade. A IDOOSE ROBOTIK SL não reivindica a propriedade do seu conteúdo.',
    subtitle14: '4.2. Responsabilidade do conteúdo',
    parraf10:
      'Você é o único responsável pelo conteúdo que carrega, armazena ou transmite através dos serviços. Você declara e garante que tem todos os direitos necessários para carregar tal conteúdo e que seu conteúdo não viola os direitos de terceiros.',
    subtitle15: '4.3. Revisão e remoção de conteúdo',
    parraf11:
      'A IDOOSE ROBOTIK SL reserva-se o direito de revisar o conteúdo carregado em seus servidores e remover qualquer conteúdo que, a seu critério, infrinja estes termos e condições ou a lei aplicável.',
    subtitle16: '5. Cancelamento dos Serviços',
    subtitle17: '5.1. Pelo usuário',
    parraf12:
      'Você pode cancelar sua conta da IDOOSE a qualquer momento através das páginas de administração da sua conta. O cancelamento entrará em vigor no final do seu período de faturamento.',
    subtitle18: '5.2. Pela IDOOSE ROBOTIK SL',
    parraf13:
      'A IDOOSE ROBOTIK SL reserva-se o direito de cancelar sua conta ou acesso aos serviços a qualquer momento, com ou sem motivo, e sem aviso prévio.',
    subtitle19: '6. Legislação aplicável',
    parraf14:
      'Este contrato é regido pelas leis da Espanha, sem dar efeito a qualquer escolha de princípios legais. Qualquer disputa que surja em relação a este contrato será submetida à jurisdição exclusiva dos tribunais da Espanha.',
    subtitle20: '7. Dados de contato',
    parraf15:
      'Para qualquer dúvida sobre este contrato, pode entrar em contato conosco em info@idoose.com.',
  },
  privacy: {
    title: 'Política de Privacidade',
    subtitle: 'IDOOSE ROBOTIK SL',
    parraf1:
      'Na IDOOSE ROBOTIK SL, com sede na Calle Castillo de Moratalla 45, Lorquí, Murcia, CP 30564 e CIF B56740137, estamos comprometidos em proteger a privacidade e segurança de nossos usuários. Esta Política de Privacidade descreve como coletamos, usamos e protegemos suas informações pessoais quando você utiliza nosso software.',
    subtitle2: '1. Informações que Coletamos',
    parraf2:
      'Podemos coletar os seguintes tipos de informações: Informações de identificação pessoal: Nome, endereço, número de telefone, endereço de e-mail e qualquer outra informação que você forneça voluntariamente. Informações de uso do software: Dados sobre como você interage com nosso software, incluindo as funções que utiliza e a frequência de uso. Informações técnicas: Dados técnicos sobre seu dispositivo, como endereço IP, tipo de navegador, sistema operacional e configuração de idioma.',
    subtitle3: '2. Uso das Informações',
    parraf3:
      'Usamos as informações coletadas para: Fornecer, operar e manter nosso software. Melhorar, personalizar e expandir nosso software. Entender e analisar como você utiliza nosso software. Desenvolver novas funções, produtos e serviços. Comunicar-se com você, seja diretamente ou através de um de nossos parceiros, incluindo para atendimento ao cliente, fornecer atualizações e outras informações relacionadas ao software, e para fins de marketing e promocionais. Processar suas transações e gerenciar sua conta. Enviar e-mails periódicos.',
    subtitle4: '3. Proteção de suas Informações',
    parraf4:
      'Implementamos uma variedade de medidas de segurança para manter a segurança de suas informações pessoais quando você insere, envia ou acessa suas informações pessoais. No entanto, lembre-se de que nenhum método de transmissão pela Internet ou método de armazenamento eletrônico é 100% seguro.',
    subtitle5: '4. Compartilhamento de suas Informações',
    parraf5:
      'Não vendemos, trocamos ou transferimos para terceiros suas informações de identificação pessoal. Isso não inclui terceiros confiáveis que nos ajudam a operar nosso software, conduzir nossos negócios ou atendê-lo, desde que essas partes concordem em manter essas informações confidenciais. Também podemos divulgar suas informações quando acreditamos que a divulgação é apropriada para cumprir a lei, fazer cumprir nossas políticas do site ou proteger nossos direitos, propriedade ou segurança.',
    subtitle6: '5. Seus Direitos',
    parraf6:
      'Você tem o direito de acessar, retificar, cancelar e opor-se ao tratamento de seus dados pessoais. Para exercer esses direitos, você pode entrar em contato conosco pelo e-mail info@idoose.com ou enviando uma carta para nosso endereço: Calle Castillo de Moratalla 45, Lorquí, Murcia, CP 30564.',
    subtitle7: '6. Alterações a esta Política de Privacidade',
    parraf7:
      'Reservamo-nos o direito de atualizar esta Política de Privacidade a qualquer momento. Notificaremos qualquer alteração publicando a nova Política de Privacidade nesta página. Recomenda-se revisar esta Política de Privacidade periodicamente para estar informado de quaisquer alterações.',
    subtitle8: '7. Contato',
    parraf8:
      'Se tiver alguma dúvida sobre esta Política de Privacidade, pode entrar em contato conosco: IDOOSE ROBOTIK SL Calle Castillo de Moratalla 45, Lorquí, Murcia, CP 30564 E-mail: info@idoose.com',
  },
  payments: {
    title: 'Resumo de compra',
    totalFacturado: 'Total faturado',
    taxes:'Mais impostos aplicáveis',
    compra: 'Comprar',
    securitycard: 'Pagamento seguro com cartão de crédito',
    encrypted: 'Este é um pagamento seguro criptografado SSL de 128 bits',
    subscription: 'Subscrição',
    subrealizada:'Assinatura do plano com sucesso!',
    manage:'Gerencie suas informações de faturamento',
    mes:'/ Mês'
  },
  patient_medicine_journal_list: {
    lng_general: 'Geral',
    lng_patient: 'Doentes',
    lng_history: 'Histórico',
    lng_date: 'Data',
    lng_patient_id: 'Não. Doente',
    lng_patient_name: 'Nome do doente',
    lng_produced: 'Produzido',
    lng_sold: 'Vendido',
    lng_provided: 'Fornecido',
    lng_medicine_name: 'Nome do Medicamento',
    lng_total: 'Total',
    lng_event: 'Estado',
    lng_units: 'Unidades',
    lng_sales: 'Vendas',
    lng_aport: 'Contribuição',
  },
  qrscan: {
    barcode: 'Código de Barras',
    barcodeadd: 'Folha adicionada com sucesso',
    barcodeerror: 'A folha já existe',
    barcodetitle: 'Gestão de Folhas de Cupons de Selos',
    barcodeaddb: 'Adicionar Folha',
    barcodecp: 'Cupons de selo',
    barcodeaddbarcode: 'Escaneie a Folha de Cupons de Selos!',
  },
  
};

export default pt;
