const fr = {
  demo: {
    title: 'France',
    introduction: '',
  },
  resetpass: {
    mesage: 'Mot de passe oublié?',
    info: "Entrez l'adresse e-mail associée à votre compte et nous vous enverrons un lien pour réinitialiser votre mot de passe.",
    reset: 'Réinitialiser',
    back: 'Retour',
    sent: 'Demande envoyée avec succès',
    confirmation: 'Nous avons envoyé un e-mail de confirmation à',
    check: 'Veuillez vérifier votre e-mail',
    check1:
      'Nous vous avons envoyé un code de confirmation à 6 chiffres par e-mail. Veuillez saisir le code dans le champ ci-dessous pour vérifier votre e-mail.',
    verify: 'Vérifier',
    resend: 'Renvoyer le code',
    donthave: "Vous n'avez pas reçu le code?",
    return: "Retour à la page d'accueil",
    success: 'Demande envoyée avec succès!',
    success2:
      'Nous vous avons envoyé un message de confirmation à 6 chiffres à votre adresse e-mail.',
    success3: 'Veuillez saisir le code dans le champ ci-dessous pour vérifier votre e-mail.',
    newpass: 'Mettre à jour le mot de passe',
    confirmpass: 'Confirmer le nouveau mot de passe',
    pass: 'Mot de passe',
    checkpegatina: "Vérifiez l'autocollant physique",
    checkpegatina2: "Pour garantir la sécurité de vos données sensibles, le code PIN de confirmation à 6 chiffres est imprimé sur l'autocollant physique. Entrez ce code PIN dans la case ci-dessous pour garantir l'accès aux informations confidentielles. Si vous n'avez pas le code PIN, connectez-vous et il ne sera pas nécessaire de le saisir.",
    errorpin: 'Mauvaise broche',
    checkotp: 'Vérifiez votre candidature à deux facteurs',
    checkotp2: 'Vérifiez votre application Google Authenticator pour obtenir le code permettant de vérifier votre compte.',
  },
  newparameter: {
    back: 'Retour',
    next: 'Suivant',
    scope: 'Portée du Paramètre',
    type: 'Type de Paramètre',
    config: 'Configuration',
    posology: 'Vendre conformément à la Posologie',
    swap: 'Substitution de Prescription',
    notdispense: 'Non dispensation',
    priority: 'Prioriser les Médicaments en Vedette Farmatic',
    add: 'Terminer',
    priorityconfirm:
      'À partir de maintenant, nous donnerons la priorité à vos Médicaments en Vedette.',
    posologyconfirm:
      'À partir de maintenant, seul ce qui est prescrit dans la Posologie sera vendu.',
    selectsust: 'Prescription à Substituer',
    selectsust2: 'Prescription à Dispenser.',
    notdispence2: 'Sélectionnez la prescription que vous ne souhaitez pas dispenser.',
    number: 'Numéro',
    scopetable: 'Affecte',
    name: 'Nom',
    typeparameter: 'Type de Paramètre',
    code: 'Codes Nationaux',
    patient: 'Patient',
    center: 'Centre',
    pharmacy: 'Pharmacie',
    swaptype: 'Substitution de Prescription',
    prioritytype: 'Prioriser les Médicaments en Vedette',
    posologytype: 'Uniquement ce qui est prescrit dans la Posologie',
    notdispencetype: 'Ne pas dispenser',
    no_financied: 'Uniquement Financés',
    no_narcotic: 'Ne pas vendre de Stupéfiants',
    maxstock: 'Stock Maximum',
    max_box_dps: 'Unités Maximales',
    no_regex: 'Non dispensation par "mot-clé"',
    order_lab: 'Prioriser le Laboratoire',
    check_stock: 'Vendre avec Stock',
    force_posology: 'Vendre selon les CN de Posologie',
    laboratory: 'Liste des Laboratoires',
    replace_max_stock: 'Substitution par Stock Maximum',
    cst_change: 'Dernière recharge dans la trémie',
  },
  table: {
    column: 'Colonnes',
    find: 'Trouvez votre colonne.',
    columntitle: 'Titre de la colonne',
    hyde: 'Décocher tout',
    mark: 'Sélectionner tout',
    filters: 'Filtres',
    density: "Format d'Affichage",
    density1: 'Compact',
    density2: 'Normal',
    density3: 'Grand',
    export: 'Exporter',
    operators: 'Opérateurs',
    contains: 'contient',
    equals: 'est égal à',
    start: 'commence par',
    end: 'se termine par',
    empty: 'est vide',
    noempty: "n'est pas vide",
    value: 'Valeur',
    filtervalue: 'Filtrer la valeur',
    download: 'Télécharger en CSV',
    print: 'Imprimer',
    download2: 'Télécharger en Excel',
    columnMenuLabel: 'Menu',
    columnMenuShowColumns: 'Afficher les colonnes',
    columnMenuManageColumns: 'Gérer les colonnes',
    columnMenuFilter: 'Filtrer',
    columnMenuHideColumn: 'Masquer la colonne',
    columnMenuUnsort: 'Désordonner',
    columnMenuSortAsc: 'Trier par ASC',
    columnMenuSortDesc: 'Trier par DESC',
  },
  menu: {
    general: 'MENU',
    order: 'Gestion des Commandes',
    'historic Orders': 'Historique',
    catalog: 'Médicaments',
    patient: 'Patients',
    posology: 'Posologie',
    newpro: 'Production',
    Laboratorys: 'Laboratoires',
    Config: 'Configuration',
    management: 'ADMINISTRATION',
    user: 'Utilisateurs',
    profile: 'Mon profil',
    list: 'Gestion des Utilisateurs',
    myinstallation: 'Mon Installation',
    subscription: 'Ma Pharmacie',
    invoice: 'Facture',
    centers: 'Centres',
    centersmanage: 'Gestion des Centres',
    virtual: 'IA Mery',
    available: 'Commandes Disponibles',
    parameters: 'Paramètres',
    listrx: 'Ordonnances par Patient',
    reports: 'Ordonnance Électronique',
    traceability: 'Traçabilité',
    alerts: 'Alertes',
    history: 'Historique',
    medpatient: 'Médication par Patient',
    merge: 'Fusion de Posologie',
    take: 'Distribution de Médicaments',
    import: 'Importation',
    inicio: 'Accueil',
    upload: 'Importer Fichier',
    parametersimport: "Paramètres d'Importation",
    task: 'Tâches Programmées',
    electronic: 'Cartes Sanitaires',
    availableia: 'Disponibles',
    match: 'Fusion Posologie / Ordonnance',
    sellparameters: 'Paramètres de Vente',
    form0: 'Rapports',
    form1: 'Rapport de Production',
    form2: 'Médication par Patient',
    form3: 'Traçabilité',
    chat: 'Chat',
    calendar: 'Calendrier',
    support: 'Support Technique',
    assist: 'Assistance',
    softinstall: 'Logiciel installé',
  },
  dropdown: {
    Home: 'Accueil',
    Profile: 'Profil',
    Settings: 'Paramètres',
    logout: 'Se déconnecter',
  },
  alert: {
    success: 'Accès accordé',
    newuser: 'Nouvel Utilisateur',
    verified: 'Vérifié',
    yes: 'Oui',
    no: 'Non',
    edit: 'Modifier',
    deleteuser: "Supprimer l'Utilisateur",
    confirmation: 'Êtes-vous sûr de vouloir supprimer cet/cette Utilisateur(s) ?',
  },
  myuser: {
    general: 'Général',
    pass: 'Changer le Mot de Passe',
    allow: 'Autorisé',
    sice: 'Taille maximale de',
    save: 'Enregistrer les Modifications',
    about: 'Commentaire',
    old: 'Ancien Mot de Passe',
    new: 'Nouveau Mot de Passe',
    mustbe: 'Le mot de passe doit comporter au moins 6 caractères.',
    confirm: 'Confirmer le Nouveau Mot de Passe',
    banned: 'Désactivé',
    applyban: 'Appliquer la désactivation du compte.',
    savechanges: 'Enregistrer les Modifications',
    upload: 'Importer un Fichier',
    photo: 'Importer une Photo',
    update: 'Mettre à Jour la Photo',
  },
  register: {
    mesage: 'Rapide, simple et intuitif.',
    intro: 'Créez votre compte et commencez à profiter de nos solutions.',
    intro2: "C'est très simple!",
    name: 'Nom et Prénom',
    lastname: 'Nom de famille',
    namepharma: "Nom de l'Installation",
    create: "S'inscrire",
    already: 'Vous avez déjà un compte ? ',
    firstnamerec: 'Le prénom est obligatoire',
    lastnamerec: 'Le nom de famille est nécessaire',
    emailrec: "L'adresse e-mail est obligatoire",
    passrec: 'Le mot de passe est nécessaire',
  },
  centers: {
    list: 'Liste des Centres',
    title: 'Fiche du centre',
    nif: 'NIF',
    date: "Date d'enregistrement",
    production: 'Mode de Production',
    machinepro: 'Dispositif Sélectionné',
    print1: 'Imprimer les NON conditionnables.',
    print2: 'Imprimer par moments.',
    print3: 'Imprimer le Si nécessaire.',
    code: 'Par code de médicament',
    code2: "Par famille d'emballages",
    code3: 'Par familles biologiques',
    change: 'Voulez-vous changer de Centre ?',
    changeinstallation: "Voulez-vous changer d'Installation ?",
    sure: 'Êtes-vous sûr de vouloir changer de centre ?',
    acept: 'Accepter',
    addnew: 'Nouvelle Prescription Prédéfinie',
    namenewpred: 'Nom de la Prescription',
    syncdate: 'Synchroniser la date de fin de la recette électronique',
    syncseller: 'Mettre à jour la posologie selon la dernière vente de médicament',
    antibioticpackablealone: 'Chaque antibiotique nécessite un contenant séparé.',
  },
  listmedicine: {
    medication: 'Médication',
    search: 'Recherche',
    filter: 'Filtre Médicament',
    all: 'Tous',
    tipemedication: 'Médicaments',
    familya: "Famille d'Emballage",
    familyb: 'Famille Biologique',
    inactive: 'Inactif',
    active: 'Actif',
    ficha: 'Fiche du Médicament',
    brand: 'Marque',
    generic: 'Générique',
    needrecipe: 'Ordonnance nécessaire',
    code: 'Code',
    name: 'Nom',
    codefamily: 'Code Famille Biologique',
    namefamily: 'Nom Famille Biologique',
    units: 'Unités Boîte',
    atc: 'Code ATC',
    packable: 'Conditionnable',
    psicotropic: 'Psychotrope',
    narcotic: 'Narcotique',
    warning: 'Précautions',
    replaceable: 'Remplaçable',
    clinic: 'Emballage Clinique',
    use: 'Usage Hospitalier',
    comer: 'Commercialisé',
    tld: 'TLD',
    para: 'Parapharmacie',
    control: 'Spécial Contrôle Médical',
    drive: 'Effets Conduite',
    orphan: 'Orphelin',
    recipe: 'Ordonnance',
    bio: 'Bio Similaire',
    save: 'Enregistrer',
    cancel: 'Annuler',
  },
  listpatient: {
    title: 'Liste des Patients',
    search: 'Recherche',
    active: 'Actif',
    ficha: 'Fiche du Patient',
    name: 'Nom',
    dni: 'DNI/NIE',
    born: 'Date de Naissance',
    ss: 'Numéro de la Sécurité Sociale',
    phone: 'Téléphone',
    sex: 'Sexe',
    female: 'Féminin',
    male: 'Masculin',
    bed: 'Lit',
    region: 'Province',
    room: 'Chambre',
    address: 'Adresse',
    country: 'Pays',
    city: 'Ville',
    cp: 'Code Postal',
    mail: 'Adresse e-mail',
    save: 'Enregistrer',
    cancel: 'Annuler',
    sync: 'Synchronisation',
    journal: 'Historique de Médication',
    electronic: 'Ordonnance Électronique',
    group: 'Groupe',
    doctorname: 'Nom du médecin',
    excludeia: "Exclure les modifications apportées à l'ia"
  },
  production: {
    status: 'Statut',
    title: 'Nouvelle Production',
    code: 'Code',
    datecreate: 'Date de Création',
    dateproduction: 'Date de Production',
    lastprox: 'Prochaine/Dernière Dispensation',
    initialdose: 'Début/Dernière Dispensation',
    dateend: 'Date de Fin',
    cancel: 'Annuler',
    pendent: 'En attente',
    working: 'En cours',
    produced: 'Produite',
    deleted: 'Supprimée',
    repackaging: 'Production de Reconditionnement',
    pouch: 'N° de Doses',
    dose: 'Unités par Dose',
    printlabel: "Impression d'Étiquettes",
    labelsearch: 'Recherche par Nom',
    cretit_none: 'Crédits insuffisants',
    cretit_none1: "Vous n'avez pas assez de crédits pour compléter cette production. Vous pouvez vous abonner à un plan mensuel pour obtenir des crédits automatiquement ou, si vous avez acheté des consommables d'origine, entrer le code de série figurant sur la carte incluse dans votre commande.",
    cretit_none2: 'Veuillez recharger votre compte pour continuer.',
    cretit_none3: 'Crédits actuels',
    cretit_none4: 'Crédits nécessaires',
    cretit_none5: 'Crédits',
    num_production: "Numéro de Production",
    date_generate: "Génération de Production",
    dates_production: "Dates de Production",
    date_send: "Envoi au Dispositif"
  },
  dashboard: {
    title: 'Bienvenue de nouveau 👋',
    titlecomment: 'Avec notre IA avancée, nous offrons le service le plus sûr et fiable du marché.',
    discover: 'Découvrir plus',
    patients: 'Patients',
    medication: 'Médication Active',
    production: 'Productions',
    caducity: 'Médication proche de la date de péremption',
    data: 'Données de la Dernière Production : ',
    total: 'Total Dispensé :',
    packable: 'Conditionnables',
    unpackable: 'Non conditionnable',
    dispensed: 'Dispensé',
    activpatient: 'Patients Actifs',
    activposology: 'Traitements Actifs',
    totalcount: 'U. Dispensées Mois',
    type: 'Caractéristiques',
    if_needed: 'Si nécessaire',
    iaquality: 'IA Qualité',
    merge: 'Corrects',
    rxx: 'Sans Ordonnance',
    posology: 'Sans Posologie',
    reviwer: 'Revoir',
    dep: 'Dépôt',
    ranking: 'TOP Changements',
  },
  posology: {
    force_print: 'Imprimer uniquement',
    no_sync: 'Protégé',
    title: 'Posologie',
    search: 'Recherche',
    active: 'Actif',
    inactive: 'Médication Inactive',
    save: 'Enregistrer',
    cancel: 'Annuler',
    packable: 'Conditionnable',
    unpackable: 'Non conditionnable',
    start: 'Date de début',
    end: 'Date de Fin',
    dosis: 'Dose',
    period: 'Jours Période',
    cronic: 'Chronique',
    days: 'Jours',
    hour: 'Heure Période',
    starthour: 'Heure de Début',
    months: 'Mois',
    january: 'Janvier',
    february: 'Février',
    march: 'Mars',
    april: 'Avril',
    may: 'Mai',
    june: 'Juin',
    july: 'Juillet',
    august: 'Août',
    september: 'Septembre',
    october: 'Octobre',
    november: 'Novembre',
    december: 'Décembre',
    week: 'Hebdomadaire',
    ciclica: 'Cyclique Jours',
    ciclicb: 'Cyclique Heures',
    especilavar: 'Variable',
    month: 'Mensuel',
    aparte: 'Conditionnement à Part',
    optional: 'Si Nécessaire',
    irreplaceable: 'Non Remplaçable',
    moment: 'Moment',
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi',
    sunday: 'Dimanche',
    add: 'Ajouter Prise',
    name: 'Nom de la prise',
    selecttitle: 'Sélection du Médicament',
    select: 'Sélectionner',
    addtoma: 'Ajouter',
    cannot: 'Vous ne pouvez pas enregistrer une posologie vide.',
    confirmation: 'Confirmation',
    confirmationtext: 'Êtes-vous sûr de vouloir supprimer ce traitement ?',
    confirmationdelete: 'Supprimer',
    update: 'Modifications enregistrées avec succès.',
    error: 'Erreur lors de la suppression de la posologie.',
    erroradd: "Erreur lors de l'ajout de la posologie.",
    addsuccess: 'Ajouté avec succès.',
    deletesuccess: 'Supprimé avec succès.',
    errordate: "La Date de Début ne peut pas être antérieure à aujourd'hui.",
    exist: 'Prise déjà existante.',
    periodless: 'La période ne peut pas être inférieure à jours.',
    daysless: 'Les jours ne peuvent pas être inférieurs à 1.',
    hourless: 'Les heures ne peuvent pas être inférieures à 1.',
    hourerror: 'La période ne peut pas dépasser 24 heures.',
    dayerror: 'Le jour ne peut pas dépasser 31 ou être inférieur à 0.',
    delete: 'Supprimer la Posologie',
    note: 'Notes',
    interaction: 'Interactions médicamenteuses',
    deletedose: 'Voulez-vous supprimer le traitement sélectionné ?',
    confirmationotp: 'Générer des QR',
  },
  login: {
    mesage: 'Bonjour, bienvenue',
    introduction:
      "Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Il a été popularisé dans les années 1960 grâce à la vente de feuilles Letraset contenant des passages du Lorem Ipsum, et, plus récemment, par son inclusion dans des applications de mise en page de texte, comme Aldus PageMaker.",
    signin: 'Se connecter à IDOOSE',
    details: 'Veuillez entrer vos informations ci-dessous.',
    inicio: 'Connexion',
    agree: "En m'inscrivant, j'accepte les ",
    terms: "Conditions d'utilisation",
    privacy: 'Politique de confidentialité',
    and: ' et ',
    account: 'Nouvel Utilisateur ? ',
    started: 'Créer un compte',
    email: 'Adresse e-mail',
    password: 'Mot de passe',
    rememberme: 'Se souvenir de moi',
    forgotpassword: 'Mot de passe oublié ?',
    requiremail: "L'adresse e-mail est obligatoire",
    requirepass: 'Le mot de passe est obligatoire',
    requirement: "En m'inscrivant, j'accepte les ",
    requirement2: "Termes et Conditions d'Utilisation",
    requirement3: ' et la ',
    requirement4: 'Politique de Confidentialité',
  },

  virtual: {
    list: 'Liste des Ordres de Médicaments',
    new: 'Nouvelle Prescription',
    active: 'Active',
    expired: 'Expirée',
    expiredate: "Date d'Expiration",
    renewdate: 'Date de Renouvellement',
    raw: 'Bande/Chip/TSI/N° de Prescription',
    order: 'Code-Barres (uniquement CAT)',
    review: 'Révision',
    assignment: 'Attribution de Codes',
    sell: 'Vente',
    create: 'Créer un Ordre',
    title: 'Tâches Planifiées',
    number: "N° d'Ordre",
    status: 'Statut',
    date: 'Date de Début',
    patients: 'N° de Patients',
    titleparameters: 'Paramètres de Vente',
    confirm: 'Voulez-vous supprimer la prescription sélectionnée ?',
    deletesinc: 'Voulez-vous supprimer la synchronisation sélectionnée ?',
    productionform: 'Formulaire de Production',
    prepareinform: 'Préparer le Formulaire',
    export: 'Exporter vers Excel',
  },

  trazability: {
    ema: 'Agence européenne des médicaments (EMA)',
    text: "L'Agence européenne des médicaments (EMA) est une agence décentralisée de l'Union européenne (UE) chargée de l'évaluation scientifique, de la supervision et du suivi de la sécurité des médicaments dans l'UE. L'EMA est une organisation en réseau dont les activités impliquent des milliers d'experts de toute l'Europe. Ces experts mènent les travaux des comités scientifiques de l'EMA.",
    discover: "Découvrir l'EMA",
    detections: 'Alertes Détectées',
    eligible: 'Alertes Éligibles',
    lastupdate: 'Dernière Mise à Jour',
    totals: 'Médication Totale Vérifiée',
  },

  reportpatientproductionlist: {
    lng_title: 'RAPPORT DES PATIENTS PRODUITS',
    lng_titlelistcenter: 'LISTE DES PATIENTS',
    lng_phone: 'Téléphone',
    lng_mail: 'E-mail',
    lng_num_production: 'Numéro Production',
    lng_center: 'Centre',
    lng_date_int: 'Date de Début',
    lng_date_end: 'Date de Fin',
    lng_code: 'Code',
    lng_name: 'Nom',
    lng_send: 'Envoi',
    lng_reception: 'Réception',
    lng_date: 'Date',
    lng_no_medication: 'Sans Médiation',
    lng_reviwer: 'Revu par:',
    lng_produced: 'Produit par:',
  },
  reportmedicineproductionlist: {
    lng_title: 'RAPPORT DE MÉDICAMENTATION REGROUPÉE',
    lng_title2: 'RAPPORT DE RECHARGE',
    lng_title3: 'RAPPORT DE MÉDICAMENTATION PAR PATIENT',
    lng_phone: 'Téléphone',
    lng_mail: 'Courriel',
    lng_num_production: 'N° de Production',
    lng_center: 'Centre',
    lng_date_int: 'Date de Début',
    lng_date_end: 'Date de Fin',
    lng_code: 'Code',
    lng_name: 'Nom',
    lng_qty: 'Unités',
    lng_yes: 'Oui',
    lng_no: 'Non',
    lng_packable: 'Emballable',
    lng_date: 'Date',
    lng_dev: 'Trémie',
    lng_lot: 'Lot',
    lng_exp: 'Expiration',
    lng_qty2: 'Reste',
  },
  reportposologyproductionlist: {
    lng_title_not_packing: 'MÉDICAMENTATION NON EMBALLABLE',
    lng_title_packing: 'MÉDICAMENTATION EMBALLABLE',
    lng_mo2: 'LUNDI',
    lng_tu2: 'MARDI',
    lng_we2: 'MERCREDI',
    lng_th2: 'JEUDI',
    lng_fr2: 'VENDREDI',
    lng_sa2: 'SAMEDI',
    lng_su2: 'DIMANCHE',

    lng_title: 'PLAN PHARMACOLOGIQUE',
    lng_phone: 'Téléphone',
    lng_mail: 'Courriel',
    lng_date: 'Date',
    lng_num_patient: 'N° Patient',
    lng_name_patient: 'Nom',
    lng_dni: 'N° Identification',
    lng_ss: 'N° Sécurité Sociale',
    lng_code_medicine: 'Code',
    lng_name_medicine: 'Nom du Médicament',
    lng_posology: 'Posologie',
    lng_date_int: 'Date de Début',
    lng_date_end: 'Date de Fin',
    lng_cronic: 'Chronique',
    lng_mo: 'L',
    lng_tu: 'M',
    lng_we: 'M',
    lng_th: 'J',
    lng_fr: 'V',
    lng_sa: 'S',
    lng_su: 'D',
    lng_day: 'Jours',
    lng_month: 'Mois',
    lng_ja: 'JA',
    lng_fe: 'FE',
    lng_ma: 'MA',
    lng_ap: 'AV',
    lng_my: 'MA',
    lng_jn: 'JN',
    lng_jl: 'JL',
    lng_au: 'AU',
    lng_se: 'SE',
    lng_oc: 'OC',
    lng_no: 'NO',
    lng_de: 'DE',
    lng_month_all: 'Tous les Mois',
    lng_hour: 'Toutes les',
    lng_hour_x: 'Heures à partir de',
  },

  reportchangeslist: {
    lng_title_not_packing: 'MÉDICAMENTATION NON EMBLISTABLE',
    lng_title_packing: 'MÉDICAMENTATION EMBLISTABLE',
    lng_mo2: 'LUNDI',
    lng_tu2: 'MARDI',
    lng_we2: 'MERCREDI',
    lng_th2: 'JEUDI',
    lng_fr2: 'VENDREDI',
    lng_sa2: 'SAMEDI',
    lng_su2: 'DIMANCHE',

    lng_title: 'RAPPORT DE CHANGEMENTS',
    lng_phone: 'Téléphone',
    lng_mail: 'Courriel',
    lng_date: 'Date',
    lng_num_patient: 'N° Patient',
    lng_name_patient: 'Nom',
    lng_dni: 'N° Identification',
    lng_ss: 'N° Sécurité Sociale',
    lng_code_medicine: 'Code',
    lng_name_medicine: 'Nom du Médicament',
    lng_posology: 'Posologie',
    lng_date_int: 'Date de Début',
    lng_date_end: 'Date de Fin',
    lng_cronic: 'Chronique',
    lng_mo: 'L',
    lng_tu: 'M',
    lng_we: 'M',
    lng_th: 'J',
    lng_fr: 'V',
    lng_sa: 'S',
    lng_su: 'D',
    lng_day: 'Jours',
    lng_month: 'Mois',
    lng_ja: 'EN',
    lng_fe: 'FE',
    lng_ma: 'MA',
    lng_ap: 'AV',
    lng_my: 'MA',
    lng_jn: 'JN',
    lng_jl: 'JL',
    lng_au: 'AU',
    lng_se: 'SE',
    lng_oc: 'OC',
    lng_no: 'NO',
    lng_de: 'DE',
    lng_month_all: 'Tous Les Mois',
    lng_hour: 'Chaque',
    lng_hour_x: 'Heures Depuis',
    lng_id_center: 'Nº Centre',
    lng_state: 'Statut',
    lng_state_add: 'Ajouté',
    lng_state_edit: 'Modifié',
    lng_state_delete: 'Supprimé',
  },

  sync: {
    new: 'Ajouter une synchronisation',
  },
  reportpatientjournallist: {
    lng_title: 'RAPPORT DE DETTE PATIENTS',
    lng_phone: 'Téléphone',
    lng_mail: 'Courriel',
    lng_date: 'Date',
    lng_num_patient: 'N° Patient',
    lng_name_patient: 'Nom',
    lng_dni: 'N° Identification',
    lng_ss: 'N° Sécurité Sociale',
    lng_code_medicine: 'Code',
    lng_name_medicine: 'Nom du Médicament',
    lng_prod: 'Produit',
    lng_seller: 'Vendu',
    lng_aport: 'Apporté',
    lng_total: 'Total',
  },
  reportpatientconsent: {
    lng_anx_1:
      'je souhaite participer au programme de systèmes de dosage personnalisé de la pharmacie',
    lng_anx_2:
      'Je comprends que ledit programme consiste à conditionner les médicaments dans un système de dosage personnalisé.',
    lng_anx_3:
      "Je participe de manière totalement volontaire à ce programme et je pourrai cesser d'y participer lorsque je le jugerai opportun en révoquant simplement ce consentement en remplissant la section de révocation du même.",
    lng_anx_4:
      'Participer à ce projet implique, s’il est effectué à partir d’emballages commerciaux, de laisser les médicaments dans leurs emballages d’origine à la pharmacie où il est de la responsabilité du pharmacien de les conserver et de les préserver correctement.',
    lng_anx_5: "Je m'engage à :",
    lng_anx_51: 'Communiquer au pharmacien toute variation de ma médication.',
    lng_anx_52: 'Respecter les conditions de conservation et de sécurité du blister.',
    lng_anx_53:
      "Rendre les dispositifs vides des semaines précédentes pour vérification de la conformité et d'éventuelles erreurs d'utilisation.",
    lng_anx_6: "Le pharmacien s'engage à :",
    lng_anx_61: "Me communiquer tout problème lié au médicament qu'il pourrait détecter.",
    lng_anx_62:
      "Fournir les informations nécessaires à l'utilisation correcte des dispositifs de dosage personnalisé et des médicaments eux-mêmes.",
    lng_anx_7:
      "J'autorise le pharmacien au traitement de mes données personnelles nécessaires à la participation au programme*.",
    lng_consent: 'CONSENTEMENT ÉCLAIRÉ',
    lng_date: 'Date',
    lng_dni: 'N° Identification',
    lng_don_d: 'M./Mme',
    lng_lopd_1:
      "*Les données personnelles fournies pour l'inclusion dans le programme de systèmes de dosage personnalisé seront incluses dans un fichier appelé PDA, dûment enregistré à l'Agence espagnole de protection des données, dont le responsable est la pharmacie",
    lng_lopd_2:
      " disposent de toutes les mesures de sécurité nécessaires conformément à la loi sur la protection des données, seront traitées uniquement dans le but de gérer les dispositifs de dosage personnalisé du patient, les données pourront être cédées ou communiquées à des professionnels de santé, des membres de la famille et / ou des aidants en relation avec le patient afin de corroborer le traitement et / ou d'améliorer l'adhésion à celui-ci, elles ne seront en aucun cas utilisées à des fins de publicité ou de promotion commerciale, elles sont soumises au secret professionnel du pharmacien. Les personnes dont les données sont enregistrées dans ces fichiers ont le droit d'y accéder, de les rectifier ou de les annuler, si elles le jugent opportun en s'adressant à cette même pharmacie dans les termes légalement prévus.",
    lng_mail: 'Courriel',
    lng_name_d: 'Patient/Représentant Légal',
    lng_name_patient: 'Nom',
    lng_name_ph_d: 'Pharmacien',
    lng_name_ph_sin_d: 'Signature du Pharmacien',
    lng_name_sin_d: 'Signature du Patient/Représentant Légal',
    lng_num_patient: 'N° Patient',
    lng_phone: 'Téléphone',
    lng_ss: 'Numéro Sécurité Sociale',
    lng_title: 'CONSENTEMENT ÉCLAIRÉ DU PATIENT',
  },
  Bob: {
    ordernumber: 'N° Commande',
    status: 'État',
    process: 'Progression',
    Startorder: 'Début de la Commande',
    createdate: 'Date de Création',
    selling: 'En cours',
    reviewing: 'Révision',
    wait: 'En attente',
    sevem: 'SEVEM',
    patient: 'Patient',
    drug: 'Médicament',
    recipe: 'Ordonnance Électronique',
    initialdate: 'Début du traitement',
    expiredate: 'Expiration',
    posology: 'Posologie',
    scan: 'Scanner le Code',
    dep: 'Dépôt',
    without1: 'Pas de Posologie',
    without2: "Pas d'Ordonnance",
    reviwer: 'Revoir',
    correct: 'Correct',
    lot: 'Lot',
    neworder: 'Créer une Commande',
    deleteorder: 'Souhaitez-vous supprimer la commande de travail sélectionnée?',
    numberorder: 'N° de Commande:',
    history: 'Historique de la Traçabilité',
    scancodes: 'Attribution des Codes',
    forceall: 'Forcer tout à CN',
    last: 'Dernière Mise à Jour',
    deleteparameter: 'Souhaitez-vous supprimer le paramètre sélectionné?',
    sellformtitle: 'RAPPORT DE VENTE',
  },
  official: {
    error: 'Document non disponible.',
  },
  display: {
    prospectus: 'Prospectus',
    technical: 'Fiche Technique',
    unpackable: 'Non conditionnable',
    titletake: 'Date et heure de prise',
    titleproduction: "Date d'emballage",
    titleexprepacking: "Expiration du périphérique SPD",
  },
  notfound: {
    sorry: 'Prise non trouvée.',
    sorry2: "Désolé, nous n'avons pas pu trouver la page que vous recherchez.",
    home: 'Accueil',
  },
  editproduction: {
    edit: 'Édition de la Production',
    confirm: 'Êtes-vous sûr de vouloir modifier la production?',
    home: 'Accueil',
    predose: 'Posologies Prédéfinies',
    addnewpre: 'Nouvelle Posologie Prédéfinie',
    nametome: 'Nom de la Posologie',
    hourtome: 'Heure de la Posologie',
    confirmationtext: 'Êtes-vous sûr de vouloir supprimer cette posologie prédéfinie?',
  },

  blisterpro: {
    process: 'PROCESSUS',
    sorry2: "Désolé, nous n'avons pas pu trouver la page que vous recherchez.",
    home: 'Accueil',
  },

  various: {
    exhausted: 'Épuisé',
    datapatient: 'Données du Patient',
    center: 'Données du Centre',
    expiredon: "Date d'expiration:",
    synclist: 'Éléments Synchronisés',
    debt: 'Dette',
    drug: 'Médicament',
    produced: 'Produit',
    sell: 'Vendu',
    provided: 'Fourni',
    total: 'Total',
    date: 'Date',
    Details: 'Détails',
    event: 'Événement',
    units: 'Unités',
    production: 'Production',
    sell2: 'Vendu',
    add: 'Ajouter',
    historic: 'Historique',
    historic2: 'Historique de Traçabilité',
    ns: 'Numéro de Série',
    available: 'Disponible',
    next: 'Prochainement',
    recomended: 'Recommandé',
    notrecomended: 'Non recommandé',
    register: 'Registre des Livraisons',
    registerdate: 'Date de Livraison',
    from: 'De',
    to: 'À',
    datapicker: 'Dates du Rapport ',
    download: 'Télécharger',
    tpills: 'Total U.',
    tneed: 'Restant',
    rbox: 'Boîtes',
    result: 'résultats',
    predetermlist: 'Liste des Posologies',
    deletepredet: 'Voulez-vous supprimer la posologie sélectionnée?',
    no_verifi: 'Non Vérifié',
    verifi: 'Vérifié',
    installation: 'Mon Installation',
    exportamts: 'Exporter ATMS',
    confirmdose: 'Voulez-vous enregistrer la posologie?',
    whynot: "Motif d'annulation",
  },
  tooltip: {
    refill: 'Rapport de Recharge',
    label: 'Imprimer des Étiquettes',
    edit: 'Modifier la Production',
    grouped: 'Rapport de Médicament Groupé',
    report1: 'Rapport Patient-Médicament',
    delivery: 'Bon de Livraison',
    archived: 'Archivés',
    delete: 'Supprimer la Production',
    generateproduction: 'Générer la Production',
    repackaging: 'Production de Reconditionnement',
    start: 'Démarrer la Production',
    list: 'Liste des Patients',
    add: 'Nouveau Patient',
    listposology: 'Posologie par Patient',
    official: 'Rapport de Posologie Officiel',
    activate: 'Activer/Désactiver WhatsApp',
    consent: 'Consentement Éclairé',
    alllabel: 'Tous',
    changes: 'Rapport des Changements',
    sevem: 'Finaliser la Vente',
    new: 'Nouvelle Tâche',
    copy: 'Copier',
    insert: 'Insérer',
    newparameter: 'Nouveau Paramètre',
    center: 'Nouveau Centre',
  },
  import: {
    e_title: 'Attention ! Le fichier importé pourrait être incorrect',
    e_dialog: 'Il semble que nous ayons importé un fichier de la résidence :',
    e_dialog1: 'au lieu de la résidence :',
    e_dialog2: 'Veuillez vérifier et confirmer la situation.',
    i_title: 'Importer un Fichier',
    i_type: 'Format du Fichier',
    i_drop: 'Sélectionner un Fichier',
    i_dropb:
      'Vous pouvez glisser-déposer le fichier ici ou explorer votre ordinateur pour le sélectionner.',
    0: 'Resiplus (*.txt)',
    1: 'Resiplus Multi (*.txt)',
    2: 'Resiplus (*.xls)',
    3: 'Resiplus Multi (*.xls)',
    4: 'Sanitas (*.pdf)',
    5: 'DomusVi (*.txt)',
    6: 'DomusVi (*.pdf)',
    7: 'Pill Device (*.json)',
    8: 'Cretem Atms (*.xml)',
    9: 'Cretem Atms Multi (*.zip)',
    10: 'Jvm Oncube (*.txt)',
    11: 'Jvm Oncube Multi (*.zip)',
    12: 'Ecap (*.txt)',
  },
  module_: {
    title_: 'Module non disponible avec la licence souscrite',
    subtitle_: 'Contactez le service commercial pour demander une démonstration !',
    return_: "Retour à l'Application",
  },
  permit: {
    permit: 'Gérer les Autorisations',
  },
  permissionsgroup: {
    1: 'Catalogue de Médicaments',
    2: 'Gestion des Patients',
    3: 'Posologie du Patient',
    4: 'Module de Production',
    5: 'Importations',
    6: 'IA Mery',
    7: 'Traçabilité',
    8: 'Administration des Utilisateurs',
    9: 'Administration des Centres',
    10: 'Gérer les Données de ma Pharmacie',
    11: 'Livraison de Médicaments',
    12: 'Rapports',
  },
  permissions: {
    1: 'Activer le Catalogue de Médicaments',
    2: 'Modifier les Médicaments',
    3: 'Visualiser les données du Patient',
    4: 'Modifier les données du Patient',
    5: 'Visualiser la Recette Électronique',
    6: 'Ajouter ou Supprimer une Recette Électronique',
    7: 'Visualiser la Synchronisation',
    8: 'Ajouter ou Supprimer des Synchronisations',
    9: 'Visualiser les consommations du patient',
    10: 'Modifier les consommations du patient',
    11: 'Activer la Posologie',
    12: 'Modifier la Posologie',
    13: 'Activer le Module de Production',
    14: 'Créer une Production',
    15: 'Envoyer la Production / Section Blister Manuel',
    16: 'Modifier la Production',
    17: 'Supprimer la Production',
    18: "Activer le Module d'Importation",
    19: 'Importer des fichiers dans le système',
    20: "Activer l'IA Mery",
    21: 'Visualiser les Tâches Planifiées',
    22: 'Ajouter des Tâches',
    23: 'Visualiser les Cartes Sanitaires',
    24: 'Insérer des Cartes',
    25: 'Visualiser les Disponibles',
    26: 'Visualiser la Correspondance Posologie / Recette',
    27: 'Activer les Paramètres de Vente',
    28: 'Créer un Paramètre de Vente',
    29: 'Activer la section Patients.',
    30: 'Activer le module de Contrôle des prises.',
    31: 'Livraison de médicaments aux Patients.',
    32: 'Activer la Traçabilité',
    33: "Activer l'Administration des Utilisateurs",
    34: 'Modifier les Utilisateurs',
    35: 'Activer la Gestion des Centres',
    36: 'Modifier les Centres',
    37: 'Activer Ma Installation',
    38: 'Modifier Ma Installation',
    39: 'Activer les Rapports',
    40: 'Rapport de Production',
  },
  control_takes: {
    1: 'Non requis',
    2: 'Médicament manquant',
    3: 'Médicament endommagé',
    4: 'Le patient refuse',
    5: 'Autres',
    cause_not_take: 'Cause',
    6: 'Heure erronée',
    7: 'Changement de médication',
    8: 'Ajouter',
    9: 'Retirer',
    10: 'Enregistrer la prise',
  },
  home: {
    home: 'Accueil',
    title: 'Simplifiez votre Médication',
    titletex:
      "Bienvenue à l'avant-garde de la gestion des médicaments. Notre Logiciel de Systèmes de Dosage Personnalisés (PDA) redéfinit votre relation avec votre santé. Oubliez les complications dans la préparation de vos médicaments. Avec notre solution intuitive et personnalisée, l'adhérence au traitement devient une expérience simple et efficace.",
    available: 'Disponible sur',
    benefits: 'Avantages du PDA',
    titlebenefit1: 'Alerte de Prise',
    benefit1:
      'Optimisez votre routine avec notre application qui vous fournit des rappels précis pour chaque prise. Oubliez les inquiétudes concernant les oublis et assurez votre bien-être. De plus, consultez les incompatibilités et interactions entre vos médicaments pour une gestion complète et sécurisée de votre santé.',
    titlebenefit2: 'Service Personnalisé',
    benefit2:
      'Expérimentez un nouveau standard de confort dans votre prise en charge de la santé. Avec IDOOSE, vous recevrez votre médication en doses méticuleusement préparées par des pharmaciens professionnels hautement qualifiés. Nous nous engageons à vous fournir une expérience sans tracas, apportant qualité et simplification à chaque prise.',
    titlebenefit3: 'Nous préparons votre Médication',
    benefit3:
      "Adieu à la complication de préparer des boîtes. Avec notre système de doses unitaires corrélatives, la prise de votre médication est aussi simple que d'ouvrir et c'est prêt. Chaque dose contient des informations détaillées, y compris la notice, pour vous offrir confort et clarté à chaque étape de votre traitement.",
    appidoose: "Avec IDOOSE, vous n'oublierez jamais votre Médication.",
    appidoosetext:
      "Téléchargez l'application, inscrivez-vous et nous vous mettrons en contact avec la pharmacie la plus proche de chez vous.",
    listposology: 'Posologie par Patient',
    design: 'Conçu pour vous',
    partner: 'Vous voulez collaborer avec nous?',
    partner2: 'DEVENEZ PARTENAIRE',
    partnertext:
      "Nous nous démarquons comme les leaders incontestés dans l'application de l'intelligence artificielle dans le Système de Dosage Personnalisé (PDA). En nous choisissant, vous garantirez l'efficacité de vos productions et éliminerez complètement tout risque d'erreur dans le processus de production. Faites confiance à notre expertise pour amener vos opérations au plus haut niveau de précision et de sécurité.",
    prices: 'PRIX ET PLANS',
    licence: 'Licence',
    patient: 'Gestion des Patients',
    production: 'Module de Production',
    import: 'Importation de Fichier Robot',
    blister: 'Production de Blister Manuel',
    update: 'Mises à Jour',
    support: 'Support Technique',
    patient30: "Jusqu'à 50 Patients",
    patient31: 'Patients Illimités',
    rx: 'Connexion à la Recette Électronique',
    inform: 'Rapport de Posologie CCAA',
    warning: 'Changements dans la Recette Électronique',
    next: 'Prochaines Dispensations',
    mach: 'Correspondance de Posologie avec Recette Électronique',
    sell: 'Module de Vente',
    question: 'Vous avez encore des questions?',
    question2:
      'Pour vous offrir les conseils les plus adaptés, veuillez nous indiquer si vous représentez une pharmacie, un hôpital ou un laboratoire. De cette manière, nous pourrons vous fournir des informations personnalisées en fonction des particularités de votre secteur.',
    contact: 'Contactez-nous',
    reserved: 'Tous droits réservés, réalisé par',
    forgotpasswordintro:
      "Entrez l'adresse e-mail associée à votre compte et nous vous enverrons un lien pour réinitialiser votre mot de passe.",
    request: 'Envoyer la demande',
    returnto: 'Retour à la page de connexion',
    choose: "Avez-vous besoin d'un robot PDA ?",
    choose1: 'Découvrez nos robots PDA',
    choose2:
      "Nous disposons d'une large gamme de robots PDA pour répondre à vos besoins spécifiques. Nous proposons des modèles conçus en fonction du volume de production, du niveau d'automatisation et du format requis, qu'il s'agisse de blisters ou de sachets. En outre, nous disposons de robots équipés de la technologie Oculus, qui intègre la vérification et le contrôle au sein même de l'équipement.",
    siglas: 'PDA',
    robottitle: 'Explorez notre gamme de robots',
    robotdescription:
      "Si vous cherchez à optimiser l'automatisation dans la préparation des PDA, nos robots sont votre allié idéal. Nous disposons d'une large gamme de robots PDA, allant des plus hautement automatisés à ceux nécessitant moins d'automatisation, pour nous adapter à vos besoins spécifiques. Grâce à notre technologie avancée et aux trémies universelles FSP que nous proposons, vous pourrez réduire significativement la dépendance à la calibration. De plus, nos robots sont équipés du système OCULUS intégré, qui vous fournit des preuves photographiques détaillées de chaque production, ainsi que des analyses approfondies de toutes les doses.",
    catalog: 'Télécharger le catalogue',
    diff: 'Principaux avantages de nos robots PDA',
    diff1: 'FSP Universels',
    diff1a:
      "Avec les FSP Universels, vous pourrez utiliser la médication dont vous avez besoin au moment précis sans complications ni calibrations. Notre technologie innovante garantit l'utilisation de tout type de médicament que vous souhaitez, peu importe sa forme ou sa taille. Vous bénéficierez d'une plus grande liberté dans votre production.",
    diff2: 'Rotation élevée',
    diff2a:
      "Pour assurer la disponibilité constante des molécules les plus utilisées, nous procéderons à une calibration soigneuse. Nos experts mèneront une étude approfondie pour valider cette sélection, garantissant ainsi une calibration précise et une automatisation efficace. Cette approche nous permet d'atteindre une automatisation optimale.",
    diff3: 'Traçabilité TOTALE',
    diff3a:
      'Avec nos robots, la gestion de votre médication est plus simple que jamais. Il vous suffit de scanner le code Sevem pour enregistrer le lot et la date de péremption de chaque dose produite. Ce processus rapide et efficace vous offre la possibilité de suivre de manière détaillée votre médication, garantissant un contrôle précis et sécurisé de celle-ci.',
    robotnews: 'Système OCULUS',
    robotnews2:
      "Chez Robotik, nous avons développé la technologie OCULUS, qui se charge de vérifier et d'analyser toute la production par vision artificielle. Avec OCULUS, oubliez le temps consacré à vérifier manuellement la production ; notre technologie le fait pour vous. De plus, elle laisse toujours une preuve photographique de tout ce qui a été produit avec votre robot Robotik. En résumé, OCULUS vous offre une tranquillité totale.",
    discover: 'Découvrez les caractéristiques de chaque robot PDA',
    discover2: 'SÉLECTIONNER',
    tablerobots: 'Le robot PDA idéal pour toutes les nécessités',
    footer:
      "La meilleure solution PDA monodose sur le marché. Nous sommes experts en implémentation de l'intelligence artificielle dans les processus de gestion.",
    corfirmform: 'Formule reçue !',
    corfirmform2:
      'Notre équipe examinera les informations fournies et vous contactera bientôt. Merci pour votre intérêt !',
    tel: 'Téléphone',
  },
  myprofile: {
    edit: 'Éditer',
    user: 'Utilisateur',
    name: 'Nom et Prénom',
    mail: 'E-mail',
    number: 'Numéro de Téléphone',
    country: 'Pays',
    state: 'État/Province',
    city: 'Ville',
    address: 'Adresse',
    zip: 'Code Postal',
    company: 'Entreprise',
    role: 'Rôle',
    save: 'Enregistrer les Modifications',
    delete: "Supprimer l'Utilisateur",
    verify: 'E-mail Vérifié',
    verifyinfo:
      "En désactivant cette option, l'utilisateur recevra automatiquement un e-mail de vérification.",
    banned: 'Désactivé',
    apply: 'Désactiver le Compte',
    new: 'Créer un Utilisateur',
    permit: 'Autorisés *.jpeg, *.jpg, *.png, *.gif',
    max: 'taille max. de',
    management: 'Gestion',
    search: 'Rechercher...',
    list: 'Liste des Utilisateurs',
    changecenter: 'Changement de Centre',
    resetpass: 'Réinitialiser le Mot de Passe',
    confirmdelete: "Voulez-vous supprimer l'utilisateur sélectionné?",
    dense: 'Condensé',
    confirmreset: 'E-mail envoyé avec succès',
    quick: 'Modifier les Autorisations',
    otp: 'Générer un OTP',
    otptitle: 'Vérification en deux étapes',
    otpinfo: 'Voulez-vous générer un QR pour une vérification en deux étapes?',
    otpabout: `Scannez le code QR avec l'authentificateur Google pour générer un code temporaire et évolutif.`,
  },
  pages: {
    pages: 'Pages',
    about: 'À Propos',
    contact: 'Contacter IDOOSE',
    inicio: 'Accueil',
    info: 'Information',
    community: 'Communauté',
    post: 'Articles',
    robot: 'Robot PDA',
  },
  about: {
    who: 'Qui sommes-nous?',
    about: 'À Propos',
    contact: 'Contacter IDOOSE',
    inicio: 'Accueil',
    info: 'Information',
    community: 'Communauté',
    post: 'Articles',
    whotitle: 'Qui',
    are: 'sommes-nous?',
    mision: "Notre mission est d'améliorer la santé et le bien-être de nos patients",
    mision2:
      'grâce à la préparation précise de médicaments en unités de dosage unique, garantissant',
    mision3: 'une administration sûre et efficace de manière personnalisée.',
    whatis: "Qu'est-ce que IDOOSE?",
    text: "Nous sommes plus qu'un programme de gestion de médicaments, nous cherchons à stimuler un changement positif dans la santé des personnes. Avec le programme de gestion le plus avancé et accessible, nous nous efforçons de fournir des outils qui non seulement simplifient l'administration de médicaments, mais aussi encouragent la connexion communautaire et le bien-être général. Grâce à notre IA, nous offrons le service de préparation de médicaments le plus sûr et le plus fiable du marché. Chez IDOOSE, nous offrons un chemin vers une vie plus saine, plus simple et plus sûre.",
 legaltitle: "MENTIONS LÉGALES",
  legalare: "CONDITIONS GÉNÉRALES D’UTILISATION",
  legaltitle1: "I. INFORMATIONS GÉNÉRALES",
  legaltitle2: "COORDONNÉES",
  legaltitle3: "II. CONDITIONS D’UTILISATION",
  legaltitle4: "L’Utilisateur",
  legaltitle5: "III. ACCÈS ET NAVIGATION SUR LE SITE WEB : EXCLUSION DE GARANTIES ET DE RESPONSABILITÉ",
  legaltitle6: "IV. POLITIQUE DE LIENS HYPERTEXTES",
  legaltitle7: "V. PROPRIÉTÉ INTELLECTUELLE ET INDUSTRIELLE",
  legaltitle8: "VI. ACTIONS EN JUSTICE, LÉGISLATION APPLICABLE ET JURIDICTION",
  legaldata: "Nom de l’entreprise",
  legaldata1: "Numéro d’identification fiscale (NIF)",
  legaldata2: "Adresse",
  legaldata3: "Numéro de téléphone",
  legaldata4: "Adresse e-mail",
  legalsubtitile: "Conformément à la loi 34/2002, les utilisateurs sont informés de la propriété du site www.idoose.com",
  legaltext: "Idoose Robotik SL est une société située à Lorquí, dans la province de Murcie. Son siège social est établi à Calle Castillo De Moratalla, 45, 30564 Lorquí (Murcie), Espagne. Le code postal est 30564. Idoose Robotik SL est enregistrée au Registre du commerce de Murcie. Son activité est classée comme Programmation, Conseil et autres activités informatiques selon la CNAE. Plus précisément, il s’agit d’Activités de programmation informatique, code 6201. Son numéro CIF est B56740137 et elle est constituée en Société à Responsabilité Limitée.",
  legaltext1: "Conformément à l’obligation d’information prévue par la loi 34/2002 sur les Services de la Société de l’Information et du Commerce Électronique (LSSI-CE) du 11 juillet, les informations générales suivantes sont fournies concernant ce site web :",
  legaltext2: "La propriété de ce site web, www.idoose.com (ci-après le Site Web), appartient à : IDOOSE ROBOTIK SL, avec le NIF : B56740137",
  legaltext3: "Les présentes Conditions Générales d’Utilisation (ci-après, les Conditions) ont pour objet de réglementer l’accès et l’utilisation du Site Web. Aux fins des présentes Conditions, le Site Web désigne l’apparence extérieure des interfaces d’écran, en version statique comme dynamique (arborescence de navigation), ainsi que tous les éléments intégrés dans ces interfaces ou dans ladite arborescence (ci-après, Contenus), et les services ou ressources en ligne qui peuvent être proposés aux Utilisateurs (ci-après, Services).",
  legaltext4: "IDOOSE se réserve le droit de modifier, à tout moment et sans préavis, la présentation et la configuration du Site Web ainsi que les Contenus et Services qu’il peut inclure. L’Utilisateur reconnaît et accepte qu’à tout moment, IDOOSE puisse interrompre, désactiver et/ou supprimer tout ou partie de ces éléments, ou l’accès à ceux-ci.",
  legaltext5: "Hormis le coût de la connexion par le réseau de télécommunications fourni par le fournisseur d’accès contracté par l’Utilisateur, certains Contenus ou Services proposés par IDOOSE ou, le cas échéant, par des tiers via le Site Web, peuvent nécessiter une souscription ou un paiement. Dans ce cas, cela sera clairement indiqué et les Conditions Générales ou Particulières correspondantes seront mises à disposition de l’Utilisateur.",
  legaltext6: "L’utilisation de certains Contenus ou Services du Site Web peut être soumise à l’enregistrement ou à l’abonnement préalable de l’Utilisateur.",
  legaltext7: "L’accès, la navigation et l’utilisation du Site Web, ainsi que les espaces d’interaction (comme les commentaires ou les blogs) impliquent que l’on acquiert le statut d’Utilisateur. Ce statut implique l’acceptation pleine et entière des présentes Conditions, dès le début de la navigation sur le Site, ainsi que de leurs modifications ultérieures, sans préjudice de l’application de la législation obligatoire en vigueur. Il est donc recommandé de les lire à chaque visite.",
  legaltext8: "Le Site Web d’IDOOSE offre une grande variété d’informations, de services et de données. L’Utilisateur est responsable de l’utilisation correcte du Site Web. Cette responsabilité inclut :",
  legaltext9: "• L’utilisation des informations, Contenus et/ou Services fournis par IDOOSE conformément à la législation, aux présentes Conditions, à la morale et à l’ordre public, et sans porter atteinte aux droits de tiers ou au bon fonctionnement du Site Web.",
  legaltext10: "• L’exactitude et la légalité des informations fournies par l’Utilisateur dans les formulaires d’inscription mis à disposition par IDOOSE pour accéder à certains Contenus ou Services. L’Utilisateur s’engage à informer immédiatement IDOOSE de tout usage frauduleux de ses données (vol, perte, accès non autorisé à ses identifiants, etc.), afin de procéder à leur annulation.",
  legaltext11: "IDOOSE se réserve le droit de supprimer tout commentaire ou contribution qui enfreint la législation, ne respecte pas la dignité humaine, est discriminatoire, xénophobe, raciste, pornographique, spam, porte atteinte à la jeunesse ou à l’enfance, à l’ordre public ou à la sécurité, ou qui, à son avis, ne convient pas à la publication.",
  legaltext12: "Dans tous les cas, IDOOSE ne saurait être tenue responsable des opinions exprimées par les Utilisateurs via les commentaires ou tout autre outil de participation disponible.",
  legaltext13: "Le simple accès au Site Web ne constitue pas une relation commerciale entre IDOOSE et l’Utilisateur.",
  legaltext14: "L’Utilisateur déclare être majeur et avoir la capacité juridique pour accepter les présentes Conditions. Par conséquent, ce Site Web n’est pas destiné aux mineurs. IDOOSE décline toute responsabilité en cas de non-respect de cette exigence.",
  legaltext15: "IDOOSE ne garantit pas la continuité, la disponibilité ou l’utilité du Site Web, de ses Contenus ou de ses Services. Elle s’efforcera d’assurer le bon fonctionnement du Site, mais ne garantit pas un accès ininterrompu ou exempt d’erreurs.",
  legaltext16: "De même, elle ne garantit pas que les contenus ou logiciels accessibles via le Site soient exempts d’erreurs ou n’endommagent pas le système informatique de l’Utilisateur. En aucun cas, IDOOSE ne pourra être tenue responsable des pertes, dommages ou préjudices résultant de l’accès, la navigation et l’utilisation du Site, y compris les dommages causés aux systèmes informatiques ou par l’introduction de virus.",
  legaltext17: "IDOOSE n’est pas non plus responsable des dommages pouvant résulter d’une mauvaise utilisation du Site Web. En particulier, elle décline toute responsabilité en cas de coupure, interruption, défaut ou panne des télécommunications.",
  legaltext18: "Tout Utilisateur ou tiers qui crée un lien hypertexte depuis une autre page vers le Site Web d’IDOOSE doit savoir que :",
  legaltext19: "La reproduction —totale ou partielle— de tout Contenu et/ou Service du Site Web est interdite sans autorisation expresse d’IDOOSE.",
  legaltext20: "Aucune déclaration fausse, inexacte ou incorrecte sur le Site Web d’IDOOSE ou ses Contenus et/ou Services n’est autorisée.",
  legaltext21: "Sauf autorisation expresse, le site où le lien est établi ne doit contenir aucun élément protégé par des droits de propriété intellectuelle appartenant au Site Web d’IDOOSE.",
  legaltext22: "La création du lien hypertexte n’implique pas l’existence d’une relation entre IDOOSE et le propriétaire du site d’origine, ni l’approbation par IDOOSE des contenus, services ou activités proposés sur ce site, et inversement.",
  legaltext23: "IDOOSE, en tant que propriétaire ou cessionnaire, détient tous les droits de propriété intellectuelle et industrielle du Site Web, ainsi que sur les éléments qu’il contient (à titre d’exemple non exhaustif : images, sons, vidéos, logiciels, textes, marques, logos, combinaisons de couleurs, structure, design, etc.). Ces éléments sont protégés par la législation espagnole et européenne sur la propriété intellectuelle, ainsi que par les traités internationaux ratifiés par l’Espagne.",
  legaltext24: "Tous droits réservés. Conformément à la Loi sur la propriété intellectuelle, toute reproduction, distribution ou communication publique, y compris la mise à disposition, de tout ou partie des contenus de ce site à des fins commerciales, sur tout support et par tout moyen technique, est formellement interdite sans l’autorisation d’IDOOSE.",
  legaltext25: "L’Utilisateur s’engage à respecter les droits de propriété intellectuelle et industrielle d’IDOOSE. Il est autorisé à visualiser, imprimer, copier et stocker les éléments du Site, à condition que cela soit exclusivement pour son usage personnel et privé. L’Utilisateur ne peut supprimer, altérer ou manipuler tout système de protection ou de sécurité installé sur le Site.",
  legaltext26: "Si l’Utilisateur ou un tiers considère qu’un Contenu du Site Web constitue une violation de droits de propriété intellectuelle, il doit en informer immédiatement IDOOSE via les coordonnées fournies dans la section INFORMATIONS GÉNÉRALES des présentes Mentions Légales.",
  legaltext27: "IDOOSE se réserve le droit d’engager toutes les actions civiles ou pénales nécessaires en cas d’utilisation abusive du Site Web ou de non-respect des présentes Conditions.",
  legaltext28: "La relation entre l’Utilisateur et IDOOSE est régie par la législation en vigueur en Espagne. En cas de litige lié à l’interprétation et/ou à l’application de ces Conditions, les parties se soumettront à la juridiction des tribunaux compétents conformément à la loi."

  },
  contact: {
    where: 'Où',
    find: 'sommes-nous?',
    contact: "N'hésitez pas à nous contacter. Nous serons ravis de vous aider.",
    name: 'Nom',
    email: 'E-mail',
    subjet: 'Sujet',
    message: 'Écrivez votre message ici.',
    submit: 'Envoyer',
  },
  rulesimport: {
    list: "Paramètres d'Importation",
    form: 'Rapport de Vente',
    step1: 'Sélectionner un Fichier',
    step2: 'Examen des Conflits',
    step3: 'Téléversement du Fichier',
    importcorrect: 'Correct',
    sugest: 'Recommandation',
    new: 'Nouveau',
    noconflicts: 'Fichier validé avec succès, aucun conflit détecté.',
    sureimport:
      "La base de données a été mise à jour, appuyez sur Terminer pour finaliser l'importation.",
    importcomplete: "L'importation a été complétée avec succès.",
  },
  calendar: {
    title: 'Calendrier',
    event: 'Nouvel Événement',
    month: 'Mois',
    week: 'Semaine',
    day: 'Jour',
    agenda: 'Agenda',
    today: "Aujourd'hui",
    new: 'Ajouter un Événement',
    new2: 'Titre',
    description: 'Description',
    allday: 'Toute la Journée',
    start: 'Date de Début',
    end: 'Date de Fin',
  },
  tablerobots: {
    tolvas: 'Nombre de trémies',
    univers: 'FSP Universelles',
    width: 'Largeur (cm)',
    height: 'Hauteur (cm)',
    depth: 'Profondeur (cm)',
  },
  yup: {
    yup_min: 'Trop court !',
    yup_max: 'Trop long !',
    yup_required: 'Champ obligatoire !',
    yup_email: "L'e-mail doit être une adresse e-mail valide !",
    yup_phone: 'Le numéro de téléphone ne doit contenir que des chiffres !',
  },
  cookie_consent: {
    acept: 'Accepter',
    reject: 'Déclin',
    message:
      'Notre site Web utilise des cookies pour analyser le trafic et améliorer nos services.',
  },
  form_geometric: {
    1: 'Allongé',
    2: 'Cloque',
    3: 'Sonnerie',
    4: 'Arche',
    5: 'Balle',
    6: 'Biconvexe',
    7: 'Capsule',
    8: 'Cartouche',
    9: 'Cylindrique',
    10: 'Concave',
    11: 'Carré',
    12: 'Elliptique',
    13: 'Sphérique',
    14: 'Gaz',
    15: 'Inhalateur',
    16: 'Larme',
    17: 'Liquide',
    18: 'Pomme',
    19: 'Oblongue',
    20: 'Octogonal',
    21: 'Ovale',
    22: 'Poussière',
    23: 'Rectangulaire',
    24: 'Rond',
    25: 'losange',
    26: 'À propos',
    27: 'Triangulaire',
    28: 'Flacon',
  },
  form_size: {
    0: 'Non Défini',
    1: '2 mm (Micro)',
    2: '4 mm (Petit)',
    3: '8 mm (Moyen)',
    4: '12 mm (Grand)',
    5: '18.0 mm (Très Grand)',
    6: '23.3 mm (Extra Grand)',
  },
  form_desc: {
    form_geometric: 'Forme Géométrique',
    form_size: 'Taille',
    form_slot: 'Rainuré',
  },
  termsconditions: {
    title: 'Conditions Générales d’Utilisation d’IDOOSE',
    subtitle: 'Bienvenue chez IDOOSE',
    parraf1:
      'Nous vous remercions d’avoir choisi IDOOSE. Veuillez lire attentivement ces conditions générales avant d’utiliser notre logiciel. En utilisant IDOOSE, vous acceptez ces conditions générales. Si vous n’acceptez pas l’un de ces termes, n’utilisez pas notre logiciel.',
    subtitle2: '1. Portée du Contrat et Acceptation',
    subtitle3: '1.1. Services couverts par le contrat',
    parraf2:
      'Ce contrat s’applique à idoose.com et à tout autre logiciel, site web ou service fourni par IDOOSE ROBOTIK SL (collectivement, les "services").',
    subtitle4: '1.2. Acceptation de ce contrat',
    parraf3:
      'En utilisant les services d’IDOOSE, vous acceptez ces conditions générales sans modifications. Si vous n’êtes pas d’accord, n’utilisez pas les services.',
    subtitle5: '1.3. Modification du contrat',
    parraf4:
      'IDOOSE ROBOTIK SL se réserve le droit de modifier ces conditions générales à tout moment. Nous vous informerons de tout changement par email ou via notre site web. Votre utilisation continue des services après ces modifications constitue votre acceptation des mêmes.',
    subtitle6: '2. Utilisation des Services et Restrictions',
    subtitle7: '2.1. Utilisation du logiciel',
    parraf5:
      'IDOOSE est licencié, non vendu. Vous devez respecter toutes les restrictions techniques du logiciel et ne pouvez pas : • Contourner les restrictions techniques du logiciel • Décompiler, désassembler ou réaliser une ingénierie inverse du logiciel, sauf si la loi l’autorise. • Faire plus de copies du logiciel que spécifié dans ce contrat. • Publier le logiciel pour que d’autres puissent le copier. • Louer, prêter ou prêter le logiciel. • Transférer le logiciel ou ce contrat à un tiers.',
    subtitle8: '2.2. Obligations de l’utilisateur',
    parraf6:
      'Vous vous engagez à utiliser les services de manière responsable et à ne pas effectuer d’actions susceptibles de nuire à IDOOSE ROBOTIK SL ou aux autres utilisateurs. Cela inclut, mais sans s’y limiter : • Ne pas utiliser les services pour des activités illégales ou non autorisées. • Ne pas interférer avec le fonctionnement des services ou accéder aux services par des méthodes non autorisées. • Ne pas collecter d’informations personnelles d’autres utilisateurs sans leur consentement. • Ne pas télécharger ni distribuer de virus ou tout autre type de logiciel malveillant.',
    subtitle9: '3. Compte IDOOSE',
    subtitle10: '3.1. Création de compte',
    parraf7:
      'Pour accéder à certains services, vous aurez besoin d’un compte IDOOSE. Vous êtes responsable de la confidentialité de vos informations de compte et de votre mot de passe.',
    subtitle11: '3.2. Utilisation du compte',
    parraf8:
      'Vous êtes responsable de toutes les activités qui se produisent sous votre compte. Vous devez notifier immédiatement IDOOSE ROBOTIK SL de toute utilisation non autorisée de votre compte ou de toute autre violation de sécurité.',
    subtitle12: '4. Contenu',
    subtitle13: '4.1. Propriété du contenu',
    parraf9:
      'Le contenu que vous téléchargez ou transmettez via les services reste votre propriété. IDOOSE ROBOTIK SL ne revendique pas la propriété de votre contenu.',
    subtitle14: '4.2. Responsabilité du contenu',
    parraf10:
      'Vous êtes seul responsable du contenu que vous téléchargez, stockez ou transmettez via les services. Vous déclarez et garantissez que vous disposez de tous les droits nécessaires pour télécharger ce contenu et que votre contenu ne viole pas les droits de tiers.',
    subtitle15: '4.3. Revue et suppression du contenu',
    parraf11:
      'IDOOSE ROBOTIK SL se réserve le droit de revoir le contenu téléchargé sur ses serveurs et de supprimer tout contenu qui, à sa discrétion, viole ces conditions générales ou la loi applicable.',
    subtitle16: '5. Annulation des Services',
    subtitle17: '5.1. Par l’utilisateur',
    parraf12:
      'Vous pouvez annuler votre compte IDOOSE à tout moment via les pages de gestion de votre compte. L’annulation prendra effet à la fin de votre période de facturation.',
    subtitle18: '5.2. Par IDOOSE ROBOTIK SL',
    parraf13:
      'IDOOSE ROBOTIK SL se réserve le droit d’annuler votre compte ou l’accès aux services à tout moment, avec ou sans motif, et sans préavis.',
    subtitle19: '6. Législation applicable',
    parraf14:
      'Ce contrat est régi par les lois de l’Espagne, sans donner effet à aucun principe de choix de loi. Tout litige découlant de ce contrat sera soumis à la juridiction exclusive des tribunaux espagnols.',
    subtitle20: '7. Coordonnées',
    parraf15:
      'Pour toute question concernant ce contrat, vous pouvez nous contacter à info@idoose.com.',
  },
  privacy: {
    title: 'Politique de Confidentialité',
    subtitle: 'IDOOSE ROBOTIK SL',
    parraf1:
      'Chez IDOOSE ROBOTIK SL, situé à Calle Castillo de Moratalla 45, Lorquí, Murcie, CP 30564, et CIF B56740137, nous nous engageons à protéger la vie privée et la sécurité de nos utilisateurs. Cette Politique de Confidentialité décrit comment nous recueillons, utilisons et protégeons vos informations personnelles lorsque vous utilisez notre logiciel.',
    subtitle2: '1. Informations que Nous Recueillons',
    parraf2:
      'Nous pouvons recueillir les types d’informations suivants : Informations d’identification personnelle : Nom, adresse, numéro de téléphone, adresse e-mail, et toute autre information que vous fournissez volontairement. Informations d’utilisation du logiciel : Données sur la manière dont vous interagissez avec notre logiciel, y compris les fonctionnalités que vous utilisez et la fréquence d’utilisation. Informations techniques : Données techniques sur votre appareil, telles que l’adresse IP, le type de navigateur, le système d’exploitation et les paramètres de langue.',
    subtitle3: '2. Utilisation de l’Information',
    parraf3:
      'Nous utilisons les informations recueillies pour : Fournir, exploiter et maintenir notre logiciel. Améliorer, personnaliser et étendre notre logiciel. Comprendre et analyser comment vous utilisez notre logiciel. Développer de nouvelles fonctionnalités, produits et services. Communiquer avec vous, soit directement, soit par l’intermédiaire de l’un de nos partenaires, y compris pour le service client, vous fournir des mises à jour et d’autres informations relatives au logiciel, ainsi que pour des fins de marketing et promotionnelles. Traiter vos transactions et gérer votre compte. Envoyer des e-mails périodiques.',
    subtitle4: '3. Protection de vos Informations',
    parraf4:
      'Nous mettons en œuvre une variété de mesures de sécurité pour maintenir la sécurité de vos informations personnelles lorsque vous entrez, soumettez ou accédez à vos informations personnelles. Cependant, veuillez vous rappeler qu’aucune méthode de transmission sur Internet ou méthode de stockage électronique n’est 100% sécurisée.',
    subtitle5: '4. Partage de vos Informations',
    parraf5:
      'Nous ne vendons, n’échangeons ni ne transférons vos informations d’identification personnelle à des tiers. Cela n’inclut pas les tiers de confiance qui nous aident à exploiter notre logiciel, à mener nos affaires ou à vous servir, à condition que ces parties conviennent de garder cette information confidentielle. Nous pouvons également divulguer vos informations lorsque nous croyons que la divulgation est appropriée pour se conformer à la loi, appliquer nos politiques du site ou protéger nos droits, propriété ou sécurité.',
    subtitle6: '5. Vos Droits',
    parraf6:
      'Vous avez le droit d’accéder, de rectifier, d’annuler et de vous opposer au traitement de vos données personnelles. Pour exercer ces droits, vous pouvez nous contacter par email à info@idoose.com ou envoyer une lettre à notre adresse : Calle Castillo de Moratalla 45, Lorquí, Murcie, CP 30564.',
    subtitle7: '6. Changements à cette Politique de Confidentialité',
    parraf7:
      'Nous nous réservons le droit de mettre à jour cette Politique de Confidentialité à tout moment. Nous vous informerons de tout changement en publiant la nouvelle Politique de Confidentialité sur cette page. Il est recommandé de consulter périodiquement cette Politique de Confidentialité pour rester informé de tout changement.',
    subtitle8: '7. Contact',
    parraf8:
      'Si vous avez des questions concernant cette Politique de Confidentialité, vous pouvez nous contacter : IDOOSE ROBOTIK SL Calle Castillo de Moratalla 45, Lorquí, Murcie, CP 30564 Email : info@idoose.com',
  },
  payments: {
    title: "Résumé d'achat",
    totalFacturado: 'Total facturé',
    taxes: 'Des taxes plus applicables',
    compra: 'Acheter',
    securitycard: 'Paiement sécurisé par carte bancaire',
    encrypted: "Il s'agit d'un paiement sécurisé crypté SSL 128 bits",
    subscription: 'Abonnement',
    subrealizada: 'Abonnement au forfait réussi !',
    manage: 'Gérer vos informations de facturation',
    mes: '/ Mois'
  },
  patient_medicine_journal_list: {
    lng_general: 'Général',
    lng_patient: 'Patients',
    lng_history: 'Historique',
    lng_date: 'Date',
    lng_patient_id: 'Non. Patient',
    lng_patient_name: 'Nom du Patient',
    lng_produced: 'Produit',
    lng_sold: 'Vendu',
    lng_provided: 'Fourni',
    lng_medicine_name: 'Nom du Médicament',
    lng_total: 'Total',
    lng_event: 'État',
    lng_units: 'Unités',
    lng_sales: 'Vente',
    lng_aport: 'Contribution',
  },
  qrscan: {
    barcode: 'Code-barres',
    barcodeadd: 'Feuille ajoutée avec succès',
    barcodeerror: 'La feuille existe déjà',
    barcodetitle: 'Gestion des feuilles de coupons scellés',
    barcodeaddb: 'Ajouter une feuille',
    barcodecp: 'Coupons scellés',
    barcodeaddbarcode: 'Scannez la feuille de coupons scellés !',
  },
  
};

export default fr;
