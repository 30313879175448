const es = {
  demo: {
    title: 'Español',
    introduction: '',
  },
  resetpass: {
    mesage: '¿Olvidaste la contraseña?',
    info: 'Ingrese la dirección de correo electrónico asociada con su cuenta y le enviaremos un enlace para restablecer su contraseña.',
    reset: 'Reiniciar',
    back: 'Volver',
    sent: 'Solicitud enviada con éxito',
    confirmation: 'Hemos enviado un correo electrónico de confirmación a',
    check: 'Revisa tu correo electrónico',
    check1:
      'Hemos enviado por correo electrónico un código de confirmación de 6 dígitos, por favor introduzca el código en la casilla de abajo para verificar su correo electrónico.',
    verify: 'Verificar',
    resend: 'Reenviar código',
    donthave: '¿No tienes el código? ',
    return: 'Volver a la Home',
    success: '¡Solicitud enviada con éxito!',
    success2: 'Le hemos enviado un mensaje de confirmación de 6 dígitos a su correo electrónico.',
    success3:
      'Por favor, introduzca el código en el cuadro de abajo para verificar su correo electrónico.',
    newpass: 'Actualizar Contraseña',
    confirmpass: 'Confirmar Nueva Contraseña',
    pass: 'Contraseña',
    checkpegatina: 'Consulta la pegatina física',
    checkpegatina2:
      'Para garantizar la seguridad de tus datos sensibles, el PIN de confirmación de 6 dígitos está impreso en la pegatina física. Introduce ese PIN en la casilla de abajo para asegurar el acceso a la información confidencial. Si no dispones del PIN, inicia sesión y no será necesario introducirlo.',
    errorpin: 'Pin erróneo',
    checkotp: 'Revisa tu aplicacion de doble factor',
    checkotp2:
      'Consulta tu aplicacion de Google Authenticator para obtener el codigo para verificar tu cuenta.',
  },
  newparameter: {
    back: 'Volver',
    next: 'Siguiente',
    scope: 'Alcance del Parámetro',
    type: 'Tipo de Parámetro',
    config: 'Configuración',
    posology: 'Vender lo pautado en Posología',
    swap: 'Sustitución de Prescripción',
    notdispense: 'No dispensación',
    priority: 'Priorizar Destacados Farmatic',
    add: 'Finalizar',
    priorityconfirm: 'A partir de ahora daremos prioridad a tus Medicamentos Destacados.',
    posologyconfirm: 'A partir de a hora solamente se venderá lo pautado en Posología.',
    selectsust: 'Prescripción a Sustituir',
    selectsust2: 'Prescripción a Dispensar.',
    notdispence2: 'Seleccione la prescipción que no desea dispensar.',
    number: 'Número',
    scopetable: 'Afecta a',
    name: 'Nombre',
    typeparameter: 'Tipo de Parámetro',
    code: 'Códigos Nacionales',
    patient: 'Paciente',
    center: 'Centro',
    pharmacy: 'Farmacia',
    swaptype: 'Sustitución de Prescripción',
    prioritytype: 'Priorizar Destacados',
    posologytype: 'Solo lo pautado en Posología',
    notdispencetype: 'No dispensar',
    no_financied: 'Solo Financiados',
    no_narcotic: 'No vender Estupefacientes',
    maxstock: 'Mayor Stock',
    max_box_dps: 'Unidades Máximas',
    no_regex: 'No dispensación por "palabra"',
    order_lab: 'Priorizar Laboratorio',
    check_stock: 'Vender con Stock',
    force_posology: 'Vender por los CN de Posología',
    laboratory: 'Listado de Laboratorios',
    replace_max_stock: 'Sustitución por Máximo Stock',
    cst_change: 'Última Recarga en Tolva',
  },
  table: {
    column: 'Columnas',
    find: 'Encuentra tu columna.',
    columntitle: 'Título de la columna',
    hyde: 'Desmarcar todos',
    mark: 'Seleccionar todos',
    filters: 'Filtros',
    density: 'Formato de Visualización',
    density1: 'Compacto',
    density2: 'Normal',
    density3: 'Grande',
    export: 'Exportar',
    operators: 'Operadores',
    contains: 'contiene',
    equals: 'es igual a',
    start: 'empieza por',
    end: 'termina por',
    empty: 'está vacío',
    noempty: 'no está vacío',
    value: 'Valor',
    filtervalue: 'Filtrar valor',
    download: 'Descargar como CSV',
    print: 'Imprimir',
    download2: 'Descargar como Excel',
    columnMenuLabel: 'Menú',
    columnMenuShowColumns: 'Mostrar columnas',
    columnMenuManageColumns: 'Gestionar columnas',
    columnMenuFilter: 'Filtrar',
    columnMenuHideColumn: 'Ocultar columna',
    columnMenuUnsort: 'Desordenar',
    columnMenuSortAsc: 'Ordenar por ASC',
    columnMenuSortDesc: 'Ordenar por DESC',
  },
  menu: {
    general: 'MENU',
    order: 'Gestión de Ordenes',
    'historic Orders': 'Histórico',
    catalog: 'Medicamentos',
    patient: 'Pacientes',
    posology: 'Posología',
    newpro: 'Producción',
    Laboratorys: 'Laboratorios',
    Config: 'Configuración',
    management: 'ADMINISTRACIÓN',
    user: 'Usuarios',
    profile: 'Mi perfil',
    list: 'Administración de Usuarios',
    myinstallation: 'Mi Instalación',
    subscription: 'Mi farmacia',
    invoice: 'Factura',
    centers: 'Centros',
    centersmanage: 'Gestión de Centros',
    virtual: 'IA Mery',
    available: 'Ordenes de compra',
    parameters: 'Parámetros',
    listrx: 'Recetas por Paciente',
    reports: 'Receta Electrónica',
    traceability: 'Trazabilidad',
    alerts: 'Alertas',
    history: 'Histórico',
    medpatient: 'Medicación por Paciente',
    merge: 'Cruce Posología',
    take: 'Entrega de Medicación',
    import: 'Importación',
    inicio: 'Inicio',
    upload: 'Importar Fichero',
    parametersimport: 'Parámetros de Importación',
    task: 'Tareas Programadas',
    electronic: 'Tarjetas Sanitarias',
    availableia: 'Disponibles',
    match: 'Cruce Posología /Receta',
    sellparameters: 'Parámetros de Venta',
    form0: 'Informes',
    form1: 'Informe de Producción',
    form2: 'Medicación por Paciente',
    form3: 'Trazabilidad',
    chat: 'Chat',
    calendar: 'Calendario',
    support: 'Soporte Técnico',
    assist: 'Asistencia',
    softinstall: 'Software instalado',
  },
  dropdown: {
    Home: 'Inicio',
    Profile: 'Perfil',
    Settings: 'Configuración',
    logout: 'Cerrar sesión',
  },
  alert: {
    success: 'Acceso concedido',
    newuser: 'Nuevo Usuario',
    verified: 'Verificado',
    yes: 'Si',
    no: 'No',
    edit: 'Editar',
    deleteuser: 'Eliminar Usuario',
    confirmation: '¿Estás seguro de borrar a este/estos Usuarios?',
  },
  myuser: {
    general: 'General',
    pass: 'Cambiar Contraseña',
    allow: 'Permitidos',
    sice: 'Tamaño máximo de',
    save: 'Guardar Cambios',
    about: 'Comentario',
    old: 'Contraseña Actual',
    new: 'Nueva Contraseña',
    mustbe: 'La contraseña debe de tener mínimo 6 o más carácteres.',
    confirm: 'Confirmar Nueva Contraseña',
    banned: 'Desactivado',
    applyban: 'Aplicar desactivación de la cuenta.',
    savechanges: 'Guardar Cambios',
    upload: 'Subir Fichero',
    photo: 'Subir Foto',
    update: 'Actualizar Foto',
  },
  register: {
    mesage: 'Rápido, sencillo e intuitivo.',
    intro: 'Crea tu cuenta y comienza a disfrutar de nuestras soluciones.',
    intro2: '¡Es muy sencillo!',
    name: 'Nombre y Apellidos',
    lastname: 'Apellidos',
    namepharma: 'Nombre de la Instalación',
    create: 'Registrar',
    already: '¿Ya tienes una cuenta? ',
    firstnamerec: 'El nombre es obligatorio',
    lastnamerec: 'Los apellidos son necesarios',
    emailrec: 'El correo electrónico es obligatorio',
    passrec: 'La contraseña es necesaria',
  },
  centers: {
    list: 'Listado de Centros',
    title: 'Ficha del centro',
    nif: 'NIF',
    date: 'Fecha de registro',
    production: 'Modo Producción',
    machinepro: 'Dispositivo Seleccionado',
    print1: 'Imprimir los NO envasables.',
    print3: 'Imprimir los Si precisa.',
    print2: 'Imprimir por momentos.',
    code: 'Por código de medicamento',
    code2: 'Por familia de envases',
    code3: 'Por familias biológicas',
    change: '¿Deseas cambiar de Centro?',
    changeinstallation: '¿Deseas cambiar de Instalación?',
    sure: '¿Está seguro de que desea cambiar de centro?',
    acept: 'Aceptar',
    addnew: 'Nueva Pauta Predeterminada',
    namenewpred: 'Nombre de la Pauta',
    syncdate: 'Sincronizar fecha fin Receta Electrónica',
    syncseller: 'Actualizar la posología según la última venta de medicamento',
    antibioticpackablealone: 'Cada antibiótico requiere un envase independiente',
  },
  listmedicine: {
    medication: 'Medicación',
    search: 'Búsqueda',
    filter: 'Filtro Medicamento',
    all: 'Todos',
    tipemedication: 'Medicamentos',
    familya: 'Familia Envase',
    familyb: 'Familia Biológica',
    inactive: 'Inactivo',
    active: 'Activo',
    ficha: 'Ficha del Medicamento',
    brand: 'Marca',
    generic: 'Genérico',
    needrecipe: 'Receta necesaria',
    code: 'Código',
    name: 'Nombre',
    codefamily: 'Código Familia Biológica',
    namefamily: 'Nombre Familia Biológica',
    units: 'Unidades Caja',
    atc: 'Código ATC',
    packable: 'Envasable',
    psicotropic: 'Psicotrópico',
    narcotic: 'Narcótico',
    warning: 'Precauciones',
    replaceable: 'Reemplazable',
    clinic: 'Env. Clínico',
    use: 'Hospitalario',
    comer: 'Comercializado',
    tld: 'TLD',
    para: 'Parafarmacia',
    control: 'Control Médico',
    drive: 'E.Conducción',
    orphan: 'Huérfano',
    recipe: 'Receta',
    bio: 'Bio Similar',
    save: 'Guardar',
    cancel: 'Cancelar',
  },
  listpatient: {
    title: 'Listado de Pacientes',
    search: 'Búsqueda',
    active: 'Activo',
    ficha: 'Ficha del Paciente',
    name: 'Nombre',
    dni: 'DNI/NIE',
    born: 'F.Nacimiento',
    ss: 'Número de la SS',
    phone: 'Teléfono',
    sex: 'Género',
    female: 'Femenino',
    male: 'Masculino',
    bed: 'Cama',
    region: 'Provincia',
    room: 'Habitación',
    address: 'Dirección',
    country: 'País',
    city: 'Ciudad',
    cp: 'CP',
    mail: 'Correo electrónico',
    save: 'Guardar',
    cancel: 'Cancelar',
    sync: 'Sincronización',
    journal: 'Histórico de Medicación',
    electronic: 'Receta Electrónica',
    group: 'Grupo',
    doctorname: 'Nombre del médico/a',
    excludeia: 'Excluir los cambios de IA',
  },
  production: {
    status: 'Estado',
    title: 'Nueva Producción',
    code: 'Código',
    datecreate: 'Fecha de Creación',
    dateproduction: 'Fecha de Producción',
    lastprox: 'Próxima/Última Dispensación',
    initialdose: 'Inicio/Última Dispensación',
    dateend: 'Fecha de Finalización',
    cancel: 'Cancelar',
    pendent: 'Pendiente',
    working: 'En curso',
    produced: 'Producida',
    deleted: 'Eliminada',
    repackaging: 'Producción de Reenvasado',
    pouch: 'Nº de Dosis',
    dose: 'Unidades por Dosis',
    printlabel: 'Impresión de Etiquetas',
    labelsearch: 'Buscar por Nombre',
    cretit_none: 'Créditos insuficientes',
    cretit_none1:
      'No tienes suficientes créditos para completar esta producción. Puedes suscribirte a un plan mensual para obtener créditos de forma automática o, si has comprado consumibles originales, ingresar el código serial que viene en la tarjeta incluida en tu pedido.',
    cretit_none2: 'Por favor, recarga tu cuenta para continuar.',
    cretit_none3: 'Créditos actuales',
    cretit_none4: 'Créditos necesarios',
    cretit_none5: 'Créditos',
    num_production: 'Nº Producción',
    date_generate: 'Generación Producción',
    dates_production: 'Fechas Producción',
    date_send: 'Envío a Dispositivo',
  },
  dashboard: {
    title: 'Bienvenido de nuevo 👋',
    titlecomment:
      'Con nuestra avanzada IA en IDOOSE ROBOTIK, ofrecemos el robot más seguro y fiable del mercado.',
    discover: 'Descubre más',
    patients: 'Pacientes',
    medication: 'Medicación Activa',
    production: 'Producciones',
    caducity: 'Medicación próxima a la caducidad',
    data: 'Datos de la Última Producción: ',
    total: 'Total Dispensado:',
    packable: 'Envasables',
    unpackable: 'No envasable',
    dispensed: 'Dispensado',
    activpatient: 'Pacientes Activos',
    activposology: 'Tratamientos Activos',
    totalcount: 'U. Dispensadas Mes',
    type: 'Carácteristicas',
    if_needed: 'Si precisa',
    iaquality: 'IA Calidad',
    merge: 'Correctos',
    rxx: 'No Receta',
    posology: 'No Posología',
    reviwer: 'Revisar',
    dep: 'Deposito',
    ranking: 'TOP Cambios',
  },
  posology: {
    force_print: 'Solo Impresión',
    no_sync: 'Protegido',
    title: 'Posología',
    search: 'Búsqueda',
    active: 'Activo',
    inactive: 'Medicación Inactiva',
    save: 'Guardar',
    cancel: 'Cancelar',
    packable: 'Envasable',
    unpackable: 'No envasable',
    start: 'Fecha de inicio',
    end: 'Fecha Final',
    dosis: 'Dosis',
    period: 'Días Periodo',
    cronic: 'Crónico',
    days: 'Días',
    hour: 'Hora Periodo',
    starthour: 'Hora Inicio',
    months: 'Meses',
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre',
    week: 'Semanal',
    ciclica: 'Cíclica Días',
    ciclicb: 'Cíclica Horas',
    especilavar: 'Variable',
    month: 'Mensual',
    aparte: 'Envasado Aparte',
    optional: 'Si Precisa',
    irreplaceable: 'Irremplazable',
    moment: 'Momento',
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
    sunday: 'Domingo',
    add: 'Añadir Toma',
    name: 'Nombre de la toma',
    selecttitle: 'Selección del Medicamento',
    select: 'Seleccionar',
    addtoma: 'Añadir',
    cannot: 'No puedes guardar una pauta vacía.',
    confirmation: 'Confirmación',
    confirmationtext: '¿Está seguro de que desea eliminar este tratamiento?',
    confirmationdelete: 'Eliminar',
    update: 'Cambios guardados correctamente.',
    error: 'Error al eliminar la posología.',
    erroradd: 'Error al añadir la posología.',
    addsuccess: 'Añadido correctamente.',
    contact:
      'Mensaje enviado correctamente. Nos pondremos en contacto contigo lo más pronto posible.',
    deletesuccess: 'Eliminado correctamente.',
    errordate: 'La Fecha Inicio no puede ser inferior a hoy.',
    exist: 'Toma ya existente.',
    periodless: 'El periodo no puede ser inferior a días.',
    daysless: 'Días no puede ser inferior a 1.',
    hourless: 'Horas no puede ser inferior a 1.',
    hourerror: 'El periodo no puede ser superior a 24 horas.',
    dayerror: 'El día no puede ser superior a 31 o inferior a 0.',
    delete: 'Eliminar Posología',
    note: 'Notas',
    interaction: 'Interacciones farmacológicas',
    deletedose: '¿Deseas eliminar el tratamiento seleccionado?',
    confirmationotp: 'Generar QR',
  },
  login: {
    mesage: 'Hola, bienvenido',
    introduction:
      'Lorem Ipsum ist einfach Dummy-Text der Druck- und Satzindustrie. Lorem Ipsum ist seit dem 16. Jahrhundert der Standard-Dummy-Text der Branche, als ein unbekannter Drucker eine Galeere vom Typ nahm und sie zu einem Musterbuch verschlüsselte. Es hat nicht nur fünf Jahrhunderte überlebt, sondern auch den Sprung in den elektronischen Satz, der im Wesentlichen unverändert geblieben ist. Es wurde in den 1960er Jahren mit der Veröffentlichung von Letraset-Blättern mit Lorem Ipsum-Passagen und in jüngerer Zeit mit Desktop-Publishing-Software wie Aldus PageMaker einschließlich Versionen von Lorem Ipsum populär gemacht.',
    signin: 'Iniciar sesión en IDOOSE',
    details: 'Introduzca sus datos a continuación.',
    inicio: 'Iniciar Sesión',
    agree: 'Al registrarme, acepto las ',
    terms: 'Condiciones del servicio',
    privacy: 'Política de privacidad',
    and: ' y ',
    account: '¿Nuevo Usuario? ',
    started: 'Crear una cuenta',
    email: 'Correo electrónico',
    password: 'Contraseña',
    rememberme: 'Recuérdame',
    forgotpassword: '¿Olvidaste tu Contraseña?',
    requiremail: 'El correo electrónico es necesario',
    requirepass: 'La contraseña es necesaria',
    complete: 'Completa tus Datos',
    alreadyaccount: '¿Ya tienes cuenta?',
    identify: 'Identifícate',
    requirement: 'Al registrarme, acepto los ',
    requirement2: ' Terminos y Condiciones de Uso',
    requirement3: ' y la ',
    requirement4: 'Política de Privacidad',
  },

  virtual: {
    list: 'Listado de Recetas',
    new: 'Nueva Receta',
    active: 'Activa',
    expired: 'Caducada',
    expiredate: 'Fecha de Caducidad',
    renewdate: 'Fecha de renovación',
    raw: 'Banda / Chip / TSI / Nº Receta',
    order: 'Código Barras (solo CAT)',
    review: 'Revisión',
    assignment: 'Asignación de Códigos',
    sell: 'Venta',
    create: 'Crear Orden',
    title: 'Tareas Programadas',
    number: 'Nº Orden',
    status: 'Estado',
    date: 'Fecha de Inicio',
    patients: 'Nº de Pacientes',
    titleparameters: 'Parámetros de Venta',
    confirm: '¿Deseas borrar la receta seleccionada?',
    deletesinc: '¿Deseas borrar la sincronización seleccionada?',
    productionform: 'Informe de Producción',
    prepareinform: 'Preparar Informe',
    export: 'Exportar a Excel',
  },

  trazability: {
    ema: 'Agencia Europea de Medicamentos (EMA)',
    text: 'La Agencia Europea de Medicamentos (EMA) es una agencia descentralizada de la Unión Europea (UE) responsable de la evaluación científica, la supervisión y el seguimiento de la seguridad de los medicamentos en la UE. EMA es una organización de redes cuyas actividades involucran a miles de expertos de toda Europa. Estos expertos llevan a cabo el trabajo de los comités científicos de la EMA.',
    discover: 'Descubre EMA',
    detections: 'Alertas Detectadas',
    eligible: 'Alertas Subsanables',
    lastupdate: 'Última Actualización',
    totals: 'Medicación total Verificada',
  },

  reportpatientproductionlist: {
    lng_title: 'INFORME DE PACIENTES PRODUCIDOS',
    lng_titlelistcenter: 'LISTADO DE PACIENTES',
    lng_phone: 'Teléfono',
    lng_mail: 'Correo',
    lng_num_production: 'Nº',
    lng_center: 'Centro',
    lng_date_int: 'Fecha Inicio',
    lng_date_end: 'Fecha Final',
    lng_code: 'Código',
    lng_name: 'Nombre',
    lng_send: 'Envío',
    lng_reception: 'Recepción',
    lng_date: 'Fecha',
    lng_no_medication: 'Sin Medicación',
    lng_reviwer: 'Revisado por:',
    lng_produced: 'Producido por:',
  },
  reportmedicineproductionlist: {
    lng_title: 'INFORME DE MEDICACIÓN AGRUPADA',
    lng_title2: 'INFORME DE RECARGA',
    lng_title3: 'INFORME DE MEDICACIÓN POR PACIENTE',
    lng_phone: 'Teléfono',
    lng_mail: 'Correo',
    lng_num_production: 'Nº Producción',
    lng_center: 'Centro',
    lng_date_int: 'Fecha Inicio',
    lng_date_end: 'Fecha Final',
    lng_code: 'Código',
    lng_name: 'Nombre',
    lng_qty: 'Unidades',
    lng_yes: 'Si',
    lng_no: 'No',
    lng_packable: 'Emblistable',
    lng_date: 'Fecha',
    lng_dev: 'Tolva',
    lng_lot: 'Lote',
    lng_exp: 'Caducidad',
    lng_qty2: 'Resta',
  },
  reportposologyproductionlist: {
    lng_title_not_packing: 'MEDICACIÓN NO EMBLISTABLE',
    lng_title_packing: 'MEDICACIÓN EMBLISTABLE',
    lng_mo2: 'LUNES',
    lng_tu2: 'MARTES',
    lng_we2: 'MIERCOLES',
    lng_th2: 'JUEVES',
    lng_fr2: 'VIERNES',
    lng_sa2: 'SABADO',
    lng_su2: 'DOMINGO',

    lng_title: 'PLAN FARMACOLÓGICO',
    lng_phone: 'Teléfono',
    lng_mail: 'Correo',
    lng_date: 'Fecha',
    lng_num_patient: 'Nº Paciente',
    lng_name_patient: 'Nombre',
    lng_dni: 'DNI',
    lng_ss: 'Numero SS',
    lng_code_medicine: 'Código',
    lng_name_medicine: 'Nombre Medicamento',
    lng_posology: 'Pauta',
    lng_date_int: 'Fecha Inicio',
    lng_date_end: 'Fecha Final',
    lng_cronic: 'Crónico',
    lng_mo: 'L',
    lng_tu: 'M',
    lng_we: 'X',
    lng_th: 'J',
    lng_fr: 'V',
    lng_sa: 'S',
    lng_su: 'D',
    lng_day: 'Días',
    lng_month: 'Meses',
    lng_ja: 'EN',
    lng_fe: 'FE',
    lng_ma: 'MZ',
    lng_ap: 'AB',
    lng_my: 'MY',
    lng_jn: 'JU',
    lng_jl: 'JL',
    lng_au: 'AG',
    lng_se: 'SE',
    lng_oc: 'OC',
    lng_no: 'NO',
    lng_de: 'DI',
    lng_month_all: 'Todos Los Meses',
    lng_hour: 'Cada',
    lng_hour_x: 'Horas Desde Las',
  },

  reportchangeslist: {
    lng_title_not_packing: 'MEDICACIÓN NO EMBLISTABLE',
    lng_title_packing: 'MEDICACIÓN EMBLISTABLE',
    lng_mo2: 'LUNES',
    lng_tu2: 'MARTES',
    lng_we2: 'MIERCOLES',
    lng_th2: 'JUEVES',
    lng_fr2: 'VIERNES',
    lng_sa2: 'SABADO',
    lng_su2: 'DOMINGO',

    lng_title: 'INFORME DE CAMBIOS',
    lng_phone: 'Teléfono',
    lng_mail: 'Correo',
    lng_date: 'Fecha',
    lng_num_patient: 'Nº Paciente',
    lng_name_patient: 'Nombre',
    lng_dni: 'DNI',
    lng_ss: 'Numero SS',
    lng_code_medicine: 'Código',
    lng_name_medicine: 'Nombre Medicamento',
    lng_posology: 'Pauta',
    lng_date_int: 'Fecha Inicio',
    lng_date_end: 'Fecha Final',
    lng_cronic: 'Crónico',
    lng_mo: 'L',
    lng_tu: 'M',
    lng_we: 'X',
    lng_th: 'J',
    lng_fr: 'V',
    lng_sa: 'S',
    lng_su: 'D',
    lng_day: 'Días',
    lng_month: 'Meses',
    lng_ja: 'EN',
    lng_fe: 'FE',
    lng_ma: 'MZ',
    lng_ap: 'AB',
    lng_my: 'MY',
    lng_jn: 'JU',
    lng_jl: 'JL',
    lng_au: 'AG',
    lng_se: 'SE',
    lng_oc: 'OC',
    lng_no: 'NO',
    lng_de: 'DI',
    lng_month_all: 'Todos Los Meses',
    lng_hour: 'Cada',
    lng_hour_x: 'Horas Desde Las',
    lng_id_center: 'Nº Centro',
    lng_state: 'Estado',
    lng_state_add: 'Añadido',
    lng_state_edit: 'Modificado',
    lng_state_delete: 'Eliminado',
  },

  sync: {
    new: 'Añadir Sincronización',
  },
  reportpatientjournallist: {
    lng_title: 'INFORME DE DEUDA PACIENTES',
    lng_phone: 'Teléfono',
    lng_mail: 'Correo',
    lng_date: 'Fecha',
    lng_num_patient: 'Nº Paciente',
    lng_name_patient: 'Nombre',
    lng_dni: 'DNI',
    lng_ss: 'Numero SS',
    lng_code_medicine: 'Código',
    lng_name_medicine: 'Nombre Medicamento',
    lng_prod: 'Producido',
    lng_seller: 'Vendido',
    lng_aport: 'Aportado',
    lng_total: 'Total',
  },
  reportpatientconsent: {
    lng_anx_1:
      'deseo participar en el programa de sistemas de dosificación personalizada de la farmacia',
    lng_anx_2:
      'Entiendo que dicho programa consiste en el acondicionamiento de la medicación en un sistema de dosificación personalizada.',
    lng_anx_3:
      'Participo de forma totalmente voluntaria en dicho programa y podré dejar de participar en este programa cuando lo estime oportuno simplemente revocando este consentimiento cumplimentando el apartado revocación del mismo.',
    lng_anx_4:
      'Participar en este proyecto implica, si se hace a partir de envases comerciales, dejar los medicamentos en sus envases originales en la farmacia donde es responsabilidad del farmacéutico su correcta custodia y conservación.',
    lng_anx_5: 'Me comprometo a:',
    lng_anx_51: 'Comunicar al farmacéutico cualquier variación en mi medicación.',
    lng_anx_52: 'Cumplir las condiciones de conservación y seguridad del blister.',
    lng_anx_53:
      'Entregar los dispositivos vacíos de las semanas anteriores para la comprobación del cumplimiento y posibles errores de utilización.',
    lng_anx_6: 'El farmacéutico se compromete a:',
    lng_anx_61:
      'Comunicarme cualquier problema relacionado con el medicamento que pudiera detectar.',
    lng_anx_62:
      'Suministrarme la información necesaria para la correcta utilización de los dispositivos de dosificación personalizada y de los propios medicamentos.',
    lng_anx_7:
      'Autorizo al farmacéutico al tratamiento de mis datos personales necesarios para la participación en el programa*.',
    lng_consent: 'CONSENTIMIENTO INFORMADO',
    lng_date: 'Fecha',
    lng_dni: 'DNI',
    lng_don_d: 'D/Dª',
    lng_lopd_1:
      '*Los datos personales facilitados para la inclusión en el programa sistemas de dosificación personalizada serán incluido en un fichero denominado SPD, debidamente inscrito en la Agencia Española de Protección de Datos, cuyo responsable es la farmacia',
    lng_lopd_2:
      ' cuentan con todas las medidas de seguridad necesarias de acuerdo a la Ley de Protección de Datos, serán tratados únicamente con la finalidad de gestionar los dispositivos de dosificación personalizados del paciente, los datos podrán ser cedidos o comunicados a profesionales sanitarios, familiares y/o cuidadores relacionados con el paciente con objeto de corroborar el tratamiento y/o mejorar la adherencia al mismo, en ningún caso se utilizarán con fines de publicidad o promoción comercial, están sometidos al secreto profesional del farmacéutico. Las personas cuyos datos están registrados en estos ficheros tienen derecho a acceder a los mismos, rectificarlos o cancelarlos, cuando lo consideren oportuno dirigiéndose a esta misma farmacia en los términos previstos legalmente.',
    lng_mail: 'Correo',
    lng_name_d: 'Paciente/Representante Legal',
    lng_name_patient: 'Nombre',
    lng_name_ph_d: 'Farmacéutico',
    lng_name_ph_sin_d: 'Firma del Farmacéutico',
    lng_name_sin_d: 'Firma del Paciente/Representante Legal',
    lng_num_patient: 'Nº Paciente',
    lng_phone: 'Teléfono',
    lng_ss: 'Numero SS',
    lng_title: 'CONSENTIMIENTO INFORMADO DEL PACIENTE',
  },
  Bob: {
    ordernumber: 'Nº Orden',
    status: 'Estado',
    process: 'Progreso',
    Startorder: 'Inicio de la Orden',
    createdate: 'Fecha de creación',
    selling: 'Procesando',
    reviewing: 'Revisando',
    wait: 'En espera',
    sevem: 'SEVEM',
    patient: 'Paciente',
    drug: 'Medicamento',
    recipe: 'Receta Electrónica',
    initialdate: 'Inicio del tratamiento',
    expiredate: 'Caducidad',
    posology: 'Posología',
    scan: 'Escanea el Código',
    dep: 'Deposito',
    without1: 'No Posología',
    without2: 'No Receta',
    reviwer: 'Revisar',
    correct: 'Correcto',
    lot: 'Lote',
    neworder: 'Crear Orden',
    deleteorder: '¿Deseas eliminar la orden de trabajo seleccionada?',
    numberorder: 'Nº de Orden:',
    history: 'Historico de Trazabilidad',
    scancodes: 'Asignación de Códigos',
    forceall: 'Forzar todo a CN',
    last: 'Última Actualización',
    deleteparameter: '¿Deseas eliminar el parámetro seleccionado?',
    sellformtitle: 'INFORME DE VENTA',
  },
  official: {
    error: 'Documento no disponible.',
  },
  display: {
    prospectus: 'Prospecto',
    technical: 'F.Técnica',
    unpackable: 'No envasable',
    titletake: 'Fecha y momento de toma',
    titleproduction: 'Fecha Envasado',
    titleexprepacking: 'Caducidad Dispositivo SPD',
  },
  notfound: {
    sorry: 'Toma no encontrada.',
    sorry2: 'Lo sentimos, no hemos podido encontrar la página que busca.',
    home: 'Página Principal',
  },
  editproduction: {
    edit: 'Edición de la Producción',
    confirm: '¿Está seguro de que desea modificar la producción?',
    home: 'Página Principal',
    predose: 'Pautas Predeterminadas',
    addnewpre: 'Nueva Toma Predeterminada',
    nametome: 'Nombre de la Toma',
    hourtome: 'Hora de la Toma',
    confirmationtext: '¿Está seguro de que desea eliminar esta toma predeterminada?',
  },

  blisterpro: {
    process: 'PROGRESO',
    sorry2: 'Lo sentimos, no hemos podido encontrar la página que busca.',
    home: 'Página Principal',
  },

  various: {
    exhausted: 'Agotado',
    datapatient: 'Datos del Paciente',
    center: 'Datos del Centro',
    expiredon: 'Fecha de Caducidad:',
    synclist: 'Elementos Sincronizados',
    debt: 'Deuda',
    drug: 'Medicamento',
    produced: 'Producido',
    sell: 'Vendido',
    provided: 'Aportado',
    total: 'Total',
    date: 'Fecha',
    Details: 'Detalle',
    event: 'Evento',
    units: 'Unidades',
    production: 'Producción',
    sell2: 'Vendido',
    add: 'Añadir Aportación',
    historic: 'Histórico',
    historic2: 'Histórico de Trazabilidad',
    ns: 'Nº Serie',
    available: 'Disponible',
    next: 'Proximamente',
    recomended: 'Recomendado',
    notrecomended: 'No recomendado',
    register: 'Registro de Entregas',
    registerdate: 'Fecha de la Toma',
    from: 'Desde',
    to: 'Hasta',
    datapicker: 'Fechas del Informe ',
    download: 'Descargar',
    tpills: 'U.Total',
    tneed: 'Resta',
    rbox: 'Cajas',
    result: 'resultados',
    predetermlist: 'Listado de Pautas',
    deletepredet: '¿Deseas eliminar la pauta seleccionada?',
    no_verifi: 'No Verificado',
    verifi: 'Verificado',
    installation: 'Mi Instalación',
    exportamts: 'Exportar ATMS',
    confirmdose: '¿Desea registrar la Toma?',
    whynot: 'Motivo Cancelación',
  },
  tooltip: {
    refill: 'Informe de Recarga',
    label: 'Imprimir Etiquetas',
    edit: 'Editar Producción',
    grouped: 'Informe de Medicación Agrupada',
    report1: 'Informe Paciente-Medicación',
    delivery: 'Albarán de Entrega',
    individual: 'Albarán Individual',
    allinone: 'Albarán',
    scan: 'Escanear',
    markall: 'Escanear Todos',
    archived: 'Archivados',
    delete: 'Eliminar Producción',
    generateproduction: 'Generar Producción',
    repackaging: 'Producción de Reenvasado',
    start: 'Iniciar Producción',
    list: 'Listado de Pacientes',
    add: 'Nuevo Paciente',
    listposology: 'Posología por Paciente',
    excelposology: 'Excel Posologías',
    official: 'Informe Posología Oficial',
    activate: 'Activar/Desactivar WhatsApp',
    consent: 'Consentimiento Informado',
    alllabel: 'Todas',
    changes: 'Informe de Cambios',
    sevem: 'Finalizar la Venta',
    new: 'Nueva Tarea',
    copy: 'Copiar',
    insert: 'Insertar',
    newparameter: 'Nuevo Parámetro',
    center: 'Nuevo Centro',
  },
  import: {
    e_title: '¡Atención! El archivo importado podría ser incorrecto',
    e_dialog: 'Parece que hemos importado un archivo de la residencia :',
    e_dialog1: 'en lugar de la residencia :',
    e_dialog2: 'Por favor, revisa y verifica la situación.',
    i_title: 'Importar Fichero',
    i_type: 'Formato del Fichero',
    i_drop: 'Seleccionar Fichero',
    i_dropb:
      'Puedes arrastrar y soltar el archivo aquí o explorar en tu ordenador para seleccionarlo.',
    0: 'Resiplus (*.txt)',
    1: 'Resiplus Multi (*.txt)',
    2: 'Resiplus (*.xls)',
    3: 'Resiplus Multi (*.xls)',
    4: 'Sanitas (*.pdf)',
    5: 'DomusVi (*.txt)',
    6: 'DomusVi (*.pdf)',
    7: 'Pill Device (*.json)',
    8: 'Cretem Atms (*.xml)',
    9: 'Cretem Atms Multi (*.zip)',
    10: 'Jvm Oncube (*.txt)',
    11: 'Jvm Oncube Multi (*.zip)',
    12: 'Ecap (*.txt)',
  },
  module_: {
    title_: 'Módulo no disponible con la licencia contratada',
    subtitle_: 'Contacte con el departamento comercial para solicitar una demostración!',
    return_: 'Volver App',
  },
  permit: {
    permit: 'Administrar Permisos',
  },
  permissionsgroup: {
    1: 'Catálogo de Medicamentos',
    2: 'Gestión de Pacientes',
    3: 'Posología del Paciente',
    4: 'Módulo de Producción',
    5: 'Importaciones',
    6: 'IA Mery',
    7: 'Trazabilidad',
    8: 'Administración de Usuarios',
    9: 'Administración de Centros',
    10: 'Gestionar datos de mi Farmacia',
    11: 'Entrega de Medicación',
    12: 'Informes',
  },
  permissions: {
    1: 'Activar Catálogo de Medicamentos',
    2: 'Modificar Medicamentos',
    3: 'Visualizar datos del Paciente',
    4: 'Modificar datos del Paciente',
    5: 'Visualizar Receta Electrónica',
    6: 'Añadir o Eliminar Receta Electrónica',
    7: 'Visualizar Sincronización',
    8: 'Añadir o Eliminar Sincronizaciones',
    9: 'Visualizar consumos del paciente',
    10: 'Modificar consumos del paciente',
    11: 'Activar Posología',
    12: 'Modificar Posología',
    13: 'Activar Módulo de Producción',
    14: 'Crear Producción',
    15: 'Enviar Producción / Apartado Blister Manual',
    16: 'Editar Producción',
    17: 'Eliminar Producción',
    18: 'Activar Módulo de Importación',
    19: 'Importar ficheros al sistema',
    20: 'Habilitar IA Mery',
    21: 'Visualizar Tareas Programadas',
    22: 'Añadir Tareas',
    23: 'Visualizar Tarjetas Sanitarias',
    24: 'Insertar Tarjetas',
    25: 'Visualizar Disponibles',
    26: 'Visualizar Cruce Posología / Receta',
    27: 'Activar Parámetros de Venta',
    28: 'Crear Parámetro de Venta',
    29: 'Activar apartado de Pacientes.',
    30: 'Habilitar módulo de Control de tomas.',
    31: 'Entrega de medicación a Pacientes.',
    32: 'Activar Trazabilidad',
    33: 'Activar Administración de Usuarios',
    34: 'Modificar Usuarios',
    35: 'Activar Gestión de Centros',
    36: 'Modificar Centros',
    37: 'Activar Mi Instalación',
    38: 'Modificar Mi Instalación',
    39: 'Activar Informes',
    40: 'Informe de Producción',
  },
  control_takes: {
    1: 'No Precisa',
    2: 'Falta Medicación',
    3: 'Medicación Dañada',
    4: 'El Paciente Se Niega',
    5: 'Otros',
    cause_not_take: 'Motivo',
    6: 'Hora Errónea',
    7: 'Cambio en la Medicación',
    8: 'Añadir',
    9: 'Retirar',
    10: 'Registrar Toma',
  },
  home: {
    open_ticket: 'Abrir Incidencia',
    open_manual: 'Manual del Usuario',
    home: 'Inicio',
    title: 'Simplifica tu Medicación',
    titletex:
      'Bienvenido a la vanguardia de la gestión de medicamentos. Nuestro Software de Sistemas Personalizados de Dosificación (SPD) redefine la manera en que te relacionas con tu salud. Olvídate de las complicaciones en la preparación de tus medicamentos. Con nuestra solución intuitiva y personalizada, la adherencia al tratamiento se convierte en una experiencia sencilla y eficiente.',
    available: 'Disponible en',
    benefits: 'Beneficios del SPD',
    titlebenefit1: 'Aviso de Toma',
    benefit1:
      'Optimiza tu rutina con nuestra aplicación que te brinda recordatorios precisos para cada toma. Olvídate de las preocupaciones por olvidos y garantiza tu bienestar. Además, consulta las incompatibilidades e interacciones entre tus medicamentos para una gestión completa y segura de tu salud.',
    titlebenefit2: 'Servicio Personalizado',
    benefit2:
      'Experimenta un nuevo estándar de comodidad en tu cuidado de la salud. Con IDOOSE, recibirás tu medicación en dosis meticulosamente preparadas por profesionales farmacéuticos altamente capacitados. Nos dedicamos a proporcionarte una experiencia sin complicaciones, aportando calidad y simplificando cada toma.',
    titlebenefit3: 'Preparamos tu Medicación',
    benefit3:
      'Adiós a la complicación de preparar cajas. Con nuestro sistema de unidosis correlativas, la toma de tu medicación es tan fácil como abrir y listo. Cada dosis contiene información detallada, incluyendo el prospecto, para brindarte comodidad y claridad en cada paso de tu tratamiento.',
    appidoose: 'Con IDOOSE nunca olvidarás tu Medicación.',
    appidoosetext:
      'Descarga la app, regístrate y te pondremos en contacto con tu Farmacia más cercana.',
    listposology: 'Posología por Paciente',
    design: 'Diseñada para ti',
    partner: '¿Quieres colaborar con nosotros?',
    partner2: 'HAZTE PARTNER',
    partnertext:
      'Nos destacamos como líderes indiscutibles en la aplicación de la inteligencia artificial en el Sistema Personalizado de Dosificación (SPD). Al elegirnos, garantizarás la eficiencia de tus producciones y eliminarás por completo cualquier riesgo de error en el proceso de producción. Confía en nuestra experiencia para llevar tus operaciones al más alto nivel de precisión y seguridad.',
    prices: 'TIPOS DE LICENCIAS SPD',
    licence: 'Licencia',
    patient: 'Gestión de Pacientes',
    production: 'Módulo de Producción',
    import: 'Importación de Fichero Robot',
    blister: 'Producción de Blister Manual',
    update: 'Actualizaciones',
    support: 'Soporte Técnico',
    patient30: 'Hasta 50 Pacientes',
    patient31: 'Pacientes Ilimitados',
    rx: 'Conexión a Receta Electrónica',
    inform: 'Informe de Posología CCAA',
    warning: 'Cambios en Receta Electrónica',
    next: 'Próximas Dispensaciones',
    mach: 'Cruce de Posología con Receta Electrónica',
    sell: 'Módulo de Venta',
    question: '¿Aún tienes preguntas?',
    question2:
      'Para ofrecerte la asesoría más adecuada, indicanos si representas a una farmacia, hospital o laboratorio. De esta manera, podremos proporcionarte información personalizada según las particularidades de tu sector.',
    contact: 'Contáctanos',
    reserved: 'Todos los derechos reservados, hecho por',
    forgotpasswordintro:
      'Introduzca la dirección de correo electrónico asociada a su cuenta y le enviaremos un enlace para restablecer su contraseña.',
    request: 'Enviar solicitud',
    returnto: 'Volver a inicio de sesión',
    choose: '¿Buscas un Robot SPD?',
    choose1: 'Explora nuestra gama de Robots SPD',
    choose2:
      'Disponemos de una amplia gama de robots SPD para adaptarnos a tus necesidades específicas. Ofrecemos modelos diseñados según el volumen de producción, nivel de automatización y formato requerido, ya sea blister o bolsa. Además, contamos con robots equipados con la tecnología Oculus, que integran la verificación y el chequeo dentro del propio equipo.',
    siglas: 'SPD',
    robottitle: 'Explora Nuestra Gama de Robots',
    robotdescription:
      'Si estás buscando optimizar la automatización en la preparación de SPD, nuestros robots son tu aliado ideal. Disponemos de una amplia gama de robots SPD, desde los altamente automatizados hasta los que requieren menos automatización, para adaptarnos a tus necesidades específicas. Gracias a nuestra tecnología avanzada y a las tolvas universales FSP que ofrecemos, podrás reducir significativamente la dependencia de la calibración. Además, nuestros robots están equipados con el sistema OCULUS integrado, que te proporciona evidencia fotográfica detallada de cada producción, así como análisis exhaustivos de todas las dosis.',
    catalog: 'Descargar Catálogo',
    diff: 'Principales Ventajas de Nuestros Robots SPD',
    diff1: 'FSP Universales',
    diff1a:
      'Con las FSP Universales, podrás utilizar la medicación que necesitas en el momento preciso sin complicaciones y sin calibraciones. Nuestra innovadora tecnología garantiza la utilización de cualquier tipo de medicamento que desees, sin importar su forma o tamaño. Disfrutarás mayor libertad en tu producción.',
    diff2: 'Alta Rotación',
    diff2a:
      'Para asegurar la disponibilidad constante de las moléculas más utilizadas, llevaremos a cabo una calibración cuidadosa. Nuestros expertos realizarán un estudio exhaustivo para validar esta selección, garantizando así una calibración precisa y una automatización eficiente. Este enfoque nos permite alcanzar una automatización óptima.',
    diff3: 'Trazabilidad TOTAL',
    diff3a:
      'Con nuestros robots, gestionar tu medicación es más sencillo que nunca. Basta con escanear el código Sevem para registrar el lote y la caducidad en cada dosis producida. Este proceso rápido y eficiente te brinda la capacidad de realizar un seguimiento detallado de tu medicación, asegurando un control preciso y seguro del mismo. ',
    robotnews: 'Sistema OCULUS',
    robotnews2:
      'Desde Robotik, hemos desarrollado la tecnología OCULUS, que se encarga de revisar y analizar toda la producción mediante visión artificial. Con OCULUS, olvídate del tiempo dedicado a revisar la producción manualmente; nuestra tecnología lo hace por ti. Además, siempre deja una evidencia fotográfica de todo lo que se ha producido con tu robot Robotik. En resumen, OCULUS te brinda tranquilidad total.',
    discover: 'Descubre las caracteristicas de cada Robot SPD',
    discover2: 'SELECCIONA',
    tablerobots: 'El robot SPD ideal para todas las necesidades',
    footer:
      'La mejor solución SPD unidosis del mercado. Somos expertos en implementar la inteligencia artificial en los procesos de gestión.',
    corfirmform: '¡Formulado recibido!',
    corfirmform2:
      'Nuestro equipo revisará la información proporcionada y nos pondremos en contacto contigo en breve ¡Gracias por tu interés!',
    tel: '+34 911 230 678',
  },
  myprofile: {
    edit: 'Editar',
    user: 'Usuario',
    name: 'Nombre y Apellidos',
    mail: 'Correo Electrónico',
    number: 'Numero de Teléfono',
    country: 'Pais',
    state: 'Provincia',
    city: 'Ciudad',
    address: 'Dirección',
    zip: 'CP',
    company: 'Empresa',
    role: 'Rol',
    save: 'Guardar Cambios',
    delete: 'Eliminar Usuario',
    verify: 'Email Verificado',
    verifyinfo:
      'Al desactivar esta opción, el usuario recibirá automáticamente un correo electrónico de verificación.',
    banned: 'Desactivado',
    apply: 'Desactivar cuenta',
    new: 'Crear Usuario',
    permit: 'Permitidos *.jpeg, *.jpg, *.png, *.gif',
    max: 'tamaño máx. de',
    management: 'Administración',
    search: 'Buscar...',
    list: 'Listado de Usuarios',
    changecenter: 'Cambio de Centro',
    resetpass: 'Restablecer Contraseña',
    confirmdelete: '¿Deseas eliminar el usuario seleccionado?',
    dense: 'Acortar',
    confirmreset: 'Email enviado correctamente',
    quick: 'Editar Permisos',
    otp: 'Generar OTP',
    otptitle: 'Verificación en dos pasos',
    otpinfo: '¿Desea generar un QR para la verificación en dos pasos?',
    otpabout:
      'Escanea el código QR con Google autheticator para generar un codigo temporal y cambiante.',
  },
  pages: {
    pages: 'Contacto',
    robot: 'Robots SPD',
    about: 'Sobre Nosotros',
    contact: 'Contactar con IDOOSE',
    inicio: 'Inicio',
    info: 'Información',
    community: 'Comunidad',
    post: 'Publicaciones',
    legalpag: 'Aviso Legal',
  },
  about: {
    who: '¿Quiénes somos?',
    about: 'Sobre Nosotros',
    contact: 'Contactar con IDOOSE',
    inicio: 'Inicio',
    info: 'Información',
    community: 'Comunidad',
    post: 'Publicaciones',
    whotitle: '¿Quiénes',
    are: 'somos?',
    mision: 'Nuestra misión es mejorar la salud y el bienestar de nuestros pacientes',
    mision2: 'mediante la preparación precisa de medicamentos en unidosis, garantizando',
    mision3: 'una administración segura y efectiva de manera personalizada.',
    whatis: '¿Qué es IDOOSE?',
    text: 'Somos más que un programa de gestión de medicación, buscamos impulsar un cambio positivo en la salud de las personas. Con el programa de gestión más avanzado y accesible, nos esforzamos por brindar herramientas que no solo simplifiquen la administración de medicamentos, sino que también promuevan la conexión comunitaria y el bienestar general. Gracias a nuestra IA, ofrecemos el servicio más seguro y fiable del mercado en la preparación de medicación. En IDOOSE ofrecemos un camino hacia una vida más saludable, sencilla y segura.',
    legaltitle: 'AVISO LEGAL',
    legalare: 'CONDICIONES GENERALES DE USO',
    legaltitle1: 'I. INFORMACIÓN GENERAL',
    legaltitle2: 'DATOS DE CONTACTO',
    legaltitle3: 'II. TÉRMINOS Y CONDICIONES DE USO',
    legaltitle4: 'El Usuario',
    legaltitle5: 'III. ACCESO Y NAVEGACIÓN EN EL SITIO WEB: EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD',
    legaltitle6: 'IV. POLÍTICA DE ENLACES',
    legaltitle7: 'V. PROPIEDAD INTELECTUAL E INDUSTRIAL',
    legaltitle8: 'VI. ACCIONES LEGALES, LEGISLACIÓN APLICABLE Y JURISDICCIÓN',
    legaldata: 'Nombre de la empresa',
    legaldata1: 'NIF',
    legaldata2: 'Dirección',
    legaldata3: 'Teléfono de contacto',
    legaldata4: 'Email de contacto',
    legalsubtitile:
      'En cumplimiento de la Ley 34/2002, se informa a los usuarios sobre la titularidad del sitio web www.idoose.com',
    legaltext:
      'Idoose Robotik Sl es una compañía de Lorqui, localidad ubicada en la provincia de Murcia. Su domicilio fiscal se establece en Calle Castillo De Moratalla, 45. 30564, Lorqui (murcia). España., cuyo código postal es 30564. Así pues, la empresa Idoose Robotik Sl está inscrita en el Registro Mercantil de Murcia. Desempeña la actividad clasificada como Programación, Consultoría Y Otras Actividades Relacionadas Con La Informática, según el CNAE. Concretamente, la clase de Idoose Robotik Sl es Actividades De Programación Informática, con el código numérico 6201. Su CIF es B56740137 y, por su naturaleza jurídica, es una Sociedad Limitada.',
    legaltext1:
      'En cumplimiento con el deber de información dispuesto en la Ley 34/2002 de Servicios de la Sociedad de la Información y el Comercio Electrónico (LSSI-CE) de 11 de julio, se facilitan a continuación los siguientes datos de información general de este sitio web:',
    legaltext2:
      'La titularidad de este sitio web, www.idoose.com, (en adelante, Sitio Web) la ostenta: IDOOSE ROBOTIK SL, con NIF: B56740137',
    legaltext3:
      'El objeto de las presentes Condiciones Generales de Uso (en adelante, Condiciones) es regular el acceso y la utilización del Sitio Web. A los efectos de las presentes Condiciones se entenderá como Sitio Web: la apariencia externa de los interfaces de pantalla, tanto de forma estática como de forma dinámica, es decir, el árbol de navegación; y todos los elementos integrados tanto en los interfaces de pantalla como en el árbol de navegación (en adelante, Contenidos) y todos aquellos servicios o recursos en línea que en su caso ofrezca a los Usuarios (en adelante, Servicios).',
    legaltext4:
      'IDOOSE se reserva la facultad de modificar, en cualquier momento, y sin aviso previo, la presentación y configuración del Sitio Web y de los Contenidos y Servicios que en él pudieran estar incorporados. El Usuario reconoce y acepta que en cualquier momento IDOOSE pueda interrumpir, desactivar y/o cancelar cualquiera de estos elementos que se integran en el Sitio Web o el acceso a los mismos.',
    legaltext5:
      'Aparte del coste de conexión a través de la red de telecomunicaciones suministrada por el proveedor de acceso, y que hubiere contratado el Usuario, algunos de los Contenidos o Servicios ofrecidos por IDOOSE o, en su caso, terceros a través del Sitio Web pueden encontrarse sujetos a la contratación previa del Contenido o Servicio, en cuyo caso se especificará de forma clara y/o se pondrá a disposición del Usuario las correspondientes Condiciones Generales o Particulares por las que esto se rija.',
    legaltext6:
      'La utilización de alguno de los Contenidos o Servicios del Sitio Web podrá hacerse mediante la suscripción o registro previo del Usuario.',
    legaltext7:
      'El acceso, la navegación y uso del Sitio Web, así como por los espacios habilitados para interactuar entre los Usuarios, y el Usuario y IDOOSE, como los comentarios y/o espacios de blogging, confiere la condición de Usuario, por lo que se aceptan, desde que se inicia la navegación por el Sitio Web, todas las Condiciones aquí establecidas, así como sus ulteriores modificaciones, sin perjuicio de la aplicación de la correspondiente normativa legal de obligado cumplimiento según el caso. Dada la relevancia de lo anterior, se recomienda al Usuario leerlas cada vez que visite el Sitio Web.',
    legaltext8:
      'El Sitio Web de IDOOSE proporciona gran diversidad de información, servicios y datos. El Usuario asume su responsabilidad para realizar un uso correcto del Sitio Web. Esta responsabilidad se extenderá a:',
    legaltext9:
      '•	Un uso de la información, Contenidos y/o Servicios y datos ofrecidos por IDOOSE sin que sea contrario a lo dispuesto por las presentes Condiciones, la Ley, la moral o el orden público, o que de cualquier otro modo puedan suponer lesión de los derechos de terceros o del mismo funcionamiento del Sitio Web.',
    legaltext10:
      '•	La veracidad y licitud de las informaciones aportadas por el Usuario en los formularios extendidos por IDOOSE para el acceso a ciertos Contenidos o Servicios ofrecidos por el Sitio Web. En todo caso, el Usuario notificará de forma inmediata a IDOOSE acerca de cualquier hecho que permita el uso indebido de la información registrada en dichos formularios, tales como, pero no solo, el robo, extravío, o el acceso no autorizado a identificadores y/o contraseñas, con el fin de proceder a su inmediata cancelación.',
      legaltext11: 'IDOOSE se reserva el derecho de retirar todos aquellos comentarios y aportaciones que vulneren la ley, el respeto a la dignidad de la persona, que sean discriminatorios, xenófobos, racistas, pornográficos, spamming, que atenten contra la juventud o la infancia, el orden o la seguridad pública o que, a su juicio, no resultaran adecuados para su publicación.',
      legaltext12: 'En cualquier caso, IDOOSE no será responsable de las opiniones vertidas por los Usuarios a través de comentarios u otras herramientas de blogging o de participación que pueda haber.',
      legaltext13: 'El mero acceso a este Sitio Web no supone entablar ningún tipo de relación de carácter comercial entre IDOOSE y el Usuario.',
      legaltext14: 'El Usuario declara ser mayor de edad y disponer de la capacidad jurídica suficiente para vincularse por las presentes Condiciones. Por lo tanto, este Sitio Web de IDOOSE no se dirige a menores de edad. IDOOSE declina cualquier responsabilidad por el incumplimiento de este requisito.',
      legaltext15: 'IDOOSE no garantiza la continuidad, disponibilidad y utilidad del Sitio Web, ni de los Contenidos o Servicios. IDOOSE hará todo lo posible por el buen funcionamiento del Sitio Web, sin embargo, no se responsabiliza ni garantiza que el acceso a este Sitio Web no vaya a ser ininterrumpido o que esté libre de error.',
      legaltext16: 'Tampoco se responsabiliza o garantiza que el contenido o software al que pueda accederse a través de este Sitio Web, esté libre de error o cause un daño al sistema informático (software y hardware) del Usuario. En ningún caso IDOOSE será responsable por las pérdidas, daños o perjuicios de cualquier tipo que surjan por el acceso, navegación y el uso del Sitio Web, incluyéndose, pero no limitándose, a los ocasionados a los sistemas informáticos o los provocados por la introducción de virus.',
      legaltext17: 'IDOOSE tampoco se hace responsable de los daños que pudiesen ocasionarse a los usuarios por un uso inadecuado de este Sitio Web. En particular, no se hace responsable en modo alguno de las caídas, interrupciones, falta o defecto de las telecomunicaciones que pudieran ocurrir.',
      legaltext18: 'El Usuario o tercero que realice un hipervínculo desde una página web de otro, distinto, sitio web al Sitio Web de IDOOSE deberá saber que:',
      legaltext19: 'No se permite la reproducción —total o parcialmente— de ninguno de los Contenidos y/o Servicios del Sitio Web sin autorización expresa de IDOOSE.',
      legaltext20: 'No se permite tampoco ninguna manifestación falsa, inexacta o incorrecta sobre el Sitio Web de IDOOSE, ni sobre los Contenidos y/o Servicios del mismo.',
      legaltext21: 'A excepción del hipervínculo, el sitio web en el que se establezca dicho hiperenlace no contendrá ningún elemento, de este Sitio Web, protegido como propiedad intelectual por el ordenamiento jurídico español, salvo autorización expresa de IDOOSE.',
      legaltext22: 'El establecimiento del hipervínculo no implicará la existencia de relaciones entre IDOOSE y el titular del sitio web desde el cual se realice, ni el conocimiento y aceptación de IDOOSE de los contenidos, servicios y/o actividades ofrecidas en dicho sitio web, y viceversa.',
      legaltext23: 'IDOOSE por sí o como parte cesionaria, es titular de todos los derechos de propiedad intelectual e industrial del Sitio Web, así como de los elementos contenidos en el mismo (a título enunciativo y no exhaustivo, imágenes, sonido, audio, vídeo, software o textos, marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.). Serán, por consiguiente, obras protegidas como propiedad intelectual por el ordenamiento jurídico español, siéndoles aplicables tanto la normativa española y comunitaria en este campo, como los tratados internacionales relativos a la materia y suscritos por España.',
      legaltext24: 'Todos los derechos reservados. En virtud de lo dispuesto en la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización de IDOOSE.',
      legaltext25: 'El Usuario se compromete a respetar los derechos de propiedad intelectual e industrial de IDOOSE. Podrá visualizar los elementos del Sitio Web o incluso imprimirlos, copiarlos y almacenarlos en el disco duro de su ordenador o en cualquier otro soporte físico siempre y cuando sea, exclusivamente, para su uso personal. El Usuario, sin embargo, no podrá suprimir, alterar, o manipular cualquier dispositivo de protección o sistema de seguridad que estuviera instalado en el Sitio Web.',
      legaltext26: 'En caso de que el Usuario o tercero considere que cualquiera de los Contenidos del Sitio Web suponga una violación de los derechos de protección de la propiedad intelectual, deberá comunicarlo inmediatamente a IDOOSE a través de los datos de contacto del apartado de INFORMACIÓN GENERAL de este Aviso Legal y Condiciones Generales de Uso.',
      legaltext27: 'IDOOSE se reserva la facultad de presentar las acciones civiles o penales que considere necesarias por la utilización indebida del Sitio Web y Contenidos, o por el incumplimiento de las presentes Condiciones.',
      legaltext28: 'La relación entre el Usuario y IDOOSE se regirá por la normativa vigente y de aplicación en el territorio español. De surgir cualquier controversia en relación con la interpretación y/o a la aplicación de estas Condiciones las partes someterán sus conflictos a la jurisdicción ordinaria sometiéndose a los jueces y tribunales que correspondan conforme a derecho.',
    },
  contact: {
    where: '¿Dónde',
    find: 'estamos?',
    contact:
      'Por favor, completa el siguiente formulario de contacto y nos comunicaremos contigo lo antes posible. ¡Gracias!',
    name: 'Nombre',
    email: 'Email',
    subjet: 'Asunto',
    message: 'Escribe tu mensaje aquí.',
    submit: 'Enviar',
    tele: 'Teléfono',
  },
  rulesimport: {
    list: 'Parámetros de Importación',
    form: 'Informe de Venta',
    step1: 'Seleccionar Fichero',
    step2: 'Revisión de conflictos',
    step3: 'Subida del fichero',
    importcorrect: 'Correcto',
    sugest: 'Recomendación',
    new: 'Nuevo',
    noconflicts: 'Fichero validado correctamente, no se han detectado conflictos.',
    sureimport:
      'La base de datos ha sido actualizada, pulse finalizar para completar la importación.',
    importcomplete: 'La importación ha sido completada con éxito.',
  },
  calendar: {
    title: 'Calendario',
    event: 'Nuevo Evento',
    month: 'Mes',
    week: 'Semana',
    day: 'Día',
    agenda: 'Agenda',
    today: 'Hoy',
    new: 'Añadir Evento',
    new2: 'Titulo',
    description: 'Descripción',
    allday: 'Todo el Día',
    start: 'Fecha de Inicio',
    end: 'Fecha Final',
  },
  tablerobots: {
    tolvas: 'Nº Tolvas',
    univers: 'FSP Universales',
    width: 'Ancho (cm)',
    height: 'Altura (cm)',
    depth: 'Profundidad (cm)',
  },
  yup: {
    yup_min: 'Demasiado corto !',
    yup_max: 'Demasiado largo !',
    yup_required: 'Campo Requerido !',
    yup_email: 'El correo electrónico debe ser una dirección de correo electrónico válida !',
    yup_phone: 'El número de teléfono debe contener solo dígitos !',
  },
  cookie_consent: {
    acept: 'Aceptar',
    reject: 'Rechazar',
    message:
      'Nuestro sitio web utiliza cookies para analizar el tráfico y mejorar nuestros servicios.',
  },
  form_geometric: {
    1: 'Alargado',
    2: 'Ampolla',
    3: 'Anillo',
    4: 'Arco',
    5: 'Bala',
    6: 'Biconvexo',
    7: 'Cápsula',
    8: 'Cartucho',
    9: 'Cilíndrico',
    10: 'Cóncavo',
    11: 'Cuadrado',
    12: 'Elíptica',
    13: 'Esferica',
    14: 'Gas',
    15: 'Inhalador',
    16: 'Lágrima',
    17: 'Líquido',
    18: 'Manzana',
    19: 'Oblongo',
    20: 'Octogonal',
    21: 'Ovalado',
    22: 'Polvo',
    23: 'Rectangular',
    24: 'Redondo',
    25: 'Rombo',
    26: 'Sobre',
    27: 'Triangular',
    28: 'Vial',
  },
  form_size: {
    0: 'Sin Definir',
    1: '2 mm (Micro)',
    2: '4 mm (Pequeño)',
    3: '8 mm (Mediano)',
    4: '12 mm (Grande)',
    5: '18.0 mm (Muy Grande)',
    6: '23.3 mm (Extra Grande)',
  },
  form_desc: {
    form_geometric: 'Forma Geometrica',
    form_size: 'Tamaño',
    form_slot: 'Ranurado',
  },

  termsconditions: {
    title: 'Términos y Condiciones de Uso de IDOOSE',
    subtitle: 'Bienvenido a IDOOSE',
    parraf1:
      'Le agradecemos que haya elegido IDOOSE. Lea cuidadosamente estos términos y condiciones antes de usar nuestro software. Al utilizar IDOOSE, usted acepta estos términos y condiciones. Si no está de acuerdo con alguno de estos términos, no utilice nuestro software.',
    subtitle2: '1. Ámbito del Contrato y Aceptación',
    subtitle3: '1.1. Servicios cubiertos en el contrato',
    parraf2:
      'Este contrato se aplica a idoose.com y a cualquier otro software, sitio web o servicio proporcionado por IDOOSE ROBOTIK SL (colectivamente, los "servicios").',
    subtitle4: '1.2. Aceptación de este contrato',
    parraf3:
      'Al utilizar los servicios de IDOOSE, usted acepta estos términos y condiciones sin modificaciones. Si no está de acuerdo, no utilice los servicios.',
    subtitle5: '1.3. Modificación del contrato',
    parraf4:
      'IDOOSE ROBOTIK SL se reserva el derecho de modificar estos términos y condiciones en cualquier momento. Le notificaremos de cualquier cambio mediante correo electrónico o a través de nuestro sitio web. Su uso continuado de los servicios después de dichos cambios constituye su aceptación de los mismos.',
    subtitle6: '2. Uso de los Servicios y Restricciones',
    subtitle7: '2.1. Uso del software',
    parraf5:
      'IDOOSE está licenciado, no vendido. Debe cumplir todas las restricciones técnicas del software y no puede:  •Eludir las restricciones técnicas del software •Descompilar, desensamblar o realizar ingeniería inversa del software, excepto donde la ley lo permita. •Hacer más copias del software de las especificadas en este contrato. •Publicar el software para que otros lo copien. •Alquilar, arrendar o prestar el software. •	Transferir el software o este contrato a un tercero.',
    subtitle8: '2.2. Obligaciones del usuario',
    parraf6:
      'Usted se compromete a utilizar los servicios de manera responsable y a no realizar ninguna acción que pueda perjudicar a IDOOSE ROBOTIK SL o a otros usuarios. Esto incluye, pero no se limita a: •No utilizar los servicios para actividades ilegales o no autorizadas. •No interferir con el funcionamiento de los servicios o acceder a los servicios mediante métodos no autorizados. •No recopilar información personal de otros usuarios sin su consentimiento. •No cargar ni distribuir virus ni cualquier otro tipo de software malicioso.',
    subtitle9: '3. Cuenta de IDOOSE',
    subtitle10: '3.1. Creación de cuenta',
    parraf7:
      'Para acceder a ciertos servicios, necesitará una cuenta de IDOOSE. Usted es responsable de mantener la confidencialidad de su información de cuenta y contraseña.',
    subtitle11: '3.2. Uso de la cuenta',
    parraf8:
      'Usted es responsable de todas las actividades que ocurran bajo su cuenta. Debe notificar a IDOOSE ROBOTIK SL inmediatamente de cualquier uso no autorizado de su cuenta o cualquier otra violación de seguridad.',
    subtitle12: '4. Contenido',
    subtitle13: '4.1. Propiedad del contenido',
    parraf9:
      'El contenido que usted cargue o transmita mediante los servicios sigue siendo de su propiedad. IDOOSE ROBOTIK SL no reclama la propiedad de su contenido.',
    subtitle14: '4.2. Responsabilidad del contenido',
    parraf10:
      'Usted es el único responsable del contenido que cargue, almacene o transmita a través de los servicios. Usted declara y garantiza que tiene todos los derechos necesarios para cargar dicho contenido y que su contenido no viola los derechos de terceros.',
    subtitle15: '4.3. Revisión y eliminación de contenido',
    parraf11:
      'IDOOSE ROBOTIK SL se reserva el derecho de revisar el contenido cargado en sus servidores y eliminar cualquier contenido que, a su discreción, infrinja estos términos y condiciones o la ley aplicable.',
    subtitle16: '5. Cancelación de los Servicios',
    subtitle17: '5.1. Por parte del usuario',
    parraf12:
      'Puede cancelar su cuenta de IDOOSE en cualquier momento a través de las páginas de administración de su cuenta. La cancelación entrará en vigor al final de su período de facturación.',
    subtitle18: '5.2. Por parte de IDOOSE ROBOTIK SL',
    parraf13:
      'IDOOSE ROBOTIK SL se reserva el derecho de cancelar su cuenta o acceso a los servicios en cualquier momento, con o sin motivo, y sin previo aviso.',
    subtitle19: '6. Legislación aplicable',
    parraf14:
      'Este contrato se rige por las leyes de España, sin dar efecto a ninguna elección de principios legales. Cualquier disputa que surja en relación con este contrato será sometida a la jurisdicción exclusiva de los tribunales de España.',
    subtitle20: '7. Datos de contacto',
    parraf15:
      'Para cualquier pregunta sobre este contrato, puede ponerse en contacto con nosotros en info@idoose.com.',
  },
  privacy: {
    title: 'Política de Privacidad',
    subtitle: 'IDOOSE ROBOTIK SL',
    parraf1:
      'En IDOOSE ROBOTIK SL, con domicilio en Calle Castillo de Moratalla 45, Lorquí, Murcia, CP 30564 y CIF B56740137, estamos comprometidos a proteger la privacidad y seguridad de nuestros usuarios. Esta Política de Privacidad describe cómo recopilamos, usamos y protegemos su información personal cuando utiliza nuestro software.',
    subtitle2: '1. Información que Recopilamos',
    parraf2:
      'Podemos recopilar los siguientes tipos de información: Información de identificación personal: Nombre, dirección, número de teléfono, dirección de correo electrónico, y cualquier otra información que usted proporcione voluntariamente. Información de uso del software: Datos sobre cómo interactúa con nuestro software, incluidas las funciones que utiliza y la frecuencia de uso. Información técnica: Datos técnicos sobre su dispositivo, como la dirección IP, el tipo de navegador, el sistema operativo y la configuración de idioma.',
    subtitle3: '2. Uso de la Información',
    parraf3:
      'Utilizamos la información recopilada para: Proporcionar, operar y mantener nuestro software.Mejorar, personalizar y expandir nuestro software.	Entender y analizar cómo utiliza nuestro software. Desarrollar nuevas funciones, productos y servicios.	Comunicarnos con usted, ya sea directamente o a través de uno de nuestros socios, incluidos para el servicio al cliente, proporcionarle actualizaciones y otra información relacionada con el software, y con fines de marketing y promocionales.	Procesar sus transacciones y gestionar su cuenta.	Enviar correos electrónicos periódicos.',
    subtitle4: '3. Protección de su Información',
    parraf4:
      'Implementamos una variedad de medidas de seguridad para mantener la seguridad de su información personal cuando ingresa, envía o accede a su información personal. Sin embargo, recuerde que ningún método de transmisión a través de Internet o método de almacenamiento electrónico es 100% seguro.',
    subtitle5: '4. Compartir su Información',
    parraf5:
      'No vendemos, intercambiamos ni transferimos a terceros su información de identificación personal. Esto no incluye a terceros de confianza que nos ayudan a operar nuestro software, llevar a cabo nuestro negocio, o atenderle, siempre y cuando dichas partes acuerden mantener esta información confidencial. También podemos divulgar su información cuando creemos que la divulgación es apropiada para cumplir con la ley, hacer cumplir nuestras políticas del sitio, o proteger nuestros derechos, propiedad o seguridad.',
    subtitle6: '5. Sus Derechos',
    parraf6:
      'Usted tiene derecho a acceder, rectificar, cancelar y oponerse al tratamiento de sus datos personales. Para ejercer estos derechos, puede ponerse en contacto con nosotros a través del correo electrónico info@idoose.com o enviando una carta a nuestra dirección: Calle Castillo de Moratalla 45, Lorquí, Murcia, CP 30564.',
    subtitle7: '6. Cambios a esta Política de Privacidad',
    parraf7:
      'Nos reservamos el derecho de actualizar esta Política de Privacidad en cualquier momento. Le notificaremos cualquier cambio mediante la publicación de la nueva Política de Privacidad en esta página. Se recomienda revisar esta Política de Privacidad periódicamente para estar informado de cualquier cambio.',
    subtitle8: '7. Contacto',
    parraf8:
      'Si tiene alguna pregunta sobre esta Política de Privacidad, puede ponerse en contacto con nosotros:IDOOSE ROBOTIK SL Calle Castillo de Moratalla 45, Lorquí, Murcia, CP 30564 Correo electrónico: info@idoose.com',
  },
  payments: {
    title: 'Resumen de compra',
    totalFacturado: 'Total Facturado',
    taxes: 'Más impuestos aplicables',
    compra: 'Comprar',
    securitycard: 'Pago seguro con tarjeta de crédito',
    encrypted: 'Este es un pago seguro cifrado SSL de 128 bits',
    subscription: 'Suscripción',
    subrealizada: 'Suscripción al plan exitosa!',
    manage: 'Gestiona tu información de facturación',
    mes: '/ Mes',
  },
  patient_medicine_journal_list: {
    lng_general: 'General',
    lng_patient: 'Pacientes',
    lng_history: 'Histórico',
    lng_date: 'Fecha',
    lng_patient_id: 'Nº Paciente',
    lng_patient_name: 'Nombre Paciente',
    lng_produced: 'Producido',
    lng_sold: 'Vendido',
    lng_provided: 'Proporcionado',
    lng_medicine_name: 'Nombre del Medicamento',
    lng_total: 'Total',
    lng_event: 'Estado',
    lng_units: 'Unidades',
    lng_sales: 'Ventas',
    lng_aport: 'Aportado',
  },
  qrscan: {
    barcode: 'Código de Barras',
    barcodeadd: 'Hoja añadida correctamente',
    barcodeerror: 'La hoja ya existe',
    barcodetitle: 'Gestión Hojas de cupones precinto',
    barcodeaddb: 'Añadir Hoja',
    barcodecp: 'Cupones precinto',
    barcodeaddbarcode: '¡Escane la Hoja de cupones precinto!',
  },
};

export default es;
